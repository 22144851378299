import ServiceProvider from '@/core/providers/ServiceProvider';
import Vue from 'vue';

export default class VueConfigServiceProvider extends ServiceProvider {
  /**
   * Init service provider
   */
  public async init() {
    Vue.config.productionTip = false;
  }
}
