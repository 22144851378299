import VueConfigServiceProvider from '@/app/providers/VueConfigServiceProvider';
import AppServiceProvider from '@/app/providers/AppServiceProvider';
import UserServiceProvider from '@/app/providers/UserServiceProvider';
import SettingsServiceProvider from '@/app/providers/SettingsServiceProvider';
import LocaleServiceProvider from '@/app/providers/LocaleServiceProvider';
import AuthExpiredServiceProvider from '@/app/providers/AuthExpiredServiceProvider';
import AuthServiceProvider from '@/app/providers/AuthServiceProvider';

const providers = [
  new VueConfigServiceProvider(),
  new SettingsServiceProvider(),
  new AuthServiceProvider(),
  new LocaleServiceProvider(),
  new AppServiceProvider(),
  new UserServiceProvider(),
  new AuthExpiredServiceProvider(),
];

export { providers };
