import Pagination from '@/core/support/pagination/Pagination';
import Model from '@vuex-orm/core/dist/src/model/Model';
import ORMModel from '@/core/bridge/orm/ORMModel';
import Search from '@/core/support/search/Search';

declare type InstanceOf<T> = T extends new (...args: any[]) => infer R ? R : any;

export default abstract class Collection {
  /**
   * @info      Collection
   * @author    Lukas Laskowski & Karol Chojnacki
   */

  /**
   * Pagination
   */
  public pagination: Pagination = new Pagination();

  /**
   * Search
   */
  public search: Search = new Search();

  /**
   * Current index of collection
   */
  public index: number = 0;

  /**
   * Collection data
   */
  public data: any[] = [];

  /**
   * Information about any data injected
   */
  public injection = false;

  /**
   * Create new collection
   * @param data
   * @param pagination
   * @param search
   */
  public constructor(
    data: any[] | null = null,
    pagination: Pagination | null = null,
    search: Search | null = null,
  ) {
    if (data !== null) {
      this.data = data;
      this.injection = true;
    }

    if (pagination !== null) {
      this.pagination = pagination;
    }

    if (search !== null) {
      this.search = search;
    }
  }

  /**
   * Get item by given property and value
   */
  public find(property: string, value: number | string): any {
    return this.data.find((item: any) => {
      return item[property] === value;
    });
  }

  /**
   * Check if collection has model by id
   * @param property
   * @param value
   */
  public has(property: string, value: number | string): boolean {
    return !!this.find(property, value);
  }

  /**
   * Get items
   */
  public get items(): any[] {
    return this.data;
  }

  /**
   * Get paginated items
   */
  public get paginatedItems(): any[] {
    const start: number = this.pagination.offset;
    const end: number = this.pagination.getLimit() + start;

    return this.data.slice(start, end);
  }

  /**
   * Get data count
   */
  public get count() {
    return this.data.length;
  }

  /**
   * Return model on index
   * @param index
   */
  public item(index: number): any {
    return this.data[index];
  }

  /**
   * Add model/s to collection
   * @param item
   * @param atBeginning
   */
  public add(item: any | Collection, atBeginning: boolean = false) {
    this.injection = true;

    if (item instanceof Collection) {
      this.pagination = item.pagination;

      item.data.forEach((collectionItem: any) => {
        this.add(collectionItem);
      });
    } else {
      if (atBeginning) {
        this.data.unshift(item);
      } else {
        this.data.push(item);
      }
    }

    this.pagination.setTotal(this.count);

    return this;
  }

  /**
   * Add many data to collection
   * @param data
   */
  public addMany(data: Array<number | string> | Array<InstanceOf<Model>>) {
    data.forEach((item: number | string | InstanceOf<Model>) => {
      this.add(item);
    });
  }

  /**
   * Add model/s to the beginning of collection
   * @param item
   */
  public addToBeginning(item: number | string | InstanceOf<Model> | Collection) {
    this.injection = true;
    if (item instanceof Collection) {
      this.pagination = item.pagination;

      item.data.reverse().forEach((collectionItem: any) => {
        this.add(collectionItem, true);
      });
    } else {
      this.add(item, true);
    }
    return this;
  }

  /**
   * Remove model/s from collection
   * @param pos
   */
  public remove(pos: number) {
    this.data.splice(pos, 1);
    this.pagination.setTotal(this.count);

    return this;
  }

  /**
   * Return new filtered collection
   * @param callbackfn
   */
  public filter(callbackfn: (item: InstanceOf<Model>) => any): InstanceOf<Collection> {
    return this.data.filter(callbackfn);
  }

  /**
   * Return true if not empty
   */
  public filled(): boolean {
    return this.count > 0;
  }

  /**
   * Return true if empty
   */
  public empty(): boolean {
    return this.count === 0;
  }

  /**
   * Check if all data are loaded
   */
  public get loaded(): boolean {
    if (!this.isInjected) {
      return false;
    }
    return !this.data.some((item: ORMModel) => {
      return !item.isLoaded;
    });
  }

  /**
   * Check if there was an injection to collection
   */
  public get isInjected() {
    return this.injection;
  }

  /**
   * Return first model
   */
  public first(): any | null {
    this.index = 0;
    return this.current();
  }

  /**
   * Return last model
   */
  public last(): any | null {
    this.index = this.count - 1;
    return this.current();
  }

  /**
   * Return next model
   */
  public next(): any | null {
    this.index++;
    if (this.exists()) {
      return this.current();
    }
    return this.first();
  }

  /**
   * Return previous model
   */
  public prev(): any | null {
    this.index--;
    if (this.exists()) {
      return this.current();
    }
    return this.last();
  }

  /**
   * Remove all collection data
   */
  public removeAll() {
    this.data = [];
    this.pagination.setTotal(0);
  }

  /**
   * Return model on active index
   */
  public current(): any | null {
    if (this.exists()) {
      return this.data[this.index];
    }
    return null;
  }

  /**
   * Check if model exist on active index
   */
  public exists(): boolean {
    return this.data[this.index] !== undefined;
  }
}
