import SettingsContract from '@/core/settings/contracts/SettingsContract';
import SettingsStorageAdapter from '@/core/settings/SettingsStorageAdapter';

enum CACHE_KEYS {
  PARAMS = 'settings-adapter-params-map-cached',
  OBJECTS = 'settings-adapter-objects-map-cached',
}

export default class BrowserLocalSettingsStorageAdapter extends SettingsStorageAdapter {
  private params: SettingsContract = [];
  private objects: SettingsContract = [];

  constructor(prefix: string) {
    super(prefix);

    this.loadCache();
  }

  /**
   * Synchronize exists params with adapter
   * @param params
   */
  public sync(params: SettingsContract, objects: SettingsContract) {
    Object.keys(params).forEach((name: string) => {
      this.setParam(name, params[name]);
    });

    Object.keys(objects).forEach((name: string) => {
      this.setObject(name, objects[name]);
    });

    return this;
  }

  /**
   * Set param
   * @param name
   * @param value
   */
  public setParam(name: string, value: string | string[] | number | number[] | boolean) {
    localStorage.setItem(this.getPrefixed(name), JSON.stringify(value));

    this.cacheParam(name);

    return this;
  }

  /**
   * Get param
   * @param name
   */
  public getParam(name: string): any {
    if (this.params.indexOf(name) === -1) {
      return undefined;
    }
    const result: string | null = localStorage.getItem(this.getPrefixed(name));
    return result ? JSON.parse(result) : undefined;
  }

  /**
   * Check if param exists
   * @param name
   */
  public hasParam(name: string): boolean {
    return this.getParam(name) !== undefined;
  }

  /**
   * Get all params
   */
  public getParams(): SettingsContract {
    const list: SettingsContract = {};
    this.params.forEach((name: string) => {
      list[name] = this.getParam(name);
    });
    return list;
  }

  /**
   * Remove params
   * @param name
   */
  public removeParam(name: string) {
    localStorage.removeItem(this.getPrefixed(name));

    if (this.params[name]) {
      delete this.params[name];
    }
  }

  /**
   * Set object type
   * @param name
   * @param object
   */
  public setObject(name: string, object: any) {
    localStorage.setItem(this.getPrefixed(name), JSON.stringify(object));

    this.cacheObject(name);

    return this;
  }

  /**
   * Get object type
   * @param name
   */
  public getObject(name: string): any {
    if (this.objects.indexOf(name) === -1) {
      return undefined;
    }
    const result: string | null = localStorage.getItem(this.getPrefixed(name));
    return result ? JSON.parse(result) : undefined;
  }

  /**
   * Remove object
   * @param name
   */
  public removeObject(name: string) {
    localStorage.removeItem(this.getPrefixed(name));

    if (this.objects[name]) {
      delete this.objects[name];
    }
  }

  /**
   * Get all objects
   */
  public getObjects(): SettingsContract {
    const list: SettingsContract = {};
    this.objects.forEach((name: string) => {
      list[name] = this.getObject(name);
    });
    return list;
  }

  /**
   * Check if object exists
   * @param name
   */
  public hasObject(name: string): boolean {
    return this.getObject(name) !== undefined;
  }

  /**
   * Clear all collected data
   */
  public clear() {
    // saved data
    this.params.forEach((name: string) => {
      localStorage.removeItem(this.getPrefixed(name));
    });
    this.objects.forEach((name: string) => {
      localStorage.removeItem(this.getPrefixed(name));
    });

    // loaded data
    this.params = [];
    this.objects = [];

    // cached keys map
    localStorage.removeItem(this.getPrefixed(CACHE_KEYS.PARAMS));
    localStorage.removeItem(this.getPrefixed(CACHE_KEYS.OBJECTS));
  }

  /**
   * Cache param name
   * @param param
   */
  private cacheParam(param: string) {
    if (this.params.indexOf(param) !== -1) {
      return;
    }
    this.params.push(param);

    localStorage.setItem(this.getPrefixed(CACHE_KEYS.PARAMS), JSON.stringify(this.params));
  }

  /**
   * Cache param name
   * @param param
   */
  private cacheObject(param: string) {
    if (this.objects.indexOf(param) !== -1) {
      return;
    }
    this.objects.push(param);

    localStorage.setItem(this.getPrefixed(CACHE_KEYS.OBJECTS), JSON.stringify(this.objects));
  }

  /**
   * Load cached params list
   */
  private loadCache() {
    const params: any = localStorage.getItem(this.getPrefixed(CACHE_KEYS.PARAMS));
    if (params) {
      this.params = JSON.parse(params);
    }

    const objects: any = localStorage.getItem(this.getPrefixed(CACHE_KEYS.OBJECTS));
    if (objects) {
      this.objects = JSON.parse(objects);
    }
  }
}
