import SettingsStorage from '@/core/settings/SettingsStorage';
import SettingsStorageRegistry from '@/core/settings/SettingsStorageRegistry';
import MemorySettingsStorageAdapter from '@/core/settings/adapters/MemorySettingsStorageAdapter';
import AuthSettingsEnum from '@/core/api/support/AuthSettingsEnum';

export default class AuthSettings {
  /**
   * Set storage
   */
  public static readonly storage: SettingsStorage = SettingsStorageRegistry.register(
    new MemorySettingsStorageAdapter('AUTH'),
  );

  /**
   * Set expired session flag
   */
  public static setExpired() {
    this.storage.setParam(AuthSettingsEnum.EXPIRED, true);
  }
}
