






import { Vue, Component, Prop } from 'vue-property-decorator';
import ProductCoreModel from '@/modules/products/models/ProductCoreModel';

@Component
export default class ProductAvailability extends Vue {
  /**
   * Props
   */
  @Prop() private product!: ProductCoreModel;

  /**
   * Getters
   */
  // TODO to model/service
  private get availableText(): string {
    if (this.product.isOnOrder) {
      return 'Na zamówienie';
    }

    if (this.product.isAvailable && this.product.maxCartItemAmount && this.product.maxCartItemAmount > 0) {
      return `Dostępnych: ${this.product.maxCartItemAmount} ${this.product.unit}`;
    }

    return 'Produkt niedostępny';
  }

  /**
   * Class names
   */
  private get availableDotClassNames(): object {
    const green: boolean = this.product.isAvailable &&
      !!(this.product.maxCartItemAmount && this.product.maxCartItemAmount > 0);

    const yellow: boolean = this.product.isOnOrder;
    const red: boolean = !green && !yellow;

    return {
      dot: true,
      green,
      red,
      yellow,
    };
  }
}
