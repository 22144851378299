export default class ApiErrorResponses {
  protected status!: number | null;
  protected internalErrorStatuses: number[] = [500, 503, 504];

  constructor(status: number | null) {
    this.status = status;
  }

  public isUnauthorized(): boolean {
    return this.status === 401;
  }

  public isForbidden(): boolean {
    return this.status === 403;
  }

  public isNotAcceptable(): boolean {
    return this.status === 406;
  }

  public isNotFound(): boolean {
    return this.status === 404;
  }

  public isExpectationFailed(): boolean {
    return this.status === 417;
  }

  public isUnprocessableEntity(): boolean {
    return this.status === 422;
  }

  public isInternalServerError(): boolean {
    return this.status === 500;
  }

  public isServiceUnavailable(): boolean {
    return this.status === 503;
  }

  public isGatewayTimeout(): boolean {
    return this.status === 504;
  }

  public isInternalError(): boolean {
    return !!this.status && this.internalErrorStatuses.indexOf(this.status) !== -1;
  }
}
