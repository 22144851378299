



















































import { Component, Vue } from 'vue-property-decorator';

import TopBar from '@/app/layouts/default/top-bar/components/TopBar.vue';

import Navigation from '@/app/layouts/default/navigation/components/Navigation.vue';
import NavigationContent from '@/shared/resources/layouts/navigation/components/NavigationContent.vue';
import ProductsNavigation from '@/app/modules/products/components/navigation/ProductsNavigation.vue';
import Icon from '@/shared/resources/components/Icon.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import Badge from '@/shared/resources/components/Badge.vue';
import Cart from '@/app/lib/cart/Cart';
import { existsInPath } from '@/shared/lib/support/router/RouterUtils';
import SideModals from '@/shared/resources/layouts/SideModals.vue';
import IndexableInterface from '@/core/interfaces/IndexableInterface';
import { navigationItems } from '@/app/layouts/NavigationItems';
import { SideModalInterface } from '@/shared/lib/interfaces/SideModalInterface';
import UISettings from '@/app/lib/settings/UISettings';

const DEFAULT_NAVIGATION_COMPONENT: string = 'NavigationContent';

@Component({
  components: {
    SideModals,
    Badge,
    Button,
    Icon,
    TopBar,
    Navigation,
    NavigationContent,
    ProductsNavigation,
  },
})
export default class DefaultLayout extends Vue {
  /**
   * Data
   */
  private navigationItems: IndexableInterface = navigationItems;

  /**
   * Getters
   */
  private get navigationComponent(): string {
    return this.$route.meta && this.$route.meta.customNavigation
      ? this.$route.meta.customNavigation
      : DEFAULT_NAVIGATION_COMPONENT;
  }

  private get appendDefaultComponent(): boolean {
    return this.navigationComponent === DEFAULT_NAVIGATION_COMPONENT;
  }

  private get disableRouteWatcher(): boolean {
    return this.navigationComponent !== DEFAULT_NAVIGATION_COMPONENT;
  }

  private get cartItemsCounter(): number {
    return Cart.countTotalItems();
  }

  private get sideModals(): SideModalInterface[] {
    return UISettings.sideModals;
  }

  /**
   * Display getters
   */
  private get displayCartButton(): boolean {
    return !Cart.isEmpty() && !existsInPath('cart', this.$route);
  }

  /**
   * Class names
   */
  private get appClassNames(): object {
    return {
      'default-layout': true,
    };
  }

  /**
   * Handlers
   */
  private handleSideModalHide(index: number) {
    UISettings.closeSideModal(index);
  }

}
