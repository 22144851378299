import { PaymentsTypeInterface } from '@/modules/payments/interfaces/PaymentsTypesInterface';
import { PaymentsTypesEnum } from '@/modules/payments/enums/PaymentsTypesEnum';
import { PaymentsEnum } from '@/modules/payments/enums/PaymentsEnum';

const paymentsTypes: PaymentsTypeInterface[] = [
  {
    key: PaymentsTypesEnum.PAYMENT_TYPE_IN_ADVANCE,
    requirePayment: true,
  },
  {
    key: PaymentsTypesEnum.PAYMENT_TYPE_ON_DELIVERY,
    requirePayment: false,
    defaultPayment: PaymentsEnum.ON_DELIVERY,
  },
];

export {
  paymentsTypes,
};
