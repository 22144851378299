import ApiORMQueryRelationHandler from '@/core/bridge/orm/api/relations/ApiORMQueryRelationHandler';
import {
  ApiORMQueryRelationContract,
  ApiORMQueryRelationsContract,
} from '@/core/bridge/orm/api/relations/contracts/ApiORMQueryRelationsContract';
import Model from '@vuex-orm/core/dist/src/model/Model';
import { ApiORMModelRelationsFieldsContract } from '@/core/bridge/orm/api/relations/contracts/ApiORMModelRelationsFieldsContract';
import { isArray } from 'lodash';
import IndexableInterface from '@/core/interfaces/IndexableInterface';

declare type InstanceOf<T> = T extends new (...args: any[]) => infer R ? R : any;

export default class ApiORMRelationsQueryBuilder {
  /**
   * Data
   */
  private data!: ApiORMQueryRelationsContract;
  private fields: string[] = [];
  private relations: ApiORMQueryRelationHandler[] = [];

  /**
   * Add relation data by string to Api Query
   * @param data
   */
  public addByString(data: string) {
    this.add({ [data]: {} });
  }

  /**
   * Add relations data to Api Query
   * @param data
   */
  public add(data: ApiORMQueryRelationsContract) {
    this.data = {
      ...this.data,
      ...data,
    };

    this.relations = [
      ...this.relations,
      ...this.recurencyAdd(data),
    ];
  }

  /**
   * Get parsed relations for Api query
   */
  public getRelations(): ApiORMQueryRelationHandler[] {
    return this.relations;
  }

  /**
   * Set base object fields
   * @param data
   */
  public setFields(fields: string[]) {
    this.fields = fields;
  }

  /**
   * Get parsed fields for Api query
   */
  public getFields(): string[] {
    return this.fields;
  }

  /**
   * Check if has any relations for Api query
   */
  public empty() {
    return !this.data && (!this.fields || !this.fields.length);
  }

  /**
   * Build ORM query with recursive relations
   * @param query
   * @param model
   */
  public buildORMQueryRelations(query: any, model: InstanceOf<Model>) {
    this.getRelations().forEach(async (relation: ApiORMQueryRelationHandler) => {
      await this.addORMQueryRelation(query, relation, model);
    });
  }

  /**
   * Add relations recursively to ORM Query
   * @param query
   * @param relation
   * @param model
   */
  private addORMQueryRelation(
    query: any,
    relation: ApiORMQueryRelationHandler,
    model: InstanceOf<Model>,
  ) {
    const modelRelationFields: ApiORMModelRelationsFieldsContract = model.relationFields;

    if (modelRelationFields[relation.name]) {
      const field: string | string[] = modelRelationFields[relation.name];

      if (isArray(field)) {
        field.forEach(async (f: string) => {
          const relatedModel: InstanceOf<Model> | null = this.getRelationModel(f, model);
          await this.recursiveAddORMRelationFieldQuery(query, f, relation, relatedModel);
        });
      } else {
        const relatedModel: InstanceOf<Model> | null = this.getRelationModel(field, model);
        this.recursiveAddORMRelationFieldQuery(query, field, relation, relatedModel);
      }
      return query;
    }
  }

  /**
   * Return related model for field name
   * @param name
   * @param model
   */
  private getRelationModel(name: string, model: InstanceOf<Model>): InstanceOf<Model> | null {
    const fields: IndexableInterface = model.getFields();
    let relationModel: InstanceOf<Model> = null;

    Object.keys(fields).some((fieldname: string) => {
      if (fieldname === name) {
        if (fields[fieldname].related) {
          relationModel = fields[fieldname].related;
        } else if (fields[fieldname].parent) {
          relationModel = fields[fieldname].parent;
        }
        return true;
      }
      return false;
    });

    return relationModel;
  }

  /**
   * Add relation field to ORM query recursively
   * @param query
   * @param field
   * @param relation
   * @param model
   */
  private async recursiveAddORMRelationFieldQuery(
    query: any,
    field: string,
    relation: ApiORMQueryRelationHandler,
    model: InstanceOf<Model>,
  ) {
    if (relation.relations) {
      query.with(field, async (nestedQuery: any) => {
        if (relation.relations) {
          relation.relations.forEach(async (r: ApiORMQueryRelationHandler) => {
            await this.addORMQueryRelation(nestedQuery, r, model);
          });
        }
      });
    } else {
      query.with(field);
    }
  }

  /**
   * Get parsed Api query relations by recurency
   * @param data
   */
  private recurencyAdd(data: ApiORMQueryRelationsContract | string | string[]): ApiORMQueryRelationHandler[] {
    const list: ApiORMQueryRelationHandler[] = [];

    if (typeof data === 'string') {
      list.push(new ApiORMQueryRelationHandler(data));
    } else if (isArray(data)) {
      data.forEach((name: string) => {
        list.push(new ApiORMQueryRelationHandler(name));
      });
    } else {
      Object.keys(data).forEach((name: string) => {
        const relation: ApiORMQueryRelationContract = data[name];

        let subrelations!: ApiORMQueryRelationHandler[];
        if (relation.with) {
          subrelations = this.recurencyAdd(relation.with);
        }

        list.push(new ApiORMQueryRelationHandler(name, relation.fields, subrelations));
      });
    }

    return list;
  }
}
