import { Model, Relation } from '@vuex-orm/core';
import Record from '@vuex-orm/core/dist/src/data/Record';
import { isEmpty } from 'lodash';
import {ApiORMModelRelationsFieldsContract} from '@/core/bridge/orm/api/relations/contracts/ApiORMModelRelationsFieldsContract';
import ORMModelDataHelper from '@/core/bridge/orm/support/ORMModelDataHelper';
import IndexableInterface from '@/core/interfaces/IndexableInterface';
import ApiORMQueryBuilder from '@/core/bridge/orm/api/ApiORMQueryBuilder';
import ApiORMQueryBuilderExtended from '@/shared/lib/api/query-builders/ApiORMQueryBuilderExtended';

declare type InstanceOf<T> = T extends new (...args: any[]) => infer R ? R : any;

export default abstract class ORMModel extends Model {
  /**
   * Return relation fields
   */
  public static relationFields: ApiORMModelRelationsFieldsContract = {};

  /**
   * Relation required fields
   */
  public static relationRequiredFields: string[] = [];

  /**
   * Init api query model
   */
  public static request(): ApiORMQueryBuilderExtended {
    return new ApiORMQueryBuilderExtended(this);
  }

  /**
   * Init api query model
   */
  public static apiExt(): ApiORMQueryBuilder {
    return new ApiORMQueryBuilder(this);
  }

  /**
   * Is model loaded
   */
  protected loaded: boolean = false;

  /**
   * Is model data valid
   */
  protected dataValid: boolean = false;

  /**
   * Original data copy
   */
  private originalData!: Record;

  /**
   * Incomplete model flag
   */
  private incomplete: boolean = false;

  /**
   * Constructor
   */
  constructor(record?: Record) {
    super(record);

    this.overwriteOriginalData();
  }

  /**
   * Set original data
   */
  public setOriginalData() {
    this.originalData = this.data([], true);
  }

  /**
   * Mark model as incomplete
   */
  public setIncomplete() {
    this.incomplete = true;
  }

  /**
   * Check if model is incomplete
   */
  public isIncomplete() {
    return this.incomplete;
  }

  public overwriteOriginalData() {
    this.originalData = this.data();
  }

  /**
   * Check if model has changed
   */
  public get changed(): boolean {
    return Object.entries(this.dataDifferences([], true)).length > 0;
  }

  /**
   * Get loaded param
   */
  public get isLoaded(): boolean {
    return this.loaded;
  }

  /**
   * Get info if model has valid data and can be used
   */
  public get isDataValid(): boolean {
    return this.dataValid;
  }

  public get origin(): Record {
    return this.originalData;
  }

  /**
   * Get model data + exclude fields option
   * @param exclude
   * @param withRelations
   */
  public data(exclude: string[] = [], withRelations: boolean = false): Record {
    const data = this.$toJson();
    const fields = this.$fields();

    Object.keys(data).forEach((param) => {
      if (
        param.includes('$', 0) || // special orm field
        param === 'originalData' || // original data copy
        fields[param] instanceof Relation && !withRelations // relation field
      ) {
        delete data[param];
      }
    });

    return ORMModelDataHelper.remove(data, exclude);
  }

  /**
   * Get model data differences between actual and original + exclude fields option
   * @param exclude
   * @param withRelations
   */
  public dataDifferences(exclude: string[] = [], withRelations: boolean = false): Record {
    return ORMModelDataHelper.compare(
      this.data(exclude, withRelations),
      ORMModelDataHelper.remove(this.originalData, exclude),
    );
  }

  /**
   * Get model fields differences between actual and original
   * @param fields
   */
  public fieldsDataDifferences(fields: string[] = []): Record {
    return ORMModelDataHelper.compare(
      ORMModelDataHelper.filter(this.data(), fields),
      ORMModelDataHelper.filter(this.originalData, fields),
    );
  }

  /**
   * Check if object with differences between actual and original is not empty + exclude fields option
   * @param exclude
   */
  public hasDataDifferences(exclude: string[] = []): boolean {
    return !isEmpty(this.dataDifferences(exclude));
  }

  /**
   * Check if model fields with differences between actual and original is not empty
   * @param fields
   */
  public hasFieldsDifferences(fields: string[] = []): boolean {
    return !isEmpty(this.fieldsDataDifferences(fields));
  }

  /**
   * Reset model data to original copy
   */
  public reset(exclude?: string[]) {
    this.fill(ORMModelDataHelper.remove(this.originalData, exclude));
  }

  /**
   * Synchronize data from model of the same type
   * @param model
   * @param overwriteOriginalData
   * @param selectedFields
   * @param excludeFields
   */
  public sync(
    model: this,
    overwriteOriginalData: boolean = false,
    selectedFields?: string[],
    excludeFields?: string[],
  ): InstanceOf<ORMModel> {
    this.fill(ORMModelDataHelper.filter(model.data(), selectedFields, excludeFields));

    if (overwriteOriginalData) {
      // overwrite original data to not show differences after sync
      this.overwriteOriginalData();
    }

    return this;
  }

  /**
   * Fill fields by data
   * @param data
   */
  public fill(data: IndexableInterface) {
    Object.keys(data).forEach((field: string) => {
      (this as IndexableInterface)[field] = data[field];
    });
  }
}
