import SettingsContract from '@/core/settings/contracts/SettingsContract';
import SettingsStorageAdapter from '@/core/settings/SettingsStorageAdapter';
import { isArray } from 'lodash';

export default class SettingsStorage {
  /**
   * Private fields
   */
  private hooks: SettingsContract = [];
  private activeAdapter!: SettingsStorageAdapter;

  /**
   * Constructor
   * @param adapter
   */
  constructor(adapter: SettingsStorageAdapter) {
    this.adapter(adapter);
  }

  /**
   * Set active storage adapter
   * @param adapter
   * @param sync
   */
  public adapter(adapter: SettingsStorageAdapter, sync: boolean = false) {
    if (sync) {
      // insert params and objects from previous adapter
      adapter.sync(this.activeAdapter.getParams(), this.activeAdapter.getObjects());
    }
    this.activeAdapter = adapter;
  }

  /**
   * Set param
   * @param param
   * @param value
   */
  public setParam(param: string, value: any) {
    this.beforeUpdate(param, value);

    this.activeAdapter.setParam(param, value);

    this.afterUpdate(param, value);

    return this;
  }

  /**
   * Get param
   */
  public getParam(name: string): any {
    return this.activeAdapter.getParam(name);
  }

  /**
   * Check if param exists
   * @param name
   */
  public hasParam(name: string): boolean {
    return this.activeAdapter.hasParam(name);
  }

  /**
   * Get all params
   */
  public getParams(): SettingsContract {
    return this.activeAdapter.getParams();
  }

  /**
   * Remove param
   * @param name
   */
  public removeParam(name: string) {
    return this.activeAdapter.removeParam(name);
  }

  /**
   * Set object type
   * @param name
   * @param object
   */
  public setObject(name: string, object: any) {
    this.activeAdapter.setObject(name, object);

    return this;
  }

  /**
   * Get object type
   * @param name
   */
  public getObject(name: string): any {
    return this.activeAdapter.getObject(name);
  }

  /**
   * Check if object exists
   * @param name
   */
  public hasObject(name: string) {
    return this.activeAdapter.hasObject(name);
  }

  /**
   * Remove object
   * @param name
   */
  public removeObject(name: string) {
    return this.activeAdapter.removeObject(name);
  }

  public clear() {
    return this.activeAdapter.clear();
  }

  /**
   * Set hook after param update
   * @param callback
   */
  public afterUpdateHook(callback: (param: string, value: any) => void, param: string | string[] = 'any') {
    return this.registerHook('after', param, callback);
  }

  /**
   * Set hook before param update
   * @param callback
   */
  public beforeUpdateHook(callback: (param: string, value: any) => void, param: string | string[] = 'any') {
    return this.registerHook('before', param, callback);
  }

  /**
   * Register a hook
   * @param section
   * @param param
   * @param callback
   */
  private registerHook(section: string, param: string | string[], callback: (param: string, value: any) => void) {
    if (this.hooks[section] === undefined) {
      this.hooks[section] = [];
    }
    if (isArray(param)) {
      param.forEach((p: string) => this.registerHook(section, p, callback));
    } else {
      if (this.hooks[section][param] === undefined) {
        this.hooks[section][param] = [];
      }
      this.hooks[section][param].push(callback);
    }
  }

  /**
   * Before param update hooks call
   */
  private beforeUpdate(param: string, value: any) {
    this.callHooks('before', param, value);
  }

  /**
   * After param update hooks call
   */
  private afterUpdate(param: string, value: any) {
    this.callHooks('after', param, value);
  }

  /**
   * Call hooks when param changed for section
   * @param section
   * @param param
   * @param value
   */
  private async callHooks(section: string, param: string, value: any) {
    if (this.hooks[section] === undefined) {
      return;
    }
    const type: string = this.hooks[section][param] === undefined ? 'any' : param;
    if (this.hooks[section][type] && isArray(this.hooks[section][type]) && this.hooks[section][type].length > 0) {
      this.hooks[section][type].forEach(async (callback: (param: string, value: any) => void) => {
        await callback(param, value);
      });
    }
  }
}
