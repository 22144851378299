import ORMModel from '@/core/bridge/orm/ORMModel';

export default class StoreSettingsStorageModel extends ORMModel {
  public static entity = 'settings';

  public static primaryKey = 'name';

  public static fields() {
    return {
      name: this.string(null),
      value: this.attr(null),
    };
  }

  public name!: string;
  public value!: any;
}
