import { authRoutes } from '@/app/routes/modules/authRoutes';
import { documentsRoutes } from '@/app/routes/modules/documentsRoutes';
import { onlineOrdersRoutes } from '@/app/routes/modules/onlineOrdersRoutes';
import { productsRoutes } from '@/app/routes/modules/productsRoutes';
import { cartRoutes } from '@/app/routes/modules/cartRoutes';

const appRoutes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/app/views/HomeView.vue'),
    meta: {
      public: true,
      layout: 'public',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/app/views/DashboardView.vue'),
    meta: {
      title: 'dashboard',
    },
  },

  {
    path: '/featured',
    name: 'featured',
    component: () => import(/* webpackChunkName: "featured" */ '@/app/views/FeaturedView.vue'),
    meta: {
    },
  },

  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/app/views/ProfileView.vue'),
    meta: {
      title: 'profile',
    },
  },

  {
    path: '/information',
    name: 'information',
    component: () => import(/* webpackChunkName: "profile" */ '@/app/views/InformationView.vue'),
    meta: {
      title: 'information',
    },
  },

  {
    path: '/regulations',
    name: 'regulations',
    component: () => import(/* webpackChunkName: "profile" */ '@/app/views/RegulationsView.vue'),
    meta: {
      title: 'regulations',
      public: true,
    },
  },

  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "profile" */ '@/app/views/PrivacyPolicyView.vue'),
    meta: {
      title: 'privacyPolicy',
      public: true,
    },
  },

  {
    path: '/redirect',
    name: 'redirect',
    component: () => import(/* webpackChunkName: "redirect" */ '@/shared/resources/views/RedirectView.vue'),
  },

  ...authRoutes,
  ...cartRoutes,
  ...productsRoutes,
  ...documentsRoutes,
  ...onlineOrdersRoutes,

  {
    path: '*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "not-found" */ '@/app/views/NotFoundView.vue'),
  },
];

export { appRoutes };
