import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';
import CartItemCoreModel from '@/modules/carts/models/CartItemCoreModel';
import AmountsFormattedInterface from '@/shared/lib/interfaces/AmountsFormattedInterface';
import Locale from '@/core/locale/Locale';

export default class CartCoreModel extends ORMModelExtended {
  /**
   * ORM entity
   */
  public static entity = 'carts';

  /**
   * Define id length
   */
  public static idLength: number = 16;

  /**
   * Fields
   */
  public static fields() {
    return {
      id: this.string(''),
      contractorId: this.number(0),
      items: this.hasMany(CartItemCoreModel, 'cartId'),
    };
  }

  /**
   * Fields
   */
  public id!: string;
  public contractorId!: number;
  public items!: CartItemCoreModel[];

  /**
   * Get total net
   */
  public get totalNet(): number {
    return this._calculateTotal('totalNet');
  }

  /**
   * Get total gross
   */
  public get totalGross(): number {
    return this._calculateTotal('totalGross');
  }

  /**
   * Get total vat
   */
  public get totalVat(): number {
    return this._calculateTotal('totalVat');
  }

  /**
   * Get totals with currencies
   */
  public get totalsWithCurrencies(): AmountsFormattedInterface {
    return {
      net: Locale.priceWithCurrency(this.totalNet),
      gross: Locale.priceWithCurrency(this.totalGross),
      vat: Locale.priceWithCurrency(this.totalVat),
    };
  }

  /**
   * Convert to object
   */
  public toObject(): object {
    return {
      id: this.id,
      contractorId: this.contractorId,
      items: this.items.map((item: CartItemCoreModel) => {
        return item.toObject();
      }),
    };
  }

  /**
   * Calculate total by given param
   * @param param
   * @private
   */
  private _calculateTotal(param: string): number {
    return this.items.reduce((total: number, item: CartItemCoreModel) => {
      total += item[param];
      return total;
    }, 0);
  }
}
