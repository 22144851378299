



















import { Component, Emit, Prop } from 'vue-property-decorator';

import Field from './Field.vue';

@Component
export default class NumberField extends Field {
  /**
   * Props
   */
  @Prop({ default: false, type: Boolean }) private small!: boolean;
  @Prop({ default: 0 }) private min!: number;
  @Prop({ default: 99999 }) private max!: number | null;

  /**
   * Getters
   */
  private get disableDecrease(): boolean {
    return parseInt(this.value, 0) - 1 < this.min;
  }

  private get disableIncrease(): boolean {
    if (!this.max) {
      return false;
    }

    return parseInt(this.value, 0) + 1 > this.max;
  }

  /**
   * Class names
   */
  private get classNames(): object {
    return {
      'number-field': true,
      'small': this.small,
      'disable-decrease': this.disableDecrease,
      'disable-increase': this.disableIncrease,
    };
  }

  /**
   * Methods
   */
  protected emitValue(value: any) {
    this.$emit('input', parseInt(value, 0));
  }

  /**
   * Emit events
   */
  @Emit()
  protected input(value: any) {
    return parseInt(value, 0);
  }

  /**
   * Handlers
   */
  private onDecreaseButtonClick() {
    if (this.disableDecrease) {
      return null;
    }

    const parsed: number = parseInt(this.value, 0);
    const newValue: number = !isNaN(parsed) ? parsed - 1 : this.min;
    this.emitValue(newValue);
  }

  private onIncreaseButtonClick() {
    if (this.disableIncrease) {
      return null;
    }

    const parsed: number = parseInt(this.value, 0);
    const newValue: number = !isNaN(parsed) ? parsed + 1 : this.min;
    this.emitValue(newValue);
  }
}
