// core modules
import StoreSettingsStorageModel from '@/core/settings/models/StoreSettingsStorageModel';
import FileCoreModel from '@/modules/files/models/FileCoreModel';

// app modules
import UserCoreModel from '@/modules/users/models/UserCoreModel'; // TODO

import ContractorCoreModel from '@/modules/contractors/models/ContractorCoreModel'; // TODO

import ProductCoreModel from '@/modules/products/models/ProductCoreModel'; // TODO
import ProductFileCoreModel from '@/modules/products/models/ProductFileCoreModel'; // TODO
import ProductTagCoreModel from '@/modules/products/models/ProductTagCoreModel'; // TODO

import DocumentModel from '@/app/modules/documents/models/DocumentModel';
import DocumentItemCoreModel from '@/modules/documents/models/DocumentItemCoreModel'; // TODO
import DocumentDataCoreModel from '@/modules/documents/models/DocumentDataCoreModel'; // TODO

import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel'; // TODO
import OnlineOrderStatusCoreModel from '@/modules/online-orders/models/OnlineOrderStatusCoreModel'; // TODO

import CategoryCoreModel from '@/modules/categories/models/CategoryCoreModel'; // TODO

import CartCoreModel from '@/modules/carts/models/CartCoreModel'; // TODO
import CartItemCoreModel from '@/modules/carts/models/CartItemCoreModel'; // TODO

import OnlineOrderAddressDataCoreModel from '@/modules/online-orders/models/OnlineOrderAddressDataCoreModel'; // TODO
import OnlineOrderItemCoreModel from '@/modules/online-orders/models/OnlineOrderItemCoreModel'; // TODO

import TagCoreModel from '@/modules/tags/models/TagCoreModel'; // TODO

const modules: any[] = [
  // core modules
  StoreSettingsStorageModel,
  FileCoreModel,

  // app modules
  UserCoreModel,

  ContractorCoreModel,

  ProductCoreModel,
  ProductFileCoreModel,
  ProductTagCoreModel,

  CategoryCoreModel,

  DocumentModel,
  DocumentItemCoreModel,
  DocumentDataCoreModel,

  OnlineOrderCoreModel,
  OnlineOrderItemCoreModel,
  OnlineOrderStatusCoreModel,
  OnlineOrderAddressDataCoreModel,

  TagCoreModel,

  CartCoreModel,
  CartItemCoreModel,
];

export { modules };
