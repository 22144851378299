

















import { Component, Vue, Prop } from 'vue-property-decorator';

import Loader from '@/shared/resources/components/Loader.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';

@Component({
  components: {
    GridRow,
    Loader,
  },
})
export default class ImgViewer extends Vue {
  /**
   * Props
   */
  @Prop({ default: false, type: Boolean }) private smallLoader!: boolean;
}
