import {isArray, uniq, isString, isNumber} from 'lodash';

import Pagination from '@/core/support/pagination/Pagination';
import Model from '@vuex-orm/core/dist/src/model/Model';
import ORMModel from '@/core/bridge/orm/ORMModel';
import ORMModelFieldsContract from '@/core/bridge/orm/contracts/ORMModelFieldsContract';
import ApiORMRelationsQueryBuilder from '@/core/bridge/orm/api/relations/ApiORMRelationsQueryBuilder';
import Collection from '@/core/support/collection/Collection';

declare type InstanceOf<T> = T extends new (...args: any[]) => infer R ? R : any;

export default class ORMCollection extends Collection {

  /**
   * Class model stored
   */
  public model: InstanceOf<Model> | null = null;

  /**
   * Store for models ids
   */
  private itemsIds: Array<number | string> = [];

  /**
   * Relations
   */
  private relations: ApiORMRelationsQueryBuilder | null = null;

  /**
   * Create new collection
   * @param itemsIds
   * @param model
   * @param pagination
   * @param relations
   */
  public constructor(
    itemsIds: Array<number | string> | null = null,
    model: InstanceOf<Model> | null = null,
    pagination: Pagination | null = null,
    relations: ApiORMRelationsQueryBuilder | null = null,
  ) {
    super();

    if (itemsIds !== null) {
      this.itemsIds = itemsIds;
      this.injection = true;
    }
    if (pagination !== null) {
      this.pagination = pagination;
    }
    this.relations = relations;
    this.model = model;
  }

  /**
   * Get items from store
   */
  public get items(): Array<InstanceOf<Model>> {
    if (!this.model || !this.itemsIds.length) {
      return [];
    }
    // new orm query
    const query = this.model.query();

    // add relations to query
    if (this.relations && !this.relations.empty()) {
      this.relations.buildORMQueryRelations(query, this.model);
    }

    if (Array.isArray(this.model.primaryKey)) {
      return query.findIn(this.itemsIds);
    } else {
      return query.whereIdIn(this.itemsIds).get();
    }
  }

  /**
   * Get model by id
   */
  public find<T extends Model>(id: number | string): InstanceOf<T> | null {
    return this.model.find(id);
  }

  /**
   * Get model by callback fn
   * @param callbackfn
   */
  public findBy<T extends Model>(callbackfn: (item: InstanceOf<Model>) => any): InstanceOf<T> {
    return this.items.find(callbackfn);
  }

  /**
   * Get collection items ids
   */
  public get ids(): Array<number | string> {
    return this.itemsIds;
  }

  /**
   * Check if collection has model by id
   * @param id
   */
  public hasId(id: number | string) {
    return this.itemsIds.indexOf(id) !== -1;
  }

  /**
   * Get items count
   */
  public get count() {
    return this.items.length;
  }

  /**
   * Return model on index
   * @param index
   */
  public item<T extends Model>(index: number): InstanceOf<T> | null {
    if (this.itemsIds[index]) {
      return this.items[index];
    }
    return null;
  }

  /**
   * Add model/s to collection
   * @param item
   */
  public add(item: number | string | InstanceOf<Model> | ORMCollection | Array<InstanceOf<Model>>) {
    this.injection = true;

    if (item instanceof ORMCollection) {
      if (!this.pagination) {
        this.pagination = item.pagination;
      }
      if (!this.relations) {
        this.relations = item.relations;
      }
      if (!this.model) {
        this.model = item.model;
      }
      if (isArray(item.itemsIds)) {
        item.itemsIds.forEach((id: number | string) => {
          this.addId(id, false, false);
        });
      }
    } else if (this.instanceOfModel(item) && this.model) {
      this.addId(this.model.getIdFromRecord(item));
    } else if (isArray(item) && (isNumber(item[0]) || isString(item[0]))) {
      item.forEach((iteratedItem: string | number) => this.addId(iteratedItem, false));
    } else if (isArray(item) && this.model) {
      item.forEach((model: InstanceOf<Model>) => this.addId(this.model.getIdFromRecord(model), false));
    } else {
      this.addId(item);
    }
    return this;
  }

  /**
   * Add many items to collection
   * @param items
   */
  public addMany(items: Array<number | string> | Array<InstanceOf<Model>>) {
    items.forEach((item: number | string | InstanceOf<Model>) => {
      this.add(item);
    });
  }

  /**
   * Add model/s to the beginning of collection
   * @param item
   */
  public addToBeginning(item: number | string | InstanceOf<Model> | ORMCollection | Array<InstanceOf<Model>>) {
    this.injection = true;
    if (item instanceof ORMCollection) {
      this.pagination = item.pagination;
      this.relations = item.relations;
      this.model = item.model;
      if (isArray(item.itemsIds)) {
        item.itemsIds.reverse().forEach((id: number | string) => this.addId(id, true, false));
      }
    } else if (this.instanceOfModel(item) && this.model) {
      this.addId(this.model && this.model.getIdFromRecord(item), true);
    } else if (isArray(item) && (isNumber(item[0]) || isString(item[0]))) {
      item.forEach((iteratedItem: string | number) => this.addId(iteratedItem, true));
    } else if (isArray(item) && this.model) {
      item.forEach((iteratedItem: InstanceOf<Model>) => {
        this.addId(this.model && this.model.getIdFromRecord(iteratedItem), true);
      });
    } else {
      this.addId(item, true);
    }
    return this;
  }

  /**
   * Remove model/s from collection
   * @param item
   */
  public remove(item: number | string | InstanceOf<Model>) {
    if (this.instanceOfModel(item)) {
      this.removeId(this.model.getIdFromRecord(item));
    } else {
      this.removeId(item);
    }
    return this;
  }

  /**
   * Return new filtered collection
   * @param callbackfn
   */
  public filter(callbackfn: (item: InstanceOf<Model>) => any): ORMCollection {
    const ids: number[] = this.items.filter(callbackfn).map(
      (item: InstanceOf<Model>) => this.model.getIdFromRecord(item));

    return new ORMCollection(ids, this.model, this.pagination, this.relations);
  }

  /**
   * Check if all items are loaded
   */
  public get loaded(): boolean {
    if (!this.isInjected) {
      return false;
    }
    return !this.items.some((item: ORMModel) => {
      return !item.isLoaded;
    });
  }

  /**
   * Map collection items unique and not empty values of given field
   * @param fieldName
   */
  public mapFieldValues(fieldName: string) {
    return uniq(
      this.items
        .map((item: any) => {
          return (item as ORMModelFieldsContract)[fieldName];
        })
        .filter((value: any) => value),
    );
  }

  /**
   * Remove all collection items
   */
  public removeAll() {
    this.itemsIds = [];
    this.pagination.setTotal(0);
  }

  /**
   * Check if model exist on active index
   */
  public exists(): boolean {
    return this.itemsIds[this.index] !== undefined;
  }

  /**
   * Check if object is an model of ORM model
   * @param value
   */
  private instanceOfModel(value: object): boolean {
    return value instanceof ORMModel;
  }

  /**
   * Add new id to collection
   * @param id
   * @param beginning
   * @param updatePagination
   */
  private addId(id: number | string, beginning: boolean = false, updatePagination: boolean = true) {
    if (this.itemsIds.indexOf(id) === -1) {
      if (beginning) {
        this.itemsIds.unshift(id);
      } else {
        this.itemsIds.push(id);
      }

      if (updatePagination) {
        this.pagination.setTotal(this.count);
      }
    }
  }

  /**
   * Remove id from collection
   * @param id
   */
  private removeId(id: number | string) {
    const pos = this.itemsIds.indexOf(id);
    if (pos !== -1) {
      this.itemsIds.splice(pos, 1);
      this.pagination.setTotal(this.count);
    }
  }
}
