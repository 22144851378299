




import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Icon extends Vue {
  /**
   * Props
   */
  @Prop() private name!: string;
  @Prop({default: ''}) private className!: string;
  @Prop({default: () => {/**/}}) private classNames!: object;
  @Prop({ default: false, type: Boolean }) private left!: string;
  @Prop({ default: false, type: Boolean }) private right!: string;
  @Prop({ default: false, type: Boolean }) private small!: string;
  @Prop({ default: false, type: Boolean }) private large!: string;

  /**
   * Data
   */
  private get elementClassNames(): object {
    return {
      'custom-icon': true,
      'material-icons-outlined': true,
      [this.className]: true,
      'right': this.right,
      'left': this.left,
      'small': this.small,
      'large': this.large,
      ...this.classNames,
    };
  }
}
