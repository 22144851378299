import SettingsStorage from '@/core/settings/SettingsStorage';
import SettingsStorageRegistry from '@/core/settings/SettingsStorageRegistry';
import GlobalSettingsEnum from '@/shared/lib/settings/GlobalSettingsEnum';
import ApiHeaders from '@/core/api/headers/ApiHeaders';
import ApiHeadersGroupEnum from '@/core/api/headers/support/ApiHeadersGroupEnum';
import ApiSchema from '@/core/api/schema/ApiSchema';
import MemorySettingsStorageAdapter from '@/core/settings/adapters/MemorySettingsStorageAdapter';

export default class GlobalSettings {
  /**
   * @info    Global settings
   *
   * @author  Lukas Laskowski & Karol Chojnacki 11.05.2019
   * @update  Lukas Laskowski 16.07.2019
   */

  /**
   * Set api URL
   * @param url
   */
  public static set apiURL(url: string) {
    this.storage.setParam(GlobalSettingsEnum.PARAM_API_URL, url);
  }

  /**
   * Get token
   */
  public static get apiURL(): string {
    return this.storage.getParam(GlobalSettingsEnum.PARAM_API_URL);
  }

  /**
   * Set token
   * @param token
   */
  public static set token(token: string) {
    this.storage.setParam(GlobalSettingsEnum.PARAM_TOKEN, token);
    ApiHeaders.set([ApiHeadersGroupEnum.DATA], 'Authorization', 'Bearer ' + token);
  }

  /**
   * Get token
   */
  public static get token(): string {
    return this.storage.getParam(GlobalSettingsEnum.PARAM_TOKEN);
  }

  /**
   * Schema
   */
  public static set schema(schema: ApiSchema) {
    this.storage.setObject(GlobalSettingsEnum.PARAM_SCHEMA, schema);
  }
  public static get schema(): ApiSchema {
    return this.storage.getObject(GlobalSettingsEnum.PARAM_SCHEMA);
  }

  /**
   * Lang
   */
  public static get lang(): string {
    return this.storage.getObject(GlobalSettingsEnum.PARAM_LANG);
  }
  public static set lang(lang: string) {
    this.storage.setObject(GlobalSettingsEnum.PARAM_LANG, lang);
  }

  /**
   * Set storage
   */
  public static readonly storage: SettingsStorage = SettingsStorageRegistry.register(
    new MemorySettingsStorageAdapter('GLOBAL'),
  );
}
