import SettingsStorage from '@/core/settings/SettingsStorage';
import SettingsStorageRegistry from '@/core/settings/SettingsStorageRegistry';
import BrowserLocalSettingsStorageAdapter from '@/core/settings/adapters/BrowserLocalSettingsStorageAdapter';
import CartCoreModel from '@/modules/carts/models/CartCoreModel';
import { randomString } from '@/core/helpers/utils/StringUtils';
import ProductCoreModel from '@/modules/products/models/ProductCoreModel';
import CartItemCoreModel from '@/modules/carts/models/CartItemCoreModel';

import AppSettings from '@/app/lib/settings/AppSettings';

const CART_DATA = 'DATA';
const CART_STORAGE_PREFIX = 'CART';

export default class Cart {
  /**
   * Cart id
   */
  public static cartId: string;

  /**
   * Initiated state
   */
  public static initiated: boolean = false;

  /**
   * Cart model
   */
  public static get model(): CartCoreModel {
    return CartCoreModel
      .query()
      .with('items')
      .where('id', this.cartId)
      .first()!;
  }

  /**
   * Collect items product ids
   */
  public static get productIds(): number[] {
    return this.items().map((item: CartItemCoreModel) => {
      return item.productId;
    });
  }

  /**
   * Set storage
   */
  public static readonly storage: SettingsStorage = SettingsStorageRegistry.register(
    new BrowserLocalSettingsStorageAdapter(CART_STORAGE_PREFIX),
  );

  /**
   * Get cart items
   */
  public static items(): CartItemCoreModel[] {
    return CartItemCoreModel
      .query()
      .with('product')
      .where('cartId', this.cartId)
      .get();
  }

  /**
   * Check if cart is initiated
   */
  public static isInitiated(): boolean {
    return !!this.model;
  }

  /**
   * Load data from cache
   */
  public static async loadData() {
    const cartData: any | null = this.storage.getObject(CART_DATA); // TODO interface

    if (cartData && cartData.id && cartData.contractorId === AppSettings.contractorId) {
      this.cartId = cartData.id;

      if (cartData.items.length) {
        try {
          await ProductCoreModel
            .apiExt()
            .where('ids[]', cartData.items.map((item: CartItemCoreModel) => item.productId))
            .limit(-1)
            .fetch();
        } catch (ex) {
          // TODO
        }
      }

      await CartCoreModel.insert({ data: cartData });
    } else {
      this.cartId = await this.createCart();
      this.syncCart();
    }

    this.initiated = true;
  }

  /**
   * Clear cart by creating new one
   */
  public static async clearCart() {
    this.removeAllItems();
  }

  /**
   * Count cart items
   */
  public static count(): number {
    return this.items().length;
  }

  /**
   * Count total items quantities
   */
  public static countTotalItems(): number {
    return this.items().reduce((total: number, item: CartItemCoreModel) => {
      total += parseInt(item.quantity.toString(), 0);
      return total;
    }, 0);
  }

  /**
   * Check if cart is empty
   */
  public static isEmpty(): boolean {
    return this.count() === 0;
  }

  /**
   * Add product to cart
   * @param product
   * @param quantity
   */
  public static async addItem(product: ProductCoreModel, quantity: any = 1) {
    await new Promise((resolve: any) => setTimeout(resolve, 1000));

    try {
      await this.validateQuantity(product.id, quantity);

      const parsedQuantity: number = parseInt(quantity, 0);
      const item: CartItemCoreModel | null = this.getItemByProductId(product.id);

      if (item) {
        await CartItemCoreModel
          .update({
            where: item.id,
            data: {
              quantity: item.quantity + parsedQuantity,
            },
          });
      } else {
        await CartItemCoreModel.insert({
          data: {
            id: randomString(CartCoreModel.idLength),
            cartId: this.cartId,
            productId: product.id,
            name: product.name,
            vatRate: product.vatRate,
            price: product['price' + AppSettings.contractorPrices],
            unit: product.unit,
            thumbPath: product.thumbPath,
            quantity: parsedQuantity,
          },
        });
      }

      this.syncCart();
    } catch (ex) {
      throw ex;
    }
  }

  /**
   * Update item quantity by given product id
   * @param item
   * @param quantity
   */
  public static async updateItemQuantity(item: CartItemCoreModel, quantity: number) {
    try {
      await this.validateQuantity(item.productId, quantity, false);

      await CartItemCoreModel
        .update({
          where: item.id,
          data: {
            quantity,
          },
        });

      this.syncCart();
    } catch (ex) {
      throw ex;
    }
  }

  /**
   * Remove from cart
   * @param item
   */
  public static async removeItem(item: CartItemCoreModel) {
    await CartItemCoreModel.delete(item.id);
    this.syncCart();
  }

  /**
   * Remove all items
   */
  public static async removeAllItems() {
    await this.items().forEach(async (item: CartItemCoreModel) => {
      await this.removeItem(item);
    });

    this.syncCart();
  }

  /**
   * Get item by product id
   * @param productId
   */
  public static getItemByProductId(productId: number): CartItemCoreModel | null {
    return this.items().find((item: CartItemCoreModel) => item.productId === productId) || null;
  }

  /**
   * Sync cart to cache
   */
  private static syncCart() {
    this.storage.setObject(CART_DATA, this.model!.toObject());
  }

  /**
   * Create new cart
   */
  private static async createCart(): Promise<string> {
    const id: string = randomString(CartCoreModel.idLength);

    await CartCoreModel.insert({
      data: {
        id,
        contractorId: AppSettings.contractorId,
      },
    });

    return id;
  }

  /**
   * Validate given product quantity
   * @param productId
   * @param quantity
   * @param includeItemQuantity
   */
  private static async validateQuantity(productId: number, quantity: any, includeItemQuantity: boolean = true) {
    if (isNaN(quantity)) {
      throw Error('Nieprawidłowa liczba.');
    }

    const product: ProductCoreModel | null = await this.getProduct(productId);

    if (!product) {
      throw Error('Wystąpił błąd przy pobieraniu informacji o produkcie. Spróbuj pobownie');
    }

    if (product.onOrder) {
      return true;
    }

    const item: CartItemCoreModel | null = this.getItemByProductId(productId);

    const maxAmount: number = includeItemQuantity && item
      ? item.maxQuantity
      : product.amount;

    if (maxAmount < quantity) {
      throw Error('Przekroczono stan produktu. Max. liczba: ' + maxAmount);
    }
  }

  /**
   * Get product
   * @param productId
   */
  private static async getProduct(productId: number) {
    try {
      return await ProductCoreModel
        .apiExt()
        .param('id', productId)
        .get();
    } catch (ex) {
      return null;
    }
  }
}
