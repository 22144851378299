import SettingsContract from '@/core/settings/contracts/SettingsContract';

export default abstract class SettingsStorageAdapter {
  /**
   * Custom prefix for unique
   */
  protected prefix?: string;

  constructor(prefix?: string) {
    if (prefix) {
      this.prefix = prefix;
    }
  }

  /**
   * Synchronize exists params with adapter
   * @param params
   * @param objects
   */
  public abstract sync(params: SettingsContract, objects: SettingsContract): void;

  /**
   * Set param
   * @param param
   * @param value
   */
  public abstract setParam(param: string, value: string | string[] | number | number[] | boolean): void;

  /**
   * Get param
   */
  public abstract getParam(name: string): any;

  /**
   * Check if param exists
   * @param name
   */
  public abstract hasParam(name: string): boolean;

  /**
   * Get all params
   */
  public abstract getParams(): SettingsContract;

  /**
   * Remove param
   */
  public abstract removeParam(name: string): void;

  /**
   * Set object type
   * @param name
   * @param object
   */
  public abstract setObject(name: string, object: any): void;

  /**
   * Get object type
   * @param name
   */
  public abstract getObject(name: string): any;

  /**
   * Get all objects
   */
  public abstract getObjects(): SettingsContract;

  /**
   * Check if object exists
   * @param name
   */
  public abstract hasObject(name: string): boolean;

  /**
   * Remove object
   */
  public abstract removeObject(name: string): void;

  /**
   * Clear collected data
   */
  public abstract clear(): void;

  /**
   * Get prefixed name
   * @param name
   */
  protected getPrefixed(name: string) {
    return (this.prefix ? `${this.prefix}:` : '') + name;
  }

  /**
   * Get unprefixed name
   * @param name
   */
  protected getUnPrefixed(name: string) {
    return this.prefix ? name.replace(`${this.prefix}:`, '') : '';
  }
}
