























































import { Component, Vue } from 'vue-property-decorator';
import Icon from '@/shared/resources/components/Icon.vue';
import UISettings from '@/app/lib/settings/UISettings';
import Button from '@/shared/resources/components/buttons/Button.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import GridSpacer from '@/shared/resources/components/grid/GridSpacer.vue';
import Badge from '@/shared/resources/components/Badge.vue';
import TopBarButton from '@/app/layouts/default/top-bar/components/TopBarButton.vue';
import Auth from '@/app/lib/auth/Auth';
import AppSettings from '@/app/lib/settings/AppSettings';
import TopBarCartButton from '@/app/layouts/default/top-bar/components/TopBarCartButton.vue';
import { redirect } from '@/shared/lib/support/router/RouterUtils';

@Component({
  components: {
    TopBarCartButton,
    TopBarButton,
    Badge,
    GridSpacer,
    GridCol,
    GridRow,
    Button,
    Icon,
  },
})
export default class TopBar extends Vue {
  /**
   * Handle drawer
   */
  private get drawer(): boolean {
    return UISettings.drawerVisible;
  }

  private set drawer(value: boolean) {
    UISettings.drawerVisible = value;
  }

  /**
   * Getters
   */
  private get contractorTradeName(): string | null {
    return AppSettings.user?.contractor?.tradeName || null;
  }

  private get fixed(): boolean {
    return false; // TODO
    // return this.$vuetify.breakpoint.smAndDown;
  }

  private get absolute(): boolean {
    return !this.fixed;
  }

  /**
   * Display getters
   */
  private get displayNavButton(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  private get displayShortLogo(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  private get displayProfileButton(): boolean {
    return this.$vuetify.breakpoint.lgAndUp;
  }

  private get displayLogoutButton(): boolean {
    return this.$vuetify.breakpoint.lgAndUp;
  }

  private get displayDesktopSpace(): boolean {
    return this.$vuetify.breakpoint.lgAndUp;
  }

  private get displayMobileSpace(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  /**
   * Handlers
   */
  private onBarNavIconClick() {
    this.drawer = !this.drawer;
  }
}
