const PRICE_A: string = 'A';
const PRICE_B: string = 'B';
const PRICE_C: string = 'C';
const PRICE_D: string = 'D';
const PRICE_ONLINE: string = 'online';

const PRICE_DEFAULT: string = PRICE_D;

const PRICE_DECIMALS: number = 2;

interface PricesInterface {
  [key: string]: PriceInterface;
}

interface PriceInterface {
  name: string;
  isGross: boolean;
}

const PRICES_LIST: PricesInterface = {
  [PRICE_A]: {
    name: 'A',
    isGross: false,
  },
  [PRICE_B]: {
    name: 'B',
    isGross: false,
  },
  [PRICE_C]: {
    name: 'C',
    isGross: false,
  },
  [PRICE_D]: {
    name: 'D',
    isGross: true,
  },
  // TODO enable this, but block in price field in some cases
  // [PRICE_ONLINE]: {
  //   name: 'ONLINE',
  //   isGross: true
  // },
};

export {
  PRICE_A,
  PRICE_B,
  PRICE_C,
  PRICE_D,
  PRICE_ONLINE,

  PRICE_DEFAULT,
  PRICE_DECIMALS,
  PRICES_LIST,
};
