





















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ProductCoreModel from '@/modules/products/models/ProductCoreModel';
import Button from '@/shared/resources/components/buttons/Button.vue';
import UISettings from '@/app/lib/settings/UISettings';
import Loader from '@/shared/resources/components/Loader.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import ProductPhotosCarousel from './partials/details/ProductPhotosCarousel.vue';
import Card from '@/shared/resources/components/cards/Card.vue';
import CartItemCoreModel from '@/modules/carts/models/CartItemCoreModel';
import Cart from '@/app/lib/cart/Cart';
import ProductAvailability from './partials/details/ProductAvailability.vue';
import ProductAddToCartButton from './partials/details/ProductAddToCartButton.vue';
import Divider from '@/shared/resources/components/Divider.vue';
import ProductPrice from '@/app/modules/products/components/partials/details/ProductPrice.vue';
import ProductDescription from '@/app/modules/products/components/partials/details/ProductDescription.vue';
import TagsCloud from '@/app/modules/tags/components/partials/TagsCloud.vue';
import Alert from '@/shared/resources/components/Alert.vue';
import { redirect } from '@/shared/lib/support/router/RouterUtils';
import { amountInPackText } from '@/app/modules/products/productsHelpers';

@Component({
  components: {
    Alert,
    TagsCloud,
    ProductDescription,
    ProductPrice,
    ProductAddToCartButton,
    Divider,
    ProductAvailability,
    Card,
    ProductPhotosCarousel,
    GridCol,
    GridRow,
    Loader,
    Button,
  },
})
export default class ProductDetails extends Vue {
  /**
   * Props
   */
  @Prop() private id!: number;

  /**
   * Data
   */
  private product: ProductCoreModel | null = null;
  private loading: boolean = false;
  private error: boolean = false;

  /**
   * Display getters
   */
  private get displayDetails(): boolean {
    return !this.loading && !!this.product;
  }

  private get displayLoader(): boolean {
    return this.loading;
  }

  private get displayCode(): boolean {
    return !!this.product?.code;
  }

  private get displayCategory(): boolean {
    return !!this.product?.category;
  }

  private get displayAmountInUnit(): boolean {
    return !!this.product?.amountInPack;
  }

  private get displayDescription(): boolean {
    return !!this.product && this.product.hasDescription;
  }

  private get displayError(): boolean {
    return this.error;
  }

  /**
   * Getters
   */
  private get amountInUnitText(): string {
    return amountInPackText(this.product!);
  }

  /**
   * Lifecycle hooks
   */
  private created() {
    this.getProduct();
  }

  /**
   * Methods
   */
  private async getProduct() {
    this.loading = true;

    try {
      this.product = await ProductCoreModel
          .apiExt()
          .param('id', this.id)
          .with(['category', 'files', 'tags'])
          .get();
    } catch (ex) {
      // TODO
      this.error = true;
    }

    this.loading = false;
  }

  /**
   * Handlers
   */
  private onCategoryLinkClick(categoryId: number) {
    redirect({ name: 'products.category', params: { id: categoryId } });
    UISettings.closeSideModals();
  }
}
