




import { Component, Vue, Watch } from 'vue-property-decorator';

import DefaultLayout from '@/app/layouts/default/DefaultLayout.vue';
import AuthLayout from '@/app/layouts/auth/AuthLayout.vue';
import PublicLayout from '@/app/layouts/PublicLayout.vue';
import CheckingLayout from '@/app/layouts/CheckingLayout.vue';

import UISettings from '@/app/lib/settings/UISettings';

const layoutSuffix = 'Layout';

@Component({
  components: {
    CheckingLayout,
    PublicLayout,
    AuthLayout,
    DefaultLayout,
  },
})
export default class App extends Vue {
  /**
   * Data
   */
  private layout: string = 'checking';

  /**
   * Getters
   */
  private get layoutComponent(): string {
    return this.layout + layoutSuffix;
  }

  private get uiLayout(): string {
    return UISettings.layout;
  }

  /**
   * Watchers
   * @param layout
   */
  @Watch('uiLayout', { immediate: true })
  private watchUILayout(layout: string) {
    this.layout = layout;
  }
}
