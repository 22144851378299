import SettingsStorage from '@/core/settings/SettingsStorage';
import SettingsStorageAdapter from '@/core/settings/SettingsStorageAdapter';
import SettingsContract from '@/core/settings/contracts/SettingsContract';

export default class SettingsStorageRegistry {
  /**
   * Register
   * @param adapter
   */
  public static register(adapter: SettingsStorageAdapter) {
    const storage: SettingsStorage = new SettingsStorage(adapter);

    this.registry.push(storage);

    return storage;
  }

  /**
   * Get all registered storages
   */
  public static getList() {
    return this.registry;
  }

  /**
   * Get merged params of all registered storages
   */
  public static getMerged(): SettingsContract {
    let settings: SettingsContract = {};
    this.registry.map((storage: SettingsStorage) => {
      settings = {
        ...settings,
        ...storage.getParams(),
      };
    });
    return settings;
  }

  private static registry: SettingsStorage[] = [];
}
