const documentsRoutes: any = [
  {
    path: '/invoices',
    component: () => import(/* webpackChunkName: "invoices" */ '@/shared/resources/views/EmptyRouterView.vue'),
    meta: {
      title: 'invoices',
    },
    children: [
      {
        path: '',
        name: 'invoices',
        component: () => import('@/app/views/modules/documents/InvoicesView.vue'),
      },
      {
        path: ':id',
        name: 'invoices.details',
        component: () => import('@/app/views/modules/documents/InvoiceDetailsView.vue'),
      },
    ],
  },
];

export { documentsRoutes };
