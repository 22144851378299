import DocumentCoreModel from '@/modules/documents/models/DocumentCoreModel';
import ProductCoreModel from '@/modules/products/models/ProductCoreModel';
import { ApiORMModelRelationsFieldsContract } from '@/core/bridge/orm/api/relations/contracts/ApiORMModelRelationsFieldsContract';
import { ApiRelationModelsEnum } from '@/shared/lib/api/relations/ApiRelationModelsEnum';
import Locale from '@/core/locale/Locale';
import {
  calculateGrossPrice,
  calculateNetPrice,
  calculateVatPrice,
  formatPrice,
} from '@/modules/prices/pricesUtils';
import AmountsFormattedInterface from '@/shared/lib/interfaces/AmountsFormattedInterface';
import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';

export default class DocumentItemCoreModel extends ORMModelExtended {
  /**
   * ORM entity name
   */
  public static entity: string = 'document-items';

  /**
   * API methods conf
   */
  public static methodConf = {
    http: {
      url: '/v2/documents/:documentId/items/',
    },
  };

  /**
   * Relation fields
   */
  public static relationFields: ApiORMModelRelationsFieldsContract = {
    [ApiRelationModelsEnum.PRODUCT]: 'product',
  };

  /**
   * ORM fields
   */
  public static fields() {
    return {
      id: this.number(null),

      documentId: this.number(0).nullable(),
      document: this.belongsTo(DocumentCoreModel, 'documentId'),

      productId: this.number(0).nullable(),
      product: this.belongsTo(ProductCoreModel, 'productId'),

      name: this.string(null).nullable(),
      quantity: this.number(null).nullable(),
      unit: this.string(''),

      vatRate: this.number(null).nullable(),

      priceNet: this.number(0),
      priceVat: this.number(0),
      priceGross: this.number(0),

      totalNet: this.number(0),
      totalVat: this.number(0),
      totalGross: this.number(0),
    };
  }

  public id!: number;

  public documentId!: number | null;
  public document!: DocumentCoreModel;

  public productId!: number | null;
  public product!: ProductCoreModel | null;

  public name!: string;
  public quantity!: number | null;
  public unit!: string;

  public vatRate!: string | number;

  public priceNet!: number;
  public priceVat!: number;
  public priceGross!: number;

  public totalNet!: number;
  public totalVat!: number;
  public totalGross!: number;

  public hasGrossPrices: boolean = false;

  /**
   * Check if is product based
   */
  public get isProductBased(): boolean {
    return !!this.productId;
  }

  /**
   * Check if has product type product
   */
  public get hasProductTypeProduct(): boolean {
    if (!this.product) {
      return false;
    }

    return this.product.isProductType;
  }

  /**
   * Get vat rate value
   */
  public get vatRateValue(): number {
    const values: any = { // TODO move it somewhere
      23: 23,
      8: 8,
      5: 5,
      0: 0,
      zw: 0,
    };

    return values[this.vatRate];
  }

  /**
   * Get prices formatted
   */
  public get pricesWithCurrencyFormatted(): AmountsFormattedInterface {
    return {
      net: Locale.price(this.priceNet),
      vat: Locale.price(this.priceVat),
      gross: Locale.price(this.priceGross),
    };
  }

  /**
   * Get totals formatted
   */
  public get totalsWithCurrencyFormatted(): AmountsFormattedInterface {
    return {
      net: Locale.price(this.totalNet),
      vat: Locale.price(this.totalVat),
      gross: Locale.price(this.totalGross),
    };
  }

  /**
   * Calculate total net
   * @returns {string}
   */
  public get dynamicTotalNet(): number {
    let totalNet;

    if (this.hasGrossPrices) {
      totalNet = calculateNetPrice(this.dynamicTotalGross, this.vatRateValue);
    } else {
      totalNet = this.priceNet * (this.quantity || 0);
    }

    return formatPrice(totalNet);
  }

  /**
   * Calculate total vat
   * @returns {string}
   */
  public get dynamicTotalVat(): number {
    return formatPrice(this.dynamicTotalGross - this.dynamicTotalNet);
  }

  /**
   * Calculate total gross
   * @returns {string}
   */
  public get dynamicTotalGross(): number {
    let totalGross;

    if (this.hasGrossPrices) {
      totalGross = this.priceGross * (this.quantity || 0);
    } else {
      totalGross = calculateGrossPrice(this.dynamicTotalNet, this.vatRateValue);
    }

    return formatPrice(totalGross);
  }

  /**
   * Get dynamic totals formatted
   */
  public get dynamicTotalsFormatted(): AmountsFormattedInterface {
    return {
      net: Locale.price(this.dynamicTotalNet),
      vat: Locale.price(this.dynamicTotalVat),
      gross: Locale.price(this.dynamicTotalGross),
    };
  }

  /**
   * Get vat rate with percent
   */
  public get vatRateWithPercent(): string | null {
    if (!this.vatRate) {
      return null;
    }

    return this.vatRate + '%';
  }

  /**
   * Set net price
   * @param price
   */
  public setNetPrice(price: number) {
    this.priceNet = price;

    this.priceGross = formatPrice(calculateGrossPrice(price, this.vatRateValue));
    this.priceVat = formatPrice(calculateVatPrice(this.priceGross, this.priceNet));
  }

  /**
   * Set gross price
   * @param price
   */
  public setGrossPrice(price: number) {
    this.priceGross = price;

    this.priceNet = formatPrice(calculateNetPrice(price, this.vatRateValue));
    this.priceVat = formatPrice(calculateVatPrice(this.priceGross, this.priceNet));
  }

  /**
   * Set vat rate
   * @param vatRate
   */
  public setVatRate(vatRate: number | string) {
    this.vatRate = vatRate;

    if (this.hasGrossPrices) {
      this.priceNet = formatPrice(calculateNetPrice(this.priceGross, this.vatRateValue));
    } else {
      this.priceGross = formatPrice(calculateGrossPrice(this.priceNet, this.vatRateValue));
    }
  }
}
