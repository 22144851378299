






























import { Vue, Component, Prop } from 'vue-property-decorator';
import ProductCoreModel from '@/modules/products/models/ProductCoreModel';
import UISettings from '@/app/lib/settings/UISettings';
import Button from '@/shared/resources/components/buttons/Button.vue';
import FileCoreModel from '@/modules/files/models/FileCoreModel';
import Carousel from '@/shared/resources/components/carousel/Carousel.vue';
import CarouselItem from '@/shared/resources/components/carousel/CarouselItem.vue';
import ImgViewer from '@/shared/resources/components/ImgViewer.vue';
import Card from '@/shared/resources/components/cards/Card.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import Sheet from '@/shared/resources/components/Sheet.vue';

@Component({
  components: {
    Sheet,
    GridRow,
    Card,
    ImgViewer,
    CarouselItem,
    Carousel,
    Button,
  },
})
export default class ProductPhotosCarousel extends Vue {
  /**
   * Props
   */
  @Prop() private product!: ProductCoreModel;
  @Prop() private photos!: FileCoreModel[];

  /**
   * Getters
   */
  private get showArrows(): boolean {
    return this.photos.length > 1;
  }

  private get showArrowsOnHover(): boolean {
    return this.$vuetify.breakpoint.lgAndUp;
  }

  private get hideDelimiters(): boolean {
    return this.photos.length <= 1;
  }
}
