import ApiORMQueryBuilder from '@/core/bridge/orm/api/ApiORMQueryBuilder';
import { ApiRequestConfigContract } from '@/core/api/support/ApiRequestConfigContract';

export default class ApiORMQueryBuilderExtended extends ApiORMQueryBuilder {
  /**
   * User pin
   */
  protected userPin: string | null = null;

  /**
   * Get request data
   */
  public getData() {
    if (this.userPin && this.store) {
      return {
        data: this.store,
        pin: this.userPin,
      };
    } else if (this.userPin && !this.store) {
      return {
        pin: this.userPin,
      };
    }

    return this.store;
  }

  /**
   * Count models
   */
  public async count(): Promise<number> {
    const config: ApiRequestConfigContract = this.getActionConfig('count');

    this.prepareAxiosResponse(this.model.api().axios, config);

    this.withoutSaving();

    const result = await this.model.api().request({
      ...this.extendedAxiosRequestConfig,
      data: this.getData(),
      url: this.prepareRequestUrl(config.url),
      method: config.method,
    });

    if (!result) {
      return 0;
    }

    return Number(result.response.data);
  }

  /**
   * Get model by param
   * @deprecated since get() method got correct param search ( last occurrence in url definition )
   */
  // public async getByParam(paramName: string, paramValue: any) {
  //   await this.prepareAxiosResponse();
  //   this.model.methodConf.http.axios = this.axiosInstance;
  //
  //   if (this.checkStoreData) {
  //     const fromStore = this.model
  //       .query()
  //       .where(paramName, paramValue)
  //       .first();
  //
  //     if (fromStore) {
  //       return fromStore;
  //     }
  //   }
  //
  //   await this.model.$get({ params: { [paramName]: paramValue } });
  //   return this.model
  //     .query()
  //     .where(paramName, paramValue)
  //     .first();
  // }

  /**
   * Set search string
   * @param searchString
   * @param fieldName
   */
  public search(searchString: string | null, fieldName: string = 'searchString') {
    this.conditions = {
      ...this.conditions,
      [fieldName]: searchString,
    };
    return this;
  }

  /**
   * Refresh axios model
   */
  public refreshAxiosInstance() {
    // TODO
    // this.axiosInstance.defaults.headers.common = {
    //   ...this.axiosInstance.defaults.headers.common,
    //   ...ApiHeaders.get(ApiHeadersGroupEnum.DATA),
    // };
    return this;
  }

  /**
   * Set user pin
   * @param pin
   */
  public pin(pin: string) {
    this.userPin = pin;
    return this;
  }
}
