import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';

export default class ProductFileCoreModel extends ORMModelExtended {
  /**
   * ORM entity
   */
  public static entity = 'product-files';

  /**
   * Primary key
   */
  public static primaryKey = ['product_id', 'file_id'];

  /**
   * ORM fields
   */
  public static fields() {
    return {
      product_id: this.attr(null),
      file_id: this.attr(null),
    };
  }
}
