import { PaymentsTypesEnum } from '@/modules/payments/enums/PaymentsTypesEnum';
import { DeliveriesEnum } from '@/modules/deliveries/enums/DeliveriesEnum';
import { VAT_RATE_23 } from '@/shared/lib/config/vatConfig';
import { DeliveryInterface } from '@/modules/deliveries/interfaces/DeliveriesInterface';

/**
 * Payments types
 */
const deliveries: DeliveryInterface[] = [
  // COURIER_DPD
  {
    key: DeliveriesEnum.COURIER_DPD,
    vatRate: VAT_RATE_23,
    requireAddress: true,
    productId: 9119,
    options: [
      {
        paymentType: PaymentsTypesEnum.PAYMENT_TYPE_IN_ADVANCE,
        price: 17.99,
        discounts: [
          {
            minValue: 500,
            price: 15.99,
          },
          {
            minValue: 1000.00,
            price: 0.00,
          },
        ],
      },
      {
        paymentType: PaymentsTypesEnum.PAYMENT_TYPE_ON_DELIVERY,
        price: 22.99,
        discounts: [
          {
            minValue: 500,
            price: 20.99,
          },
          {
            minValue: 1000.00,
            price: 0.00,
          },
        ],
      },
    ],
  },

  // COURIER_INPOST
  {
    key: DeliveriesEnum.COURIER_INPOST,
    vatRate: VAT_RATE_23,
    requireAddress: true,
    productId: 9119,
    options: [
      {
        paymentType: PaymentsTypesEnum.PAYMENT_TYPE_IN_ADVANCE,
        price: 16.99,
        discounts: [
          {
            minValue: 500,
            price: 11.99,
          },
          {
            minValue: 1000.00,
            price: 0.00,
          },
        ],
      },
      {
        paymentType: PaymentsTypesEnum.PAYMENT_TYPE_ON_DELIVERY,
        price: 20.99,
        discounts: [
          {
            minValue: 500,
            price: 18.99,
          },
          {
            minValue: 1000.00,
            price: 0.00,
          },
        ],
      },
    ],
  },

  // PERSONAL
  {
    key: DeliveriesEnum.PERSONAL,
    vatRate: VAT_RATE_23,
    requireAddress: false,
    options: [
      {
        paymentType: PaymentsTypesEnum.PAYMENT_TYPE_IN_ADVANCE,
        price: 0.00,
      },
      {
        paymentType: PaymentsTypesEnum.PAYMENT_TYPE_ON_DELIVERY,
        price: 0.00,
      },
    ],
  },
];


/**
 * Export
 */
export {
  deliveries,
};
