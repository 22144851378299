import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';

export default class TagCoreModel extends ORMModelExtended {
  /**
   * ORM entity
   */
  public static entity = 'tags';

  /**
   * API method conf
   */
  public static apiConfig = {
    actions: {
      fetch: {
        method: 'GET',
        url: '/tags',
      },
    },
  };

  /**
   * ORM fields
   */
  public static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
    };
  }

  /**
   * Public fields
   */
  public id!: number;
  public name!: string;

  /**
   * Get name in lower case
   */
  public get lowerCasedName(): string {
    return this.name.toLowerCase();
  }
}
