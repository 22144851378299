import {
  ApiHeadersContract,
  ApiHeaderContract,
  ApiHeaderArrayContract,
} from '@/core/api/headers/contracts/ApiHeaderContract';
import {isArray} from 'lodash';

export default class ApiHeaders {
  /**
   * @info    Api headers collector
   *
   * @author  Lukas Laskowski 15.07.2019
   */

  /**
   * Add header by bag
   * @param name
   * @param value
   */
  public static set(section: string | string[], name: string | string[], value: any) {
    if (isArray(section)) {
      section.forEach((s: string) => {
        this.insert(s, name, value);
      });
    } else {
      return this.insert(section, name, value);
    }
  }

  /**
   * Get all collected headers from section
   */
  public static get(section: string): ApiHeaderContract {
    return this.headers[section] ? this.headers[section] : {};
  }

  /**
   * Get all collected headers from section as Array
   */
  public static getAsArray(section: string): ApiHeaderArrayContract[] {
    const headers: ApiHeaderContract = this.get(section);
    const list: ApiHeaderArrayContract[] = [];
    Object.keys(headers).forEach((name: string) => {
      list.push({
        name,
        value: headers[name],
      });
    });
    return list;
  }

  /**
   * headers storage
   */
  private static headers: ApiHeadersContract = {};

  /**
   * Insert header to the bag
   * @param section
   * @param name
   * @param value
   */
  private static insert(section: string, name: string | string[], value: any) {
    if (!this.headers[section]) {
      this.register(section);
    }
    if (isArray(name)) {
      name.forEach((k: string) => {
        this.headers[section][k] = value;
      });
    } else {
      this.headers[section][name] = value;
    }
    return this;
  }

  /**
   * Register new bag for headers
   * @param name
   */
  private static register(name: string) {
    this.headers[name] = {};
  }
}
