import SettingsStorage from '@/core/settings/SettingsStorage';
import AppSettingsEnum from '@/app/lib/settings/AppSettingsEnum';
import SettingsStorageRegistry from '@/core/settings/SettingsStorageRegistry';
import BrowserLocalSettingsStorageAdapter from '@/core/settings/adapters/BrowserLocalSettingsStorageAdapter';
import { PricesEnum } from '@/modules/prices/enums/PricesEnum';
import ContractorCoreModel from '@/modules/contractors/models/ContractorCoreModel';
import { isGrossPrice } from '@/modules/prices/pricesUtils';
import PricesTypesEnum from '@/modules/prices/enums/PricesTypesEnum';
import ApiSettings from '@/core/api/settings/ApiSettings';
import UserCoreModel from '@/modules/users/models/UserCoreModel';

export default class AppSettings {
  public static get user(): UserCoreModel | null {
    if (!ApiSettings.userId) {
      return null;
    }

    return UserCoreModel
      .query()
      .where('id', ApiSettings.userId)
      .with('contractor')
      .first();
  }

  /**
   * Get contractor
   */
  public static get contractor(): ContractorCoreModel | null {
    if (!this.contractorId) {
      return null;
    }

    return ContractorCoreModel.find(this.contractorId);
  }

  /**
   * Handle contractor id
   */
  public static set contractorId(contractorId: number | null) {
    if (contractorId) {
      this.storage.setParam(AppSettingsEnum.PARAM_CONTRACTOR_ID, contractorId);
    } else {
      this.storage.removeParam(AppSettingsEnum.PARAM_CONTRACTOR_ID);
    }
  }

  public static get contractorId(): number | null {
    return this.storage.getParam(AppSettingsEnum.PARAM_CONTRACTOR_ID);
  }

  /**
   * Get user contractor price
   */
  public static get contractorPrices(): PricesEnum {
    return this.user?.contractor?.defaultPrice || PricesEnum.ONLINE;
  }

  /**
   * Get user contractor prices type
   */
  public static get contractorPricesType(): PricesTypesEnum {
    return isGrossPrice(this.contractorPrices)
      ? PricesTypesEnum.GROSS
      : PricesTypesEnum.NET;
  }

  /**
   * Set storage
   */
  public static readonly storage: SettingsStorage = SettingsStorageRegistry.register(
    new BrowserLocalSettingsStorageAdapter('APP'),
  );

  /**
   * Rested data
   */
  public static resetData() {
    this.contractorId = null;
  }
}
