import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import ApiSchema from './schema/ApiSchema';
import ApiORMParamsContract from '@/core/bridge/orm/api/contracts/ApiORMParamsContract';
import ApiSettings from '@/core/api/settings/ApiSettings';
import ApiError from '@/core/api/errors/ApiError';
import { isArray, has, map, isString, isObject } from 'lodash';
import IndexableInterface from '@/core/interfaces/IndexableInterface';
import Pagination from '@/core/support/pagination/Pagination';
import { PaginationSortInterface } from '@/core/support/pagination/PaginationInterface';
import { ApiRequestConfigContract } from '@/core/api/support/ApiRequestConfigContract';

export default abstract class ApiQueryBuilder {
  /**
   * Protected fields
   */
  protected store: ApiORMParamsContract = {};
  protected conditions: ApiORMParamsContract = {};
  protected params: ApiORMParamsContract = {};
  protected activeSchema: ApiSchema;
  protected activePagination!: Pagination;
  protected debugParam?: string;
  protected axiosRequestConfig!: AxiosRequestConfig;

  /**
   * Constructor
   */
  constructor() {
    this.activeSchema = ApiSettings.schema;
    this.activePagination = new Pagination();

    this.prepareDefaultAxiosConfig();
  }

  /**
   * Set axios header
   * @param key
   * @param value
   * @param common (usable with multiple domains)
   */
  public header(key: string, value: any, common: boolean = false) {
    if (common) {
      this.axiosRequestConfig.headers.common[key] = value;
    } else {
      this.axiosRequestConfig.headers[key] = value;
    }
    return this;
  }

  /**
   * Remove header from headers bag
   * @param key
   */
  public removeHeader(key: string) {
    if (this.axiosRequestConfig.headers.common[key]) {
      delete this.axiosRequestConfig.headers.common[key];
    }
    if (this.axiosRequestConfig.headers[key]) {
      delete this.axiosRequestConfig.headers[key];
    }
    return this;
  }

  /**
   * Load additional headers for request
   * @param headers
   * @param common
   */
  public headers(headers: IndexableInterface, common: boolean = false) {
    Object.keys(headers).forEach((name: string) => {
      this.header(name, headers[name], common);
    });
    return this;
  }

  /**
   * Return all headers
   */
  public getHeaders(): IndexableInterface {
    return this.axiosRequestConfig.headers;
  }

  /**
   * Define debug param name for request identify help
   * @param param
   */
  public debug(param: string) {
    this.debugParam = param;
    return this;
  }

  /**
   * Set data to save methods
   * @param data
   * @param value
   */
  public data(data: object | null | string, value?: any) {
    if (value && isString(data)) {
      this.store = {
        ...this.store,
        [data]: value,
      };
    } else if (isObject(data) && !isArray(data) && !(data instanceof FormData)) {
      this.store = {
        ...this.store,
        ...data,
      };
    } else if (data !== null && !isString(data)) {
      this.store = data;
    }
    return this;
  }

  /**
   * Return data
   */
  public getData() {
    return this.store;
  }

  /**
   * Define url params
   * @param param
   * @param val
   */
  public param(param: string | object, val: string | number | null = null) {
    if (typeof param === 'object') {
      this.params = {
        ...this.params,
        ...param,
      };
    } else {
      this.params = {
        ...this.params,
        [param]: val,
      };
    }
    return this;
  }

  /**
   * Return url params
   */
  public getParams(): ApiORMParamsContract {
    return this.params;
  }

  /**
   * Return url param by name
   * @param param
   */
  public getParam(param: string): any {
    if (this.hasParams && !!this.params[param]) {
      return this.params[param];
    }
  }

  /**
   * Check if has any url params
   */
  public get hasParams(): boolean {
    return !!this.params && !!Object.values(this.params).length;
  }

  /**
   * Return conditions
   */
  public getConditions() {
    return this.conditions;
  }

  /**
   * Return condition by param
   * @param param
   */
  public getCondition(param: string): any {
    if (this.isCondition(param)) {
      return this.conditions[param];
    }
    return null;
  }

  /**
   * Check if condition exists
   * @param param
   */
  public isCondition(param: string): boolean {
    return !!this.conditions && !!this.conditions[param];
  }

  /**
   * Remove condition by param
   * @param param
   */
  public removeCondition(param: string) {
    if (this.isCondition(param)) {
      delete this.conditions[param];
    }
    return this;
  }

  /**
   * Set query params
   * @param param
   * @param val
   */
  public where(param: string | object, val: string | any[] | number | boolean | null = null) {
    if (val === null && isString(param)) {
      return this;
    }

    if (isString(param)) {
      this.conditions[param] = val;
    } else if (isObject(param)) {
      this.conditions = {
        ...this.conditions,
        ...param,
      };
    }

    return this;
  }

  /**
   * Set response schema
   * @param schema
   */
  public schema(schema: ApiSchema) {
    this.activeSchema = schema;
    this.prepareDefaultAxiosConfig();
    return this;
  }

  /**
   * Get schema
   */
  public getSchema() {
    return this.activeSchema;
  }

  /**
   * Set base url
   * @param url
   */
  public baseUrl(url: string) {
    this.axiosRequestConfig.baseURL = url;
    return this;
  }

  /**
   * Set pagination page
   * @param page
   */
  public page(page: number | null = null) {
    if (page !== null) {
      this.activePagination.setPage(page);
    }
    return this;
  }

  /**
   * Set pagination size
   * @param limit
   */
  public limit(limit: number | null = null) {
    if (limit !== null) {
      this.activePagination.setLimit(limit);
    }
    return this;
  }

  /**
   * Set pagination sort
   * @param sort
   */
  public sort(sort: PaginationSortInterface[] | PaginationSortInterface | null = null) {
    if (sort !== null) {
      if (isArray(sort)) {
        this.activePagination.setSortArray(sort);
      } else {
        this.activePagination.setSort(sort.field, sort.desc);
      }
    }
    return this;
  }

  /**
   * Set pagination
   * @param pagination
   */
  public pagination(pagination: Pagination) {
    this.activePagination = pagination;
    return this;
  }

  /**
   * Get pagination
   */
  public getPagination(): Pagination {
    return this.activePagination;
  }

  /**
   * Override default timeout
   * @param value
   */
  public timeout(value: number) {
    this.axiosRequestConfig.timeout = value;
    return this;
  }

  /**
   * Define axios cancel token
   * @param token
   */
  public cancelToken(token: CancelToken) {
    this.axiosRequestConfig.cancelToken = token;
    return this;
  }

  /**
   * Return last url param
   */
  protected lastParamValue(): string | number | null {
    if (!this.hasParams) {
      return null;
    }
    const values: Array<string | number> = Object.values(this.params);
    return values[values.length - 1];
  }

  /**
   * Prepare default axios config
   */
  protected prepareDefaultAxiosConfig() {
    this.axiosRequestConfig = this.getSchema().prepareRequestHeaders();
  }

  /**
   * Prepare axios response
   * @param axiosInstance
   * @param requestData
   * @protected
   */
  protected prepareAxiosResponse(axiosInstance: AxiosInstance, requestData?: ApiRequestConfigContract) {
    // reset all existing axios interceptors
    (axiosInstance.interceptors.response as any).handlers = [];

    axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        return this.getSchema().parseResponse(response, requestData);
      },
      (error: AxiosError) => {
        throw new ApiError(this.getSchema().parseErrorResponse(error, requestData));
      },
    );
  }

  /**
   * Prepare request final url from collected data
   * @param url
   * @param additionalQueryParams
   * @private
   */
  protected prepareRequestUrl(url: string, additionalQueryParams: IndexableInterface = {}): string {
    const queryParams: IndexableInterface = {
      ...this.getSchema().prepareQueryPagination(this.getPagination()),
      ...this.getSchema().prepareQueryParams(this.getConditions()),
      ...additionalQueryParams,
    };

    if (this.debugParam) {
      queryParams.debug = this.debugParam;
    }

    return this.prepareUrl(url, {
      params: this.getParams(),
      query: queryParams,
    });
  }

  /**
   * Return full url with injected params and query params
   * @param url
   * @param config
   */
  protected prepareUrl(url: string, config: IndexableInterface = {}) {
    const params = map(url.match(/(\/?)(:)([A-z]*)/gm), (param) => param.replace('/', ''));

    params.forEach((param: string) => {
      const paramValue = has(config.params, param.replace(':', ''))
        ? config.params[param.replace(':', '')] : '';

      // eslint-disable-next-line no-param-reassign
      url = url.replace(param, paramValue).replace('//', '/');
    });

    if (config.query) {
      const mergedQueryParams: string = this.getSchema().prepareRequestUrlParams(config.query);
      if (mergedQueryParams.length) {
        // eslint-disable-next-line no-param-reassign
        url += `?${mergedQueryParams}`;
      }
    }
    return url;
  }
}
