import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export interface IRootState {
  [key: string]: string;
}

const store = new Vuex.Store<any>({});

export { store };
