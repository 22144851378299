import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';
import { ApiORMModelRelationsFieldsContract } from '@/core/bridge/orm/api/relations/contracts/ApiORMModelRelationsFieldsContract';
import { ApiRelationModelsEnum } from '@/shared/lib/api/relations/ApiRelationModelsEnum';
import CategoryTypesEnum from '@/modules/categories/enums/CategoryTypesEnum';

export default class CategoryCoreModel extends ORMModelExtended {
  /**
   * ORM entity
   */
  public static entity = 'categories';

  /**
   * API method conf
   */
  public static apiConfig = {
    actions: {
      fetch: {
        method: 'GET',
        url: '/categories',
      },
      get: {
        method: 'GET',
        url: '/categories/:id',
      },
      count: {
        method: 'GET',
        url: '/categories/count',
      },
    },
  };

  /**
   * Relation fields
   */
  public static relationFields: ApiORMModelRelationsFieldsContract = {
    [ApiRelationModelsEnum.CATEGORY]: 'children',
    [ApiRelationModelsEnum.CATEGORY]: 'parent',
  };

  /**
   * ORM fields
   */
  public static fields() {
    return {
      id: this.number(0),

      name: this.string(''),
      shortName: this.string(null).nullable(),
      type: this.string(null),

      redirectCategoryId: this.number(null).nullable(),

      parentId: this.number(null).nullable(),
      parent: this.belongsTo(CategoryCoreModel, 'parentId'),

      hasChildren: this.boolean(false),
      children: this.hasMany(CategoryCoreModel, 'parentId'),
    };
  }

  /**
   * Public fields
   */
  public id!: number;
  public name!: string;
  public shortName!: string | null;
  public type!: CategoryTypesEnum;

  public redirectCategoryId!: number | null;

  public parentId!: number | null;
  public parent!: CategoryCoreModel | null;

  public hasChildren!: boolean;
  public children!: CategoryCoreModel[];

  /**
   * Check if category has parent.
   */
  public get hasParent(): boolean {
    return !!this.parentId;
  }

  /**
   * Check if category is products type
   */
  public get isProductsType() {
    return this.isType(CategoryTypesEnum.PRODUCTS);
  }

  /**
   * Check if category is occasion type
   */
  public get isOccasionType() {
    return this.isType(CategoryTypesEnum.OCCASION);
  }

  /**
   * Check if category is redirect type
   */
  public get isRedirectType() {
    return this.isType(CategoryTypesEnum.REDIRECT);
  }

  /**
   * Check if category is given type
   * @param type
   */
  private isType(type: CategoryTypesEnum) {
    return this.type === type;
  }
}
