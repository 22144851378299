enum ApiSettingsEnum {
  PARAM_TOKEN = 'TOKEN',
  PARAM_TOKEN_V1 = 'TOKEN_V1',
  PARAM_STORAGE_URL = 'STORAGE_URL',
  PARAM_APP_URL = 'APP_URL',
  PARAM_API_URL = 'API_URL',
  PARAM_API_V1_URL = 'API_V1_URL',
  PARAM_SCHEMA = 'SCHEMA',
  PARAM_USER_ID = 'USER_ID',
}

export default ApiSettingsEnum;
