









import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class NavigationHeader extends Vue {
  /**
   * Props
   */
  @Prop() private item!: any;
  @Prop({ default: false, type: Boolean }) private minified!: boolean;

  /**
   * Getters
   */
  private get display(): boolean {
    return !this.minified;
  }
}
