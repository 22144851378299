















import { Vue, Component, Prop } from 'vue-property-decorator';

import TagCoreModel from '@/modules/tags/models/TagCoreModel';

@Component
export default class TagsCloud extends Vue {
  /**
   * Props
   */
  @Prop() private tags!: TagCoreModel[];
}
