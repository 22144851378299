const DEFAULT_SCROLL_DURATION: number = 1000;
const MOBILE_BREAKPOINT: number = 768;

/**
 * Scroll to top
 */
const scrollToTop = () => {
  scrollTo(DEFAULT_SCROLL_DURATION);
};

/**
 * Scroll to given element
 * @param element
 */
const scrollToElement = (element: HTMLElement) => {
  scrollTo(DEFAULT_SCROLL_DURATION, element);
};

/**
 * Scroll to
 * @param scrollDuration
 * @param elementToScroll
 * @private
 */
const scrollTo = (scrollDuration: number, elementToScroll: HTMLElement | null = null)  => {
  const isMobile: boolean = window.outerWidth <= MOBILE_BREAKPOINT;

  const navBarHeight: number = isMobile ? 0 : document.getElementById('mainHeader')?.offsetHeight || 0;

  let beforeElementHeight: number = 0;

  // find :before element height
  if (elementToScroll) {
    const beforeElementTmpHeight: string
      = window.getComputedStyle(elementToScroll, ':before').getPropertyValue('height');

    beforeElementHeight = beforeElementTmpHeight !== 'auto' ? parseInt(beforeElementTmpHeight, 0) : 0;
  }

  const targetY: number = elementToScroll
    ? Math.floor(elementToScroll.offsetTop - navBarHeight) + beforeElementHeight
    : 0;

  const directionTop: boolean = targetY <= window.scrollY;
  const directionBottom: boolean = targetY > window.scrollY;

  const scrollStep: number = Math.PI / (scrollDuration / 15);
  const cosParameter: number = Math.abs(targetY - window.scrollY) / 2;

  let scrollCount: number = 0;
  let scrollMargin: number;

  const scrollInterval = setInterval(() => {
    if ((directionBottom && window.scrollY < targetY) || (directionTop && window.scrollY > targetY)) {
      scrollCount++;
      scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);

      let toScroll: number = directionBottom
        ? window.scrollY + scrollMargin
        : window.scrollY - scrollMargin;

      toScroll = directionBottom
        ? (toScroll > targetY) ? targetY : toScroll
        : (toScroll < targetY) ? targetY : toScroll;

      window.scrollTo(0, toScroll);
    } else {
      clearInterval(scrollInterval);
    }
  }, 15);
};

/**
 * Export
 */
export {
  scrollToTop,
  scrollToElement,
};
