import queryString from 'query-string';

import { ProductTypesEnum } from '@/modules/products/enums/ProductTypesEnum';
import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';
import { ApiORMModelRelationsFieldsContract } from '@/core/bridge/orm/api/relations/contracts/ApiORMModelRelationsFieldsContract';
import { ApiRelationModelsEnum } from '@/shared/lib/api/relations/ApiRelationModelsEnum';
import FileCoreModel from '@/modules/files/models/FileCoreModel';
import ProductFileCoreModel from '@/modules/products/models/ProductFileCoreModel';
import { ProductTemplatePositionsEnum } from '@/modules/products/enums/ProductTemplatePositionsEnum';
import TagCoreModel from '@/modules/tags/models/TagCoreModel';
import ProductTagCoreModel from '@/modules/products/models/ProductTagCoreModel';
import CategoryCoreModel from '@/modules/categories/models/CategoryCoreModel';
import Cart from '@/app/lib/cart/Cart';
import CartItemCoreModel from '@/modules/carts/models/CartItemCoreModel';

export default class ProductCoreModel extends ORMModelExtended {
  /**
   * ORM entity
   */
  public static entity = 'products';

  /**
   * API method conf
   */
  public static apiConfig = {
    actions: {
      fetch: {
        method: 'GET',
        url: '/products',
      },
      get: {
        method: 'GET',
        url: '/products/:id',
      },
      count: {
        method: 'GET',
        url: '/products/count',
      },
    },
  };

  /**
   * Relation fields
   */
  public static relationFields: ApiORMModelRelationsFieldsContract = {
    [ApiRelationModelsEnum.PRODUCT_CATEGORY]: 'category',
    [ApiRelationModelsEnum.FILES]: 'files',
    [ApiRelationModelsEnum.TAGS]: 'tags',
  };

  /**
   * Relation required fields
   */
  public static relationRequiredFields: string[] = ['type'];

  /**
   * ORM fields
   * TODO add rest of fields
   */
  public static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
      type: this.string(''),

      unit: this.string(''),

      code: this.string(''),
      barCode: this.string(''),

      amountInPack: this.number(null),
      amountInPackUnit: this.string(''),

      onOrder: this.boolean(false),
      onOrderDays: this.number(null),

      thumbPath: this.string(null).nullable(),

      categoryId: this.number(0),
      category: this.belongsTo(CategoryCoreModel, 'categoryId'),

      files: this.belongsToMany(FileCoreModel, ProductFileCoreModel, 'product_id', 'file_id'),
      tags: this.belongsToMany(TagCoreModel, ProductTagCoreModel, 'product_id', 'attribute_id'),

      priceA: this.number(null),
      priceB: this.number(null),
      priceC: this.number(null),
      priceD: this.number(null),

      vatRate: this.number(null),
      amount: this.number(null),

      description: this.string(null).nullable(),

      templateId: this.number(null).nullable(),
      templatePosition: this.string(null).nullable(),
    };
  }

  /**
   * Public fields
   */
  public id!: number;

  public type!: ProductTypesEnum;

  public categoryId!: number | null;
  public category!: CategoryCoreModel | null;

  public name!: string;
  public nameOnline!: string | null;

  public amountInPack!: number;
  public amountInPackUnit!: string; // TODO enum

  public code!: string;
  public barCode!: string;
  public unit!: string; // TODO enum

  public amount!: number;

  public onOrder!: boolean;
  public onOrderDays!: number | null;

  public priceA!: number;
  public priceB!: number;
  public priceC!: number;
  public priceD!: number;
  public priceOnline!: number;
  public pricePurchase!: number;
  public vatRate!: number;

  public canDiscount!: boolean;

  public sizesTemplateId!: number | null;
  public width!: number | null;
  public height!: number | null;
  public length!: number | null;
  public weight!: number | null;
  public packageTemplateId!: number | null;
  public packageWidth!: number | null;
  public packageHeight!: number | null;
  public packageLength!: number | null;
  public packageWeight!: number | null;
  public packageFragile!: boolean | null;

  public manufacturerId!: number | null;

  public templateId!: number | null;
  public templatePosition!: ProductTemplatePositionsEnum | null;
  public description!: string | null;

  public thumbPath!: string | null;

  public files!: FileCoreModel[];
  public tags!: TagCoreModel[];

  public metaTitle!: string | null;
  public metaDescription!: string | null;

  public deletedAt!: string | null;
  public createdAt!: string | null;
  public updatedAt!: string | null;

  [key: string]: any;

  /**
   * Check if is stockable
   */
  public get isStockable(): boolean {
    return this.isProductType && !this.onOrder;
  }

  /**
   * Get product cart item
   */
  public get cartItem(): CartItemCoreModel | null {
    return Cart.getItemByProductId(this.id);
  }

  /**
   * Get max cart item amount
   * @private
   */
  public get maxCartItemAmount(): number | null {
    if (this.onOrder) {
      return null;
    }

    return this.cartItem ? this.cartItem.maxQuantity : this.amount;
  }

  /**
   * Get product thumb image
   * @param size
   * @param fit
   */
  public getThumb(size: number = 25, fit: boolean = false) {
    if (!this.thumbPath) {
      return `//placehold.it/${size}`;
    }

    const params: string = queryString.stringify({
      w: size,
      h: size,
      fit: fit ? 'crop' : null,
    });

    return `${this.thumbPath}?${params}`;
  }

  /**
   * Check if product is available
   */
  public get isAvailable() {
    if (this.onOrder) {
      return true;
    }

    return this.amount > 0;
  }

  /**
   * Check if product is on order
   */
  public get isOnOrder(): boolean {
    return this.onOrder;
  }

  /**
   * Check if is product type
   */
  public get isProductType() {
    return this.isType(ProductTypesEnum.PRODUCT);
  }

  /**
   * Check if is product type
   */
  public get isServicesType() {
    return this.isType(ProductTypesEnum.SERVICE);
  }

  /**
   * Check if is product type
   */
  public get isRentingType() {
    return this.isType(ProductTypesEnum.RENTING);
  }

  /**
   * Get photos
   */
  public get photos(): FileCoreModel[] {
    return this.files.filter((file: FileCoreModel) => file.isImage);
  }

  /**
   * Check if has description
   */
  public get hasDescription(): boolean {
    return !!this.description; // TODO check if has template
  }

  /**
   * Check if template position is on top
   */
  public get isTemplatePositionOnTop(): boolean {
    return this.isTemplatePosition(ProductTemplatePositionsEnum.TOP);
  }

  /**
   * Check if template position is on bottom
   */
  public get isTemplatePositionOnBottom(): boolean {
    return this.isTemplatePosition(ProductTemplatePositionsEnum.BOTTOM);
  }

  /**
   * Check if product is given type
   * @param type
   */
  private isType(type: ProductTypesEnum) {
    return this.type === type;
  }

  /**
   * Check template position
   * @param position
   * @private
   */
  private isTemplatePosition(position: ProductTemplatePositionsEnum): boolean {
    return this.templatePosition === position;
  }
}
