import SearchObjectInterface from '@/shared/lib/interfaces/SearchObjectInterface';

export default class Search {
  /**
   * Private fields
   */
  private query: string | null = null;
  private field: string | null = null;

  /**
   * Constructor
   * @param query
   * @param field
   */
  constructor(
    query: string | null = null,
    field: string | null = null,
  ) {
    this.query = query;
    this.field = field;
  }

  /**
   * Get query string
   */
  public get queryString(): string | null {
    return this.query;
  }

  /**
   * Set query string
   */
  public set queryString(query: string | null) {
    this.query = query;
  }

  /**
   * Get query field
   */
  public get queryField(): string | null {
    return this.field;
  }

  /**
   * Set query field
   */
  public set queryField(field: string | null) {
    this.field = field;
  }

  /**
   * Check if query is empty
   */
  public get empty(): boolean {
    return !this.query;
  }

  /**
   * Get request data
   */
  public getRequestData() {
    const params: { [key: string]: any } = {};
    params.search = this.query;

    if (this.field !== null) {
      params.searchField = this.field;
    }
    return params;
  }

  /**
   * Get object data
   */
  public toObject(): SearchObjectInterface {
    return {
      query: this.query,
      field: this.field,
    };
  }

  /**
   * Sync params
   * @param query
   * @param field
   */
  public sync(query: string | null, field: string | null) {
    this.query = query;
    this.field = field;
  }
}
