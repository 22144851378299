import ApiSchema from '@/core/api/schema/ApiSchema';
import ApiHeaders from '@/core/api/headers/ApiHeaders';
import SettingsStorage from '@/core/settings/SettingsStorage';
import SettingsStorageRegistry from '@/core/settings/SettingsStorageRegistry';
import ApiHeadersGroupEnum from '@/core/api/headers/support/ApiHeadersGroupEnum';
import ApiSettingsEnum from '@/core/api/support/ApiSettingsEnum';
import BrowserLocalSettingsStorageAdapter from '@/core/settings/adapters/BrowserLocalSettingsStorageAdapter';
import MemorySettingsStorageAdapter from '@/core/settings/adapters/MemorySettingsStorageAdapter';

export default class ApiSettings {
  /**
   * Storage
   */
  public static readonly memoryStorage: SettingsStorage = SettingsStorageRegistry.register(
    new MemorySettingsStorageAdapter('API'),
  );

  /**
   * Constant storage
   */
  public static readonly browserLocalStorage: SettingsStorage = SettingsStorageRegistry.register(
    new BrowserLocalSettingsStorageAdapter('API'),
  );

  /**
   * Set api URL
   * @param url
   */
  public static set apiURL(url: string) {
    this.memoryStorage.setParam(ApiSettingsEnum.PARAM_API_URL, url);
  }

  /**
   * Get api url
   */
  public static get apiURL(): string {
    return this.memoryStorage.getParam(ApiSettingsEnum.PARAM_API_URL);
  }

  /**
   * Set app URL
   * @param url
   */
  public static set appURL(url: string) {
    this.memoryStorage.setParam(ApiSettingsEnum.PARAM_APP_URL, url);
  }

  /**
   * Get app url
   */
  public static get appURL(): string {
    return this.memoryStorage.getParam(ApiSettingsEnum.PARAM_APP_URL);
  }

  /**
   * Handle token
   */
  public static set token(token: string) {
    this.browserLocalStorage.setParam(ApiSettingsEnum.PARAM_TOKEN, token);

    ApiHeaders.set([
      ApiHeadersGroupEnum.DATA,
      ApiHeadersGroupEnum.FILES,
    ], 'Authorization', token);
  }

  public static get token(): string {
    return this.browserLocalStorage.getParam(ApiSettingsEnum.PARAM_TOKEN);
  }

  public static get rawToken(): string {
    return this.token.replace('Bearer ', '');
  }

  /**
   * Handle user
   * @param userId
   */
  public static set userId(userId: string | null) {
    if (userId) {
      this.browserLocalStorage.setParam(ApiSettingsEnum.PARAM_USER_ID, userId);
    } else {
      this.browserLocalStorage.removeParam(ApiSettingsEnum.PARAM_USER_ID);
    }
  }

  public static get userId(): string | null {
    return this.browserLocalStorage.getParam(ApiSettingsEnum.PARAM_USER_ID);
  }

  /**
   * Load stored data
   */
  public static loadCachedData() {
    const token: string | undefined = this.browserLocalStorage.getParam(ApiSettingsEnum.PARAM_TOKEN);

    if (token) {
      this.token = token;
    }

    const userId: string | undefined = this.browserLocalStorage.getParam(ApiSettingsEnum.PARAM_USER_ID);

    if (userId) {
      this.userId = userId;
    }
  }

  /**
   * Clear prefix param
   */
  public static clearToken() {
    this.browserLocalStorage.removeParam(ApiSettingsEnum.PARAM_TOKEN);
  }

  /**
   * Set storage URL
   * @param url
   */
  public static set storageURL(url: string) {
    this.memoryStorage.setParam(ApiSettingsEnum.PARAM_STORAGE_URL, url);
  }

  /**
   * Get storage URL
   */
  public static get storageURL(): string {
    return this.memoryStorage.getParam(ApiSettingsEnum.PARAM_STORAGE_URL);
  }

  /**
   * Set schema
   * @param schema
   */
  public static set schema(schema: ApiSchema) {
    this.memoryStorage.setObject(ApiSettingsEnum.PARAM_SCHEMA, schema);
  }

  /**
   * Get schema
   */
  public static get schema(): ApiSchema {
    return this.memoryStorage.getObject(ApiSettingsEnum.PARAM_SCHEMA);
  }
}
