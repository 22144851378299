import ApiSchema from '@/core/api/schema/ApiSchema';
import ApiHeaders from '@/core/api/headers/ApiHeaders';
import SettingsStorage from '@/core/settings/SettingsStorage';
import SettingsStorageRegistry from '@/core/settings/SettingsStorageRegistry';
import ApiHeadersGroupEnum from '@/core/api/headers/support/ApiHeadersGroupEnum';
import ApiSettingsEnum from '@/core/api/support/ApiSettingsEnum';
import MemorySettingsStorageAdapter from '@/core/settings/adapters/MemorySettingsStorageAdapter';

export default class ApiV1Settings {
  /**
   * Memory Storage
   */
  public static readonly memoryStorage: SettingsStorage = SettingsStorageRegistry.register(
    new MemorySettingsStorageAdapter('API_V1'),
  );

  /**
   * Set api URL
   * @param url
   */
  public static set apiURL(url: string) {
    this.memoryStorage.setParam(ApiSettingsEnum.PARAM_API_URL, url);
  }

  /**
   * Get api url
   */
  public static get apiURL(): string {
    return this.memoryStorage.getParam(ApiSettingsEnum.PARAM_API_URL);
  }

  /**
   * Set app URL
   * @param url
   */
  public static set appURL(url: string) {
    this.memoryStorage.setParam(ApiSettingsEnum.PARAM_APP_URL, url);
  }

  /**
   * Get app url
   */
  public static get appURL(): string {
    return this.memoryStorage.getParam(ApiSettingsEnum.PARAM_APP_URL);
  }

  /**
   * Handle token
   */
  public static set token(token: string) {
    this.memoryStorage.setParam(ApiSettingsEnum.PARAM_TOKEN, token);

    ApiHeaders.set([
      ApiHeadersGroupEnum.DATA,
      ApiHeadersGroupEnum.FILES,
    ], 'Authorization', token);
  }

  public static get token(): string {
    return this.memoryStorage.getParam(ApiSettingsEnum.PARAM_TOKEN);
  }

  public static get rawToken(): string {
    return this.token.replace('Bearer ', '');
  }

  /**
   * Set storage URL
   * @param url
   */
  public static set storageURL(url: string) {
    this.memoryStorage.setParam(ApiSettingsEnum.PARAM_STORAGE_URL, url);
  }

  /**
   * Get storage URL
   */
  public static get storageURL(): string {
    return this.memoryStorage.getParam(ApiSettingsEnum.PARAM_STORAGE_URL);
  }

  /**
   * Set schema
   * @param schema
   */
  public static set schema(schema: ApiSchema) {
    this.memoryStorage.setObject(ApiSettingsEnum.PARAM_SCHEMA, schema);
  }

  /**
   * Get schema
   */
  public static get schema(): ApiSchema {
    return this.memoryStorage.getObject(ApiSettingsEnum.PARAM_SCHEMA);
  }
}
