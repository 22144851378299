import { PaymentsEnum } from '@/modules/payments/enums/PaymentsEnum';
import { PaymentInterface } from '@/modules/payments/interfaces/PaymentsInterface';

const payments: PaymentInterface[] = [
  // PAYU
  {
    key: PaymentsEnum.PAYU,
    selectable: true,
  },

  // PAYNOW
  {
    key: PaymentsEnum.PAYNOW,
    selectable: true,
  },

  // BANKWIRE
  {
    key: PaymentsEnum.BANKWIRE,
    selectable: true,
  },

  // ON_DELIVERY
  {
    key: PaymentsEnum.ON_DELIVERY,
    selectable: false,
  },
];

export {
  payments,
};
