import DocumentTypesEnum from '@/modules/documents/enums/DocumentTypesEnum';
import ContractorCoreModel from '@/modules/contractors/models/ContractorCoreModel';
import { ApiORMModelRelationsFieldsContract } from '@/core/bridge/orm/api/relations/contracts/ApiORMModelRelationsFieldsContract';
import { ApiRelationModelsEnum } from '@/shared/lib/api/relations/ApiRelationModelsEnum';
import DocumentItemCoreModel from '@/modules/documents/models/DocumentItemCoreModel';
import { VAT_RATES } from '@/shared/lib/config/vatConfig';
import DocumentDataCoreModel from '@/modules/documents/models/DocumentDataCoreModel';
import Locale from '@/core/locale/Locale';
import AmountsInterface from '@/shared/lib/interfaces/AmountsInterface';
import DocumentTotalsVatInterface from '@/modules/documents/interfaces/DocumentTotalsVatInterface';
import ProductCoreModel from '@/modules/products/models/ProductCoreModel';
import { calculateDocumentTotals } from '@/modules/documents/utils/DocumentUtils';
import AmountsFormattedInterface from '@/shared/lib/interfaces/AmountsFormattedInterface';
import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';
import moment from 'moment';
import DocumentStatusesEnum from '@/modules/documents/enums/DocumentStatusesEnum';

export default class DocumentCoreModel extends ORMModelExtended {
  /**
   * ORM entity
   */
  public static entity = 'documents';

  /**
   * ORM relation fields
   */
  public static relationFields: ApiORMModelRelationsFieldsContract = {
    [ApiRelationModelsEnum.CONTRACTOR]: 'contractor',
    [ApiRelationModelsEnum.DOCUMENT_ITEMS]: 'documentItems',
    [ApiRelationModelsEnum.DOCUMENT_DATA]: 'documentData',
  };

  /**
   * ORM fields
   */
  public static fields() {
    return {
      id: this.number(null),

      number: this.string(null).nullable(),
      order: this.number(null).nullable(),
      type: this.string(null).nullable(),

      contractorId: this.number(0).nullable(),
      contractor: this.belongsTo(ContractorCoreModel, 'contractorId'),

      documentDataId: this.number(0).nullable(),
      documentData: this.belongsTo(DocumentDataCoreModel, 'documentDataId'),

      documentItems: this.hasMany(DocumentItemCoreModel, 'documentId'),

      relatedDocumentId: this.number(0).nullable(),
      relatedDocument: this.belongsTo(DocumentCoreModel, 'relatedDocumentId'),

      date: this.string(null).nullable(),
      dateSale: this.string(null).nullable(),
      datePayment: this.string(null).nullable(),

      settledDate: this.string(null).nullable(),

      payment: this.string(null).nullable(),
      prices: this.string(null).nullable(),
      currency: this.string(null).nullable(),

      comments: this.string(null).nullable(),

      net: this.number(0).nullable(),
      vat: this.number(0).nullable(),
      gross: this.number(0).nullable(),

      createdAt: this.string(null).nullable(),
      updatedAt: this.string(null).nullable(),
      canceledAt: this.string(null).nullable(),
      canceledComment: this.string(null).nullable(),
    };
  }

  /**
   * Public fields
   */
  public id!: number;

  public number!: string | null;
  public order!: number | null;
  public type!: DocumentTypesEnum | null;

  public contractorId!: number | null;
  public contractor!: ContractorCoreModel | null;

  public documentDataId!: number | null;
  public documentData!: DocumentDataCoreModel | null;

  public documentItems!: DocumentItemCoreModel[];

  public relatedDocumentId!: number | null;
  public relatedDocument!: DocumentCoreModel | null;

  public date!: string;
  public dateSale!: string;
  public datePayment!: string;

  public settledDate!: string | null;

  public payment!: string; // TODO enum
  public prices!: string; // TODO Enum
  public currency!: string; // TODO Enum

  public comments!: string | null;

  public net!: number;
  public vat!: number;
  public gross!: number;

  public createdAt!: string;
  public updatedAt!: string;
  public canceledAt!: string | null;
  public canceledComment!: string | null;

  /**
   * Check if has gross prices
   */
  public get hasGrossPrices(): boolean {
    return this.isReceipt;
  }

  /**
   * Check if has amount to return
   */
  public get hasAmountToReturn(): boolean {
    return this.isSaleCorrection && !this.relatedDocument?.isPaid;
  }

  /**
   * Check if document is type receipt
   */
  public get isReceipt(): boolean {
    return this.isType(DocumentTypesEnum.RECEIPT);
  }

  /**
   * Check if document is type sale invoice
   */
  public get isSaleInvoice(): boolean {
    return this.isType(DocumentTypesEnum.SALE_INVOICE);
  }

  /**
   * Check if document is type sale correction
   */
  public get isSaleCorrection(): boolean {
    return this.isType(DocumentTypesEnum.SALE_INVOICE_CORRECTION);
  }

  /**
   * Check if document is type sale duplicate
   */
  public get isSaleDuplicate(): boolean {
    return this.isType(DocumentTypesEnum.SALE_INVOICE_DUPLICATE);
  }

  /**
   * Check if document is type proforma
   */
  public get isProforma(): boolean {
    return this.isType(DocumentTypesEnum.PROFORMA);
  }

  /**
   * Check if document is type purchase invoice
   */
  public get isPurchaseInvoice(): boolean {
    return this.isType(DocumentTypesEnum.PURCHASE_INVOICE);
  }

  /**
   * Check if document has contractor
   */
  public get hasContractor(): boolean {
    return !!this.contractor;
  }

  /**
   * Check if document has document data
   */
  public get hasDocumentData(): boolean {
    return !!this.documentData;
  }

  /**
   * Check if document has the same sale date
   */
  public get hasSameSaleDate(): boolean {
    return this.date === this.dateSale;
  }

  /**
   * Check if document is canceled
   */
  public get isCanceled(): boolean {
    return !!this.canceledAt;
  }

  /**
   * Get totals formatted
   */
  public get totalsFormatted(): AmountsFormattedInterface {
    return {
      net: Locale.price(this.net),
      vat: Locale.price(this.vat),
      gross: Locale.price(this.gross),
    };
  }

  /**
   * Get totals formatted with currency
   */
  public get totalsWithCurrency(): AmountsFormattedInterface {
    return {
      net: Locale.priceWithCurrency(this.net, this.currency),
      vat: Locale.priceWithCurrency(this.vat, this.currency),
      gross: Locale.priceWithCurrency(this.gross, this.currency),
    };
  }

  /**
   * Get totals vat by rate
   */
  public get totalsVatByRate(): DocumentTotalsVatInterface {
    return VAT_RATES.reduce((totals: any, vatRate: number | string) => {
      totals[vatRate] = this.sumItemsVat(this.itemsByVatRate(vatRate));
      return totals;
    }, {});
  }

  /**
   * Get totals vat by rate formatted
   */
  public get totalsVatByRateFormatted(): DocumentTotalsVatInterface {
    return VAT_RATES.reduce((totals: any, vatRate: number | string) => {
      totals[vatRate] = Locale.price(this.sumItemsVat(this.itemsByVatRate(vatRate)));
      return totals;
    }, {});
  }

  /**
   * Check if document can be created sale correction based on document
   */
  public get canCreateSaleCorrection(): boolean {
    return this.isSaleInvoice && !this.isCanceled; // TODO develop more
  }

  /**
   * Check if document can be updated
   */
  public get canBeUpdated(): boolean {
    return !this.isCanceled; // TODO develop more
  }

  /**
   * Check if document is paid
   */
  public get isPaid(): boolean {
    return !!this.settledDate && !this.isCanceled;
  }

  /**
   * Check if document is outdated
   */
  public get isOutdated(): boolean {
    if (!this.datePayment || this.isPaid || this.isCanceled) {
      return false;
    }

    return moment().subtract(1, 'day').isAfter(this.datePayment);
  }

  /**
   * Get calculate dynamic totals
   */
  public get dynamicTotals(): AmountsInterface {
    return calculateDocumentTotals(this.documentItems, this.hasGrossPrices);
  }

  /**
   * Get dynamic totals formatted
   */
  public get dynamicTotalsFormatted(): AmountsFormattedInterface {
    return {
      net: Locale.price(this.dynamicTotals.net),
      vat: Locale.price(this.dynamicTotals.vat),
      gross: Locale.price(this.dynamicTotals.gross),
    };
  }

  /**
   * Get dynamic totals formatted
   */
  public get dynamicTotalsFormattedWithCurrency(): AmountsFormattedInterface {
    return {
      net: Locale.priceWithCurrency(this.dynamicTotals.net),
      vat: Locale.priceWithCurrency(this.dynamicTotals.vat),
      gross: Locale.priceWithCurrency(this.dynamicTotals.gross),
    };
  }

  /**
   * Get status
   */
  public get status(): DocumentStatusesEnum {
    if (this.isCanceled) {
      return DocumentStatusesEnum.CANCELED;
    }

    if (this.isOutdated) {
      return DocumentStatusesEnum.OUTDATED;
    }

    if (this.isPaid) {
      return DocumentStatusesEnum.PAID;
    }

    return DocumentStatusesEnum.UNPAID;
  }

  /**
   * Check if document is given type
   * @param type
   */
  public isType(type: DocumentTypesEnum) {
    return this.type === type;
  }

  /**
   * Get items by vat rate
   * @param vatRate
   */
  public itemsByVatRate(vatRate: number | string): DocumentItemCoreModel[] {
    return this.documentItems.filter((documentItem: DocumentItemCoreModel) => {
      return documentItem.vatRate === vatRate;
    });
  }

  /**
   * Return document items gruped by vat rate
   */
  public groupItemsByVatRate(): any { // TODO interface
    return this.documentItems.reduce((grouped: any, documentItem: DocumentItemCoreModel) => {
      grouped = (grouped[documentItem.vatRate] || []).push(documentItem);
      return grouped;
    }, {});
  }

  /**
   * Sum given items vat value
   * @param items
   */
  public sumItemsVat(items: DocumentItemCoreModel[]) {
    return items.reduce((total: number, item: DocumentItemCoreModel) => {
      total += item.totalVat;
      return total;
    }, 0);
  }

  /**
   * Sync given document items
   * @param documentItems
   */
  public syncDocumentItems(documentItems: DocumentItemCoreModel[]) {
    this.documentItems = documentItems.map((items: DocumentItemCoreModel) => {
      const documentItem: DocumentItemCoreModel = new DocumentItemCoreModel(items.data(['id', 'documentId']));
      documentItem.product = documentItem.productId ? ProductCoreModel.find(documentItem.productId) : null;

      return documentItem;
    });
  }
}
