import {AxiosError, AxiosResponse} from 'axios';
import {ApiErrorResponseContract} from '@/core/api/errors/contracts/ApiErrorResponseContract';
import ApiORMRelationsQueryBuilder from '@/core/bridge/orm/api/relations/ApiORMRelationsQueryBuilder';
import {ApiORMRelationsSchemaContract} from '@/core/bridge/orm/api/relations/contracts/ApiORMRelationsResponseContract';
import ApiHeaders from '@/core/api/headers/ApiHeaders';
import ApiHeadersGroupEnum from '@/core/api/headers/support/ApiHeadersGroupEnum';
import ApiErrorSchemaEnum from '@/core/api/support/ApiErrorSchemaEnum';
import ApiORMQueryRelationHandler from '@/core/bridge/orm/api/relations/ApiORMQueryRelationHandler';
import IndexableInterface from '@/core/interfaces/IndexableInterface';
import Pagination from '@/core/support/pagination/Pagination';
import { ApiRequestConfigContract } from '@/core/api/support/ApiRequestConfigContract';
import ApiSettings from '@/core/api/settings/ApiSettings';

export default abstract class ApiSchema {
  /**
   * API settings
   */
  public apiSettings!: any; // TODO add type

  /**
   * Parse basic response
   * @param response
   * @param requestData
   */
  public parseResponse(response: AxiosResponse, requestData?: ApiRequestConfigContract): any {
    return response;
  }

  /**
   * Parse pagination
   * @param responsePagination
   * @param pagination
   */
  public parsePagination(responsePagination: any, pagination: Pagination): Pagination {
    return pagination;
  }

  /**
   * Parse error response
   * @param error
   * @param requestData
   */
  public parseErrorResponse(error: AxiosError, requestData?: ApiRequestConfigContract): ApiErrorResponseContract {
    return {
      status: error && error.response ? Number(error.response.status) : null,
      messages: error && error.message ? [{
        type: ApiErrorSchemaEnum.MESSAGE,
        message: error.message,
      }] : [],
      symbol: error && error.code ? error.code : null,
    };
  }

  /**
   * REQUEST
   */

  /**
   * Prepare request headers
   */
  public prepareRequestHeaders() {
    if (!this.apiSettings) {
      throw Error('No API settings defined!');
    }

    return {
      baseURL: this.apiSettings.apiURL,
      withCredentials: true,
      headers: {
        common: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          ...ApiHeaders.get(ApiHeadersGroupEnum.DATA),
        },
      },
    };
  }

  /**
   * Prepare query pagination
   * @param pagination
   */
  public prepareQueryPagination(pagination: Pagination): object {
    return pagination.getRequestData();
  }

  /**
   * Prepare relations data
   * @param relations
   */
  public prepareRelationQueryParams(relations: ApiORMRelationsQueryBuilder): object {
    const parsed: object = this.recurencyRelationDataMapper(relations.getRelations());
    return { relations: JSON.stringify({ relations: parsed, fields: relations.getFields() }) };
  }

  /**
   * Prepare query params
   * @param urlParams
   */
  public prepareRequestUrlParams(urlParams: IndexableInterface = {}) {
    const toQueryString = (params: IndexableInterface = {}, prefix: string = '') => {
      const query: string[] = Object.keys(params).map((k) => {
        let key: string = k;
        let value: any = params[key];

        if (!value) {
          value = '';
        }

        switch (params.constructor) {
          case Array:
            key = `${prefix}[]`;
            break;
          case Object:
            key = (prefix ? `${prefix}[${key}]` : key);
            break;
        }

        if (typeof value === 'object') {
          return toQueryString(value, key); // for nested objects
        }

        return `${key}=${encodeURIComponent(value)}`;
      });

      return query.join('&');
    };

    return toQueryString(urlParams);
  }

  /**
   * Prepare query params
   * @param params
   */
  public prepareQueryParams(params: object): object {
    return params;
  }

  /**
   * Prepare custom parsed data for CREATE
   * @param data
   */
  public prepareUpdateData(data: object): object {
    return data;
  }

  /**
   * Prepare custom parsed data for UPDATE
   * @param data
   */
  public prepareCreateData(data: object): object {
    return data;
  }

  /**
   * Prepare custom parsed data for DELETE
   * @param data
   */
  public prepareDeleteData(data: object): object {
    return data;
  }

  /**
   * Prepare custom parsed data for batch CREATE
   * @param data
   */
  public prepareBatchUpdateData(data: IndexableInterface[]): any {
    return data;
  }

  /**
   * Prepare custom parsed data for batch UPDATE
   * @param data
   */
  public prepareBatchCreateData(data: IndexableInterface[]): any {
    return data;
  }

  /**
   * CONFIG
   */

  /**
   * Return models relations map
   */
  public getRelationsMap(): ApiORMRelationsSchemaContract {
    return {};
  }

  /**
   *
   * @param relations
   * @protected
   */
  protected recurencyRelationDataMapper(relations: ApiORMQueryRelationHandler[]): string[] {
    let result: string[] = [];

    relations.forEach((relation: ApiORMQueryRelationHandler) => {
      result.push(relation.name);
      if (relation.relations) {
        result = [
          ...result,
          ...this.recurencyRelationDataMapper(relation.relations),
        ];
      }
    });

    return result;
  }
}
