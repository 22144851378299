





















import { Component, Vue, Prop } from 'vue-property-decorator';

import { redirect } from '@/shared/lib/support/router/RouterUtils';
import ImgViewer from '@/shared/resources/components/ImgViewer.vue';
import ListItemTitle from '@/shared/resources/components/lists/ListItemTitle.vue';
import ListItemContent from '@/shared/resources/components/lists/ListItemContent.vue';
import ListItem from '@/shared/resources/components/lists/ListItem.vue';
import ListItemSubtitle from '@/shared/resources/components/lists/ListItemSubtitle.vue';

@Component({
  components: {
    ListItemSubtitle,
    ListItem,
    ListItemContent,
    ListItemTitle,
    ImgViewer,
  },
})
export default class NavigationLogo extends Vue {
  /**
   * Props
   */
  @Prop({ default: 'Aplikacja' }) private text!: string;

  /**
   * Methods
   */
  private goToDashboard() {
    this.$router.push({ name: 'dashboard' });
  }
}
