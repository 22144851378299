/**
 * Remove string duplicates
 * @param array
 */
export const removeStringDuplicates = (array: string[]) => {
  return array.filter((stringValue: string, index: number) => {
    return array.indexOf(stringValue) === index;
  });
};

/**
 * Find index by attribute value
 * @param array
 * @param attr
 * @param value
 */
export const indexByAttr = (array: any[], attr: string, value: any) => {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
};
