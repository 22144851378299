
























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Card from './cards/Card.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import Icon from '@/shared/resources/components/Icon.vue';
import UISettings from '@/app/lib/settings/UISettings';

@Component({
  components: {
    Icon,
    Button,
    Card,
  },
})
export default class SideModal extends Vue {
  /**
   * Props
   * @private
   */
  @Prop() private value!: boolean;
  @Prop() private title?: number;
  @Prop({ default: 'regular' }) private size!: string;
  @Prop({ default: 0 }) private index!: number;

  /**
   *
   */
  private get show(): boolean {
    return this.value;
  }

  private set show(value: boolean) {
    this.$emit('input', value);

    if (!value) {
      this.$emit('hide');
    }
  }

  /**
   * Data
   */
  private get modals(): any {
    return UISettings.sideModals;
  }

  /**
   * Class names
   * @private
   */
  private get classNames(): string {
    return [
      'side-modal',
      ['side-modal-' + this.index],
      [this.size],
    ].join(' ');
  }

  private onCloseButtonClick() {
    this.show = false;

    setTimeout(() => {
      this.$emit('hide');
    }, 1000);
  }
}
