enum OnlineOrdersStatusesEnum {
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  PAYMENT_CONFIRMED = 'PAYMENT_CONFIRMED',
  PROCESSING = 'PROCESSING',
  RECEIVED = 'RECEIVED',
  READY = 'READY',
  SEND = 'SEND',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
}

export { OnlineOrdersStatusesEnum };
