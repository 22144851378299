const productsRoutes: any = [
  {
    path: '/products',
    component: () => import(/* webpackChunkName: "products" */ '@/shared/resources/views/EmptyRouterView.vue'),
    meta: {
      title: 'products',
    },
    children: [
      {
        path: '',
        name: 'products',
        component: () => import('@/app/views/modules/products/ProductsView.vue'),
        meta: {
          customNavigation: 'ProductsNavigation',
        },
      },
      {
        path: 'category/:id',
        name: 'products.category',
        component: () => import('@/app/views/modules/products/ProductsView.vue'),
        meta: {
          customNavigation: 'ProductsNavigation',
        },
      },
      {
        path: ':id',
        name: 'products.details',
        component: () => import('@/app/views/modules/products/ProductDetailsView.vue'),
      },
    ],
  },
];

export { productsRoutes };
