import ServiceProvider from '@/core/providers/ServiceProvider';
import Auth from '@/app/lib/auth/Auth';

export default class AuthServiceProvider extends ServiceProvider {
  /**
   * Init service provider
   */
  public async init() {
    await Auth.checkAuth();
  }
}
