import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';
import { ApiORMModelRelationsFieldsContract } from '@/core/bridge/orm/api/relations/contracts/ApiORMModelRelationsFieldsContract';
import { fullAddressStreet, fullAddressCity } from '@/core/helpers/utils/AddressUtils';

export default class OnlineOrderAddressDataCoreModel extends ORMModelExtended {
  /**
   * ORM entity
   */
  public static entity = 'online-orders-address-data';

  /**
   * API method conf
   */
  public static apiConfig = {
    actions: {
      fetch: {
        method: 'GET',
        url: '/online-orders-address-data',
      },
    },
  };

  /**
   * Relation fields
   */
  public static relationFields: ApiORMModelRelationsFieldsContract = {};

  /**
   * ORM field
   */
  public static fields() {
    return {
      id: this.number(0),

      name: this.string(''),
      street: this.string(''),
      building: this.string(''),
      locale: this.string(null).nullable(),
      zipCode: this.string(''),
      place: this.string(''),

      createdAt: this.string(''),
      updatedAt: this.string(''),
    };
  }

  /**
   * Public fields
   */
  public id!: number;

  public name!: string;
  public street!: string;
  public building!: string;
  public locale!: string | null;
  public zipCode!: string;
  public place!: string;

  public createdAt!: string;
  public updatedAt!: string;

  /**
   * Sync data
   * @param name
   * @param street
   * @param building
   * @param locale
   * @param zipCode
   * @param place
   */
  public syncData(
    name: string,
    street: string,
    building: string,
    locale: string | null,
    zipCode: string,
    place: string,
  ) {
    this.name = name;
    this.street = street;
    this.building = building;
    this.locale = locale;
    this.zipCode = zipCode;
    this.place = place;
  }

  /**
   * Get full address street
   */
  public get fullAddressStreet(): string {
    return fullAddressStreet(this.street, this.building, this.locale);
  }

  /**
   * Get full address city
   */
  public get fullAddressCity(): string {
    return fullAddressCity(this.zipCode, this.place);
  }

  /**
   * Convert to object
   */
  public toObject(): object {
    return {
      name: this.name,
      street: this.street,
      building: this.building,
      locale: this.locale,
      zipCode: this.zipCode,
      place: this.place,
    };
  }

  /**
   * Prepare data to request
   */
  public toRequest(): object {
    return this.toObject();
  }
}
