import Vue from 'vue';
import Router, { RawLocation, Route } from 'vue-router';

import { appRoutes } from '@/app/routes/appRoutes';
import Locale from '@/core/locale/Locale';
import UISettings from '@/app/lib/settings/UISettings';
import { getNearestRouteTitle, getRouteLayout } from '@/shared/lib/support/router/RouterUtils';
import Auth from '@/app/lib/auth/Auth';

Vue.use(Router);

/**
 * Configure new router
 */
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: appRoutes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

/**
 * Before each hook
 */
router.beforeEach(async (to: Route, from: Route, next: (to?: RawLocation) => void) => {
  UISettings.closeSideModals();

  if (to.matched.some((record) => record.meta.public)) {
    // public routes
    next();
  } else if (to.matched.some((record) => record.meta.guest)) {
    // guest routes
    if (!Auth.isAuthenticated) {
      next();
      return;
    }

    next({ name: 'dashboard' });
  } else {
    // auth routes
    if (Auth.isAuthenticated) {
      next();
      return;
    }

    if (to.name && !to.name.includes('auth.')) {
      next({ name: 'auth.login', query: { redirect: to.path } });
    } else {
      next({ name: 'auth.login' });
    }
  }
});

/**
 * After each hook
 */
router.afterEach((to: Route) => {
  const appTitle: string = Locale.i18n.t('app.title').toString();
  const routeTitle: string | null = getNearestRouteTitle(to.matched);

  if (routeTitle) {
    const title = Locale.i18n.t('app.views.' + routeTitle);
    document.title = `${title} - ${appTitle}`;
  } else {
    document.title = appTitle;
  }

  UISettings.layout = getRouteLayout(to);
});

/**
 * Export layout
 */
export { router };
