import { Model, Record } from '@vuex-orm/core';
import { isArray, isObject, isString, isNumber } from 'lodash';
import ORMCollection from '../ORMCollection';
import ApiORMRelationsQueryBuilder from '@/core/bridge/orm/api/relations/ApiORMRelationsQueryBuilder';
import ApiORMRelationsMapper from '@/core/bridge/orm/api/relations/ApiORMRelationsMapper';
import { ApiORMQueryRelationsContract } from '@/core/bridge/orm/api/relations/contracts/ApiORMQueryRelationsContract';
import ApiQueryBuilder from '@/core/api/ApiQueryBuilder';
import IndexableInterface from '@/core/interfaces/IndexableInterface';
import { ApiORMRelationsResponseContract } from '@/core/bridge/orm/api/relations/contracts/ApiORMRelationsResponseContract';
import { AxiosResponse } from 'axios';
import { ApiRequestConfigContract } from '@/core/api/support/ApiRequestConfigContract';
import { ApiOrmAxiosRequestConfigContract } from '@/core/bridge/orm/api/contracts/ApiOrmAxiosRequestConfigContract';
import ApiSettings from '@/core/api/settings/ApiSettings';

declare type InstanceOf<T> = T extends new (...args: any[]) => infer R ? R : any;

export default class ApiORMQueryBuilder extends ApiQueryBuilder {
  /**
   * Protected fields
   */
  protected requestedFields: string[] = [];
  protected relationsQueryBuilder!: ApiORMRelationsQueryBuilder;
  protected relationsMapper: ApiORMRelationsMapper;
  protected checkStoreData: boolean = false;
  protected model!: InstanceOf<InstanceOf<Model>>;
  protected extendedAxiosRequestConfig: ApiOrmAxiosRequestConfigContract = {};

  /**
   * Constructor set default model
   * @param model
   */
  constructor(model: InstanceOf<InstanceOf<Model>>) {
    super();
    this.model = model;
    this.relationsMapper = new ApiORMRelationsMapper();
    this.relationsQueryBuilder = new ApiORMRelationsQueryBuilder();

    this.extendedAxiosRequestConfig = this.axiosRequestConfig;
    this.extendedAxiosRequestConfig.save = true;
  }

  /**
   * Fetch models
   */
  public async fetch(): Promise<ORMCollection> {
    const config: ApiRequestConfigContract = this.getActionConfig('fetch');

    this.prepareAxiosResponse(this.model.api().axios, config);

    const result = await this.model.api().request({
      ...this.extendedAxiosRequestConfig,
      method: config.method,
      url: this.prepareRequestUrl(config.url),
    });

    return this.prepareOrmCollection(result);
  }

  /**
   * Get model
   */
  public async get() {
    const lastParamValue: string | number | null = this.lastParamValue();

    // check store if model exists
    if (this.checkStoreData && this.hasParams && !!lastParamValue) {
      const cachedModel = this.model.find(lastParamValue);
      if (!!cachedModel && !cachedModel.isIncomplete()) {
        return cachedModel;
      }
    }

    const config: ApiRequestConfigContract = this.getActionConfig('get');

    this.prepareAxiosResponse(this.model.api().axios, config);

    const result = await this.model.api().request({
      ...this.extendedAxiosRequestConfig,
      method: config.method,
      url: this.prepareRequestUrl(config.url),
    });

    if (result.response.relations) {
      await this.parseResponseRelations(result.response.relations);
    }

    if (isString(result.response.data) || isNumber(result.response.data)) {
      return result.response.data;
    }

    const query = this.model.query();

    // add relations to ORM query
    if (!!this.relationsQueryBuilder && !this.relationsQueryBuilder.empty()) {
      await this.relationsQueryBuilder.buildORMQueryRelations(query, this.model);
    }

    const model = query.find(this.model.getIdFromRecord(result.response.data));

    if (this.requestedFields.length) {
      // mark model as incomplete if custom fields list requested
      model.setIncomplete();
    }

    return model;
  }

  /**
   * Create new model
   */
  public async create() {
    const config: ApiRequestConfigContract = this.getActionConfig('create');

    const data = this.getSchema().prepareCreateData(this.getData());

    this.prepareAxiosResponse(this.model.api().axios, config);

    const result = await this.model.api().request({
      ...this.extendedAxiosRequestConfig,
      method: config.method,
      url: this.prepareRequestUrl(config.url),
      data,
    });

    if (!result) {
      return;
    }

    if (
      this.extendedAxiosRequestConfig.save &&
      isObject(result.response.data) &&
      result.response.status === 200 &&
      this.model.getIdFromRecord(result.response.data)
    ) {
      return this.model.query().find(this.model.getIdFromRecord(result.response.data));
    } else {
      return result.response.data;
    }
  }

  /**
   * Save model
   */
  public async update() {
    const config: ApiRequestConfigContract = this.getActionConfig('update');

    const data = this.getSchema().prepareUpdateData(this.getData());

    this.prepareAxiosResponse(this.model.api().axios, config);

    const result = await this.model.api().request({
      ...this.extendedAxiosRequestConfig,
      method: config.method,
      url: this.prepareRequestUrl(config.url),
      data,
    });

    const query = this.model.query();

    // add relations to ORM query
    if (!!this.relationsQueryBuilder && !this.relationsQueryBuilder.empty()) {
      await this.relationsQueryBuilder.buildORMQueryRelations(query, this.model);
    }

    if (!this.extendedAxiosRequestConfig.save) {
      return result.response.data;
    }

    if (
      isObject(result.response.data) &&
      result.response.status === 200 &&
      this.model.getIdFromRecord(result.response.data)
    ) {
      return query.find(this.model.getIdFromRecord(result.response.data));
    } else if (this.lastParamValue()) {
      return query.find(this.lastParamValue());
    } else {
      return result.response.data;
    }
  }

  /**
   * Remove model
   */
  public async delete() {
    const config: ApiRequestConfigContract = this.getActionConfig('delete');

    const data = this.getSchema().prepareDeleteData(this.getData());

    this.prepareAxiosResponse(this.model.api().axios, config);

    if (this.extendedAxiosRequestConfig.save && Number(this.lastParamValue()) > 0) {
      this.extendedAxiosRequestConfig.delete = Number(this.lastParamValue());
    }

    await this.model.api().request({
      ...this.extendedAxiosRequestConfig,
      method: config.method,
      url: this.prepareRequestUrl(config.url),
      data,
    });
  }

  /**
   * Set store fetching if data exists
   */
  public checkStore() {
    this.checkStoreData = true;
    return this;
  }

  /**
   * Define requested fields (model will be marked as incomplete and gonna had disabled cache)
   * @param fields
   */
  public fields(fields: string[]) {
    this.requestedFields = this.requestedFields.concat(fields);
    this.relationsQueryBuilder.setFields(this.requestedFields);
    return this;
  }

  /**
   * Delete all existing entities of this model before insert new entities
   */
  public fresh() {
    this.extendedAxiosRequestConfig.persistBy = 'create';
    return this;
  }

  /**
   * Will only update existing entities without inserting new
   */
  public updateOnly() {
    this.extendedAxiosRequestConfig.persistBy = 'update';
    return this;
  }

  /**
   * Avoid updating store entity
   */
  public withoutSaving() {
    this.extendedAxiosRequestConfig.save = false;
    return this;
  }

  /**
   * Define response data transformation callback
   * @param callback
   */
  public transform(callback: (response: AxiosResponse) => Record | Record[]) {
    this.extendedAxiosRequestConfig.dataTransformer = callback;
    return this;
  }

  /**
   * Define auto relation with other Models
   * @param relations
   */
  public with(relations: ApiORMQueryRelationsContract | string | string[]) {
    if (typeof relations === 'string') {
      this.relationsQueryBuilder.addByString(relations);
    } else if (isArray(relations)) {
      relations.forEach((relation: string) => {
        this.relationsQueryBuilder.addByString(relation);
      });
    } else {
      this.relationsQueryBuilder.add(relations);
    }
    return this;
  }

  /**
   * Add data to store
   * @param data
   */
  public data(data: object | null) {
    if (data instanceof FormData) {
      this.store = data;
    } else if (isObject(data)) {
      this.store = {
        ...this.store,
        ...data,
      };
    }
    return this;
  }

  /**
   * Prepare request final url from collected data
   * @param url
   * @param additionalQueryParams
   * @private
   */
  protected prepareRequestUrl(url: string, additionalQueryParams: IndexableInterface = {}): string {
    let queryParams: IndexableInterface = {
      ...additionalQueryParams,
    };

    if (!this.relationsQueryBuilder.empty()) {
      // relations params
      queryParams = {
        ...queryParams,
        ...this.getSchema().prepareRelationQueryParams(this.relationsQueryBuilder),
      };
    }

    return super.prepareRequestUrl(url, queryParams);
  }

  /**
   * @param relations
   * @private
   */
  protected async parseResponseRelations(relations: ApiORMRelationsResponseContract) {
    // start mapping relations into store
    await this.relationsMapper.build(
      relations,
      this.relationsQueryBuilder,
      this.getSchema().getRelationsMap(),
    );
  }

  /**
   * Get action config defined in model
   * @param action
   * @protected
   */
  protected getActionConfig(action: string): ApiRequestConfigContract {
    if (!this.model.apiConfig) {
      throw new Error(`Model ${this.model.name} doesnt have api configuration`);
    }
    if (!this.model.apiConfig.actions || !this.model.apiConfig.actions[action]) {
      throw new Error(`Model ${this.model.name} doesnt have configuration for action ${action}`);
    }

    return this.model.apiConfig.actions[action];
  }

  /**
   * Check if request has custom fields and models are incomplete
   */
  protected hasRequestedFields(): boolean {
    return this.requestedFields.length > 0;
  }

  /**
   * Prepare result as ORMCollection
   * @param result
   * @protected
   */
  protected async prepareOrmCollection(result: any): Promise<ORMCollection> {
    if (!result || !result.entities) {
      return new ORMCollection(null, this.model);
    }

    // replace pagination with data from response
    this.activePagination = this.getSchema().parsePagination(result.response.pagination, this.getPagination());

    if (result.response.relations) {
      await this.parseResponseRelations(result.response.relations);
    }

    const ids: number[] = result.response.data.map((item: any) => this.model.getIdFromRecord(item));
    return new ORMCollection(ids, this.model, this.activePagination, this.relationsQueryBuilder);
  }
}
