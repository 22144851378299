





















import { Vue, Component, Prop } from 'vue-property-decorator';

import ProductCoreModel from '@/modules/products/models/ProductCoreModel';

import ProductAddToCartButton from './partials/details/ProductAddToCartButton.vue';
import ProductAvailability from '@/app/modules/products/components/partials/details/ProductAvailability.vue';
import ImgViewer from '@/shared/resources/components/ImgViewer.vue';

@Component({
  components: {
    ImgViewer,
    ProductAvailability,
    ProductAddToCartButton,
  },
})
export default class ProductAddToCart extends Vue {
  /**
   * Props
   */
  @Prop() private productId!: number;

  /**
   * Data
   */
  private product: ProductCoreModel | null = null;

  /**
   * Lifecycle hooks
   */
  private created() {
    this.getProduct();
  }

  /**
   * Methods
   */
  private getProduct() {
    this.product = ProductCoreModel.find(this.productId);
  }
}
