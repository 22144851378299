import { render, staticRenderFns } from "./ProductPrice.vue?vue&type=template&id=18acce48&"
import script from "./ProductPrice.vue?vue&type=script&lang=ts&"
export * from "./ProductPrice.vue?vue&type=script&lang=ts&"
import style0 from "./ProductPrice.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports