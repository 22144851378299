import ServiceProvider from '@/core/providers/ServiceProvider';
import AuthSettings from '@/core/api/settings/AuthSettings';
import Auth from '@/app/lib/auth/Auth';
import { redirect } from '@/shared/lib/support/router/RouterUtils';
import { router } from '@/app/router';
import AuthSettingsEnum from '@/core/api/support/AuthSettingsEnum';

export default class AuthExpiredServiceProvider extends ServiceProvider {
  /**
   * Init provider
   */
  public async init() {
    await AuthSettings.storage.afterUpdateHook(async (name: string, value: any) => {
      if (value === true) {
        await Auth.reset();
        return redirect({ name: 'auth.login', query: { redirect: router.currentRoute.path } });
      }
    }, AuthSettingsEnum.EXPIRED);
  }
}
