import { OnlineOrdersConfigInterface } from '@/app/modules/online-orders/interfaces/OnlineOrdersConfigInterface';
import { formatAmount } from '@/core/helpers/utils/NumberUtils';
import Locale from '@/core/locale/Locale';

/**
 * Config
 */
const config: OnlineOrdersConfigInterface = {
  minOrder: {
    value: 0.01,
    // value: 1.00,
    isGross: true,
  },
};

/**
 * Get min order string]
 * TODO: to utils?
 */
const minOrderString = (): string => {
  const amountType: string = config.minOrder.isGross ? 'gross' : 'net';
  return `${formatAmount(config.minOrder.value)} ${Locale.currencySymbol} ${Locale.i18n.t('common.' + amountType)}`;
};

/**
 * Export
 */
export default config;

export {
  minOrderString,
};
