import SettingsContract from '../settings/contracts/SettingsContract';
import SettingsStorageRegistry from '@/core/settings/SettingsStorageRegistry';

export default abstract class ServiceProvider {
  /**
   * Settings
   */
  protected reinitialize: boolean = false;
  protected requirements: string[] = [];
  private blockade: boolean = false;
  private initialized: boolean = false;
  private settings: SettingsContract = [];

  public lock() {
    this.initialized = true;
    this.blockade = true;
  }

  public unlock() {
    this.blockade = false;
  }

  /**
   * Check if should be called once
   */
  public get isDisposable() {
    return !this.reinitialize;
  }

  /**
   * Check if initialized before
   */
  public get isReturning() {
    return this.reinitialize && this.initialized;
  }

  /**
   * Check if only bootstraping without requirements checking
   */
  public get isBootstrap() {
    return !this.reinitialize && this.requirements.length === 0;
  }

  /**
   * Set actuall settings
   */
  public prepare() {
    this.settings = SettingsStorageRegistry.getMerged();
  }

  /**
   * Check if initialize is possible by comparing new settings
   */
  public checkInitialize() {
    if (this.blockade) {
      return false;
    }

    if (this.isBootstrap) {
      return !this.initialized;
    }

    if (this.requirements.length === 0) {
      return true;
    }

    const settings: SettingsContract = SettingsStorageRegistry.getMerged();
    const len = this.requirements.length;

    for (let i = 0; i < len; i++) {
      const param: string = this.requirements[i];

      if (!settings[param]) {
        // no required parameter in settings.. still waiting
        return false;
      }

      if (this.initialized && settings[param] !== this.settings[param]) {
        // provider already initialized and param has been changed
        return true;
      }
    }
    // all parameters set and none have changed
    // init only if provider was not initialized before
    return !this.initialized;
  }

  public abstract async init(param: string | null): Promise<any>;

  public async clear(): Promise<any> {
    // TODO
  }
}
