import ServiceProvider from '@/core/providers/ServiceProvider';
import Auth from '@/app/lib/auth/Auth';

export default class UserServiceProvider extends ServiceProvider {
  /**
   * Init service provider
   */
  public async init() {
    if (Auth.isAuthenticated) {
      await Auth.init();
    }
  }
}
