















































import { Component, Prop, Vue } from 'vue-property-decorator';
import Icon from '@/shared/resources/components/Icon.vue';
import UISettings from '@/app/lib/settings/UISettings';

import NavigationItem from '@/shared/resources/layouts/navigation/components/NavigationItem.vue';
import NavigationGroup from '@/shared/resources/layouts/navigation/components/NavigationGroup.vue';
import NavigationHeader from '@/shared/resources/layouts/navigation/components/NavigationHeader.vue';
import NavigationDivider from '@/shared/resources/layouts/navigation/components/NavigationDivider.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import { redirect } from '@/shared/lib/support/router/RouterUtils';
import Auth from '@/app/lib/auth/Auth';
import NavigationLogo from '@/shared/resources/layouts/navigation/components/NavigationLogo.vue';
import ContractorCoreModel from '@/modules/contractors/models/ContractorCoreModel';
import AppSettings from '@/app/lib/settings/AppSettings';
import Tooltip from '@/shared/resources/components/Tooltip.vue';

@Component({
  components: {
    Tooltip,
    NavigationLogo,
    Button,
    Icon,
    NavigationItem,
    NavigationGroup,
    NavigationHeader,
    NavigationDivider,
  },
})
export default class Navigation extends Vue {
  /**
   * Props
   */
  @Prop() private append!: boolean;
  @Prop() private disableRouteWatcher!: boolean;

  /**
   * Handle drawer
   */
  private get drawer(): boolean {
    return UISettings.drawerVisible;
  }

  private set drawer(value: boolean) {
    UISettings.drawerVisible = value;
  }

  /**
   * Getters
   */
  private get contractor(): ContractorCoreModel | null {
    return AppSettings.contractor;
  }

  private get mobileBreakpoint(): number {
    return 1264;
  }

  private get width(): number {
    if (this.$vuetify.breakpoint.xl) {
      return 300;
    }

    if (this.$vuetify.breakpoint.lgAndUp) {
      return 260;
    }

    return 300;
  }

  /**
   * Display getters
   */
  private get displayAppendSlot(): boolean {
    return this.append && this.$vuetify.breakpoint.lgAndUp;
  }

  /**
   * Methods
   */
  private goTo(routeName: string) {
    return redirect({ name: routeName });
  }

  private async logout() {
    try {
      await Auth.logout();
      redirect({ name: 'auth.login' });
    } catch (ex) {
      // TODO handle error
    }
  }

  /**
   * Handlers
   */
  private onLogoutButtonClick() {
    this.logout();
  }
}
