import IndexableInterface from '@/core/interfaces/IndexableInterface';

const navigationItems: IndexableInterface = [
  {
    title: 'Pulpit',
    icon: 'dashboard',
    route: { name: 'dashboard' },
  },
  {
    title: 'Sklep',
    header: true,
  },
  {
    title: 'Produkty',
    icon: 'layers',
    route: { name: 'products' },
  },
  // {
  //   title: 'Nowości',
  //   icon: 'star_outline',
  //   route: { name: 'featured' },
  // },
  {
    title: 'Rozliczenia',
    header: true,
  },
  {
    title: 'Zamówienia',
    icon: 'shopping_cart',
    route: { name: 'orders' },
  },
  {
    title: 'Faktury',
    icon: 'text_snippet',
    route: { name: 'invoices' },
  },
  {
    title: 'Inne',
    header: true,
  },
  {
    title: 'Profil kontrahenta',
    icon: 'person',
    route: { name: 'profile' },
  },
  {
    title: 'Informacje',
    icon: 'info',
    route: { name: 'information' },
  },
  {
    title: 'Regulamin',
    icon: 'article',
    route: { name: 'regulations' },
  },
  {
    title: 'Polityka prywatności',
    icon: 'lock',
    route: { name: 'privacy-policy' },
  },
  {
    divider: true,
    breakpoint: 'mdAndDown',
  },
  {
    title: 'Wyloguj się',
    icon: 'power_settings_new',
    route: { name: 'auth.logout' },
    breakpoint: 'mdAndDown',
  },
];

export { navigationItems };
