const cartRoutes: any = [
  {
    path: '/cart',
    component: () => import(/* webpackChunkName: "cart" */ '@/shared/resources/views/EmptyRouterView.vue'),
    meta: {
      title: 'cart',
    },
    children: [
      {
        path: '',
        name: 'cart',
        component: () => import('@/app/views/modules/cart/CartView.vue'),
      },
      {
        path: 'order',
        component: () => import('@/shared/resources/views/EmptyRouterView.vue'),
        meta: {
          title: 'order',
        },
        children: [
          {
            path: 'checkout',
            name: 'cart.checkout',
            component: () => import('@/app/views/modules/cart/CartOrderCheckoutView.vue'),
          },
          {
            path: 'summary',
            name: 'cart.summary',
            component: () => import('@/app/views/modules/cart/CartOrderSummaryView.vue'),
          },
          {
            path: 'success/:id',
            name: 'cart.success',
            component: () => import('@/app/views/modules/cart/CartOrderSuccessView.vue'),
          },

          // PayU
          {
            path: 'payu/success',
            name: 'cart.payu.success',
            component: () => import('@/app/views/modules/cart/payu/CartOrderPayuSuccessView.vue'),
          },
          {
            path: 'payu/error',
            name: 'cart.payu.error',
            component: () => import('@/app/views/modules/cart/payu/CartOrderPayuErrorView.vue'),
          },

          // mBank paynow
          {
            path: 'paynow/status',
            name: 'cart.paynow.status',
            component: () => import('@/app/views/modules/cart/m-bank-paynow/CartOrderMBankPaynowStatusView.vue'),
          },
          {
            path: 'paynow/success',
            name: 'cart.paynow.success',
            component: () => import('@/app/views/modules/cart/m-bank-paynow/CartOrderMBankPaynowSuccessView.vue'),
          },
          {
            path: 'paynow/error',
            name: 'cart.paynow.error',
            component: () => import('@/app/views/modules/cart/m-bank-paynow/CartOrderMBankPaynowErrorView.vue'),
          },
        ],
      },
    ],
  },
];

export { cartRoutes };
