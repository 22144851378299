


















import { Vue, Component, Prop } from 'vue-property-decorator';

import ProductCoreModel from '@/modules/products/models/ProductCoreModel';
import { PricesEnum } from '@/modules/prices/enums/PricesEnum';
import AppSettings from '@/app/lib/settings/AppSettings';

@Component
export default class ProductItemPrice extends Vue {
  /**
   * Props
   */
  @Prop() private product!: ProductCoreModel;
  @Prop({ default: false, type: Boolean }) private withUnit!: boolean;

  /**
   * Getters
   */
  private get activePrice(): PricesEnum {
    return AppSettings.contractorPrices;
  }

  private get price(): object { // TODO to model/service
    const price: number = this.product['price' + this.activePrice].toFixed(2);
    const exploded: string[] = price.toString().split('.');

    return {
      main: exploded[0],
      sup: exploded[1],
    };
  }

  private get pricesType(): string {
    return this.$t('prices.types.' + AppSettings.contractorPricesType).toString();
  }

  /**
   * Display getters
   */
  private get displayUnit(): boolean {
    return this.withUnit;
  }
}
