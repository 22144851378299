const onlineOrdersRoutes: any = [
  {
    path: '/orders',
    component: () => import(/* webpackChunkName: "orders" */ '@/shared/resources/views/EmptyRouterView.vue'),
    meta: {
      title: 'orders',
    },
    children: [
      {
        path: '',
        name: 'orders',
        component: () => import('@/app/views/modules/online-orders/OrdersView.vue'),
      },
      {
        path: ':id',
        name: 'orders.details',
        component: () => import('@/app/views/modules/online-orders/OrderDetailsView.vue'),
      },
    ],
  },
];

export { onlineOrdersRoutes };
