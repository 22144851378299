






import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class GridContainer extends Vue {
}
