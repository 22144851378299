/**
 * Normalize string (remove accents/diacritics)
 * @param  {string} str String to normalize
 */
export const normalize = (str: string) => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim();
};

/**
 * Generate random string
 * @param length
 */
export const randomString = (length: number) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const count = characters.length;
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * count));
  }
  return result;
};

/**
 * Convert string to title case
 * @param str
 */
export const toTitleCase = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
};

/**
 * Make hash from given string
 * @param str
 */
export const makeHash = (str: string): string => {
  let hash: number = 0;
  let chr: number = 0;

  if (str.length === 0) {
    return hash.toString();
  }

  for (let i: number = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash.toString();
};
