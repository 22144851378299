

























import { Vue, Component, Prop } from 'vue-property-decorator';
import Button from '@/shared/resources/components/buttons/Button.vue';
import Tooltip from '@/shared/resources/components/Tooltip.vue';

@Component({
  components: {
    Tooltip,
    Button,
  },
})
export default class TopBarButton extends Vue {
  /**
   * Props
   */
  @Prop() private tooltip?: string;
  @Prop() private to?: object;

  /**
   * Getters
   */
  private get disableTooltip(): boolean {
    return !this.tooltip;
  }

  /**
   * Handlers
   */
  private onClick() {
    this.$emit('click');
  }
}
