import DocumentItemCoreModel from '@/modules/documents/models/DocumentItemCoreModel';
import AmountsInterface from '@/shared/lib/interfaces/AmountsInterface';
import DocumentAmountsByVatRateInterface from '@/modules/documents/interfaces/DocumentAmountsByVatRateInterface';

import { calculateNetPrice, formatPrice } from '@/modules/prices/pricesUtils';

/**
 * Calculate document totals
 * @returns {{gross: number, vat: number, net: number}}
 * @param documentItems
 * @param hasGrossPrices
 */
export const calculateDocumentTotals = (documentItems: DocumentItemCoreModel[], hasGrossPrices: boolean) => {
  if (hasGrossPrices) {
    return calculateTotalsFromGross(documentItems);
  } else {
    return calculateTotalsFromNet(documentItems);
  }
};

/**
 * Calculate receipt totals
 * @param documentItems
 * @returns {*}
 */
export const calculateTotalsFromGross = (documentItems: DocumentItemCoreModel[]) => {
  // CALCULATION METHOD: net and tax calculated from total gross of each tax rate

  // group vat rates
  const itemsByVatRate = documentItems.reduce((vatRates: any, item: DocumentItemCoreModel) => {
    (vatRates[item.vatRate] = vatRates[item.vatRate] || []).push(item);
    return vatRates;
  }, []);

  // sum items total gross in vat rates
  const totalsGrossByVatRate =
    itemsByVatRate.reduce((totals: any, items: DocumentItemCoreModel[], vatRate: number | string) => {
      totals[vatRate] = items.reduce((totalGross: number, item: DocumentItemCoreModel) => {
        totalGross += item.dynamicTotalGross;
        return totalGross;
      }, 0);

      return totals;
    }, []);

  const vatRateValues: any = {
    23: 23,
    8: 8,
    5: 5,
    0: 0,
    zw: 0,
  };

  // calculate vat and gross of each gross total
  const totalsByVatRate = totalsGrossByVatRate
    .reduce((totals: DocumentAmountsByVatRateInterface, totalGross: number, vatRate: number | string) => {
      const totalNet = formatPrice(calculateNetPrice(totalGross, vatRateValues[vatRate]));
      const totalVat = totalGross - totalNet;

      totals[vatRate] = {
        net: totalNet,
        vat: totalVat,
        gross: totalGross,
      };

      return totals;
    }, []);

  // sum and return all totals
  return totalsByVatRate.reduce((totals: AmountsInterface, vatRateTotals: AmountsInterface) => {
    totals.net += vatRateTotals.net;
    totals.vat += vatRateTotals.vat;
    totals.gross += vatRateTotals.gross;

    return totals;
  }, {
    net: 0,
    vat: 0,
    gross: 0,
  });
};

/**
 * Calculate invoice totals
 * @param documentItems
 * @returns {{gross: number, vat: number, net: number}}
 */
export const calculateTotalsFromNet = (documentItems: DocumentItemCoreModel[]) => {
  // CALCULATION METHOD: sum of each item totals
  return documentItems.reduce((totals: AmountsInterface, item: DocumentItemCoreModel) => {
    totals.net += item.dynamicTotalNet;
    totals.vat += item.dynamicTotalVat;
    totals.gross += item.dynamicTotalGross;

    return totals;
  }, {
    net: 0,
    vat: 0,
    gross: 0,
  });
};
