import SettingsStorage from '@/core/settings/SettingsStorage';
import SettingsStorageRegistry from '@/core/settings/SettingsStorageRegistry';
import BrowserLocalSettingsStorageAdapter from '@/core/settings/adapters/BrowserLocalSettingsStorageAdapter';
import TableSettingsInterface from '@/shared/lib/interfaces/TableSettingsInterface';
import {SideModalInterface} from '@/shared/lib/interfaces/SideModalInterface';
import MemorySettingsStorageAdapter from '@/core/settings/adapters/MemorySettingsStorageAdapter';
import ProductsListViewEnum from '@/app/modules/products/enums/ProductsListViewsEnum';

const UI_DRAWER = 'DRAWER';
const UI_LAYOUT = 'LAYOUT';
const UI_TABLES_SETTINGS = 'TABLES_SETTINGS';
const UI_FORM_SAVE_BUTTONS = 'FORM_SAVE_BUTTONS';
const UI_SIDE_MODALS = 'SIDE_MODALS';
const UI_PRODUCTS_VIEW = 'PRODUCTS_VIEW';

export default class UISettings {
  /**
   * Navigation drawer
   * @param value
   */
  public static set drawerVisible(value: boolean) {
    this.store.setParam(UI_DRAWER, value);
  }

  public static get drawerVisible(): boolean {
    return this.store.getParam(UI_DRAWER);
  }

  /**
   * Layout
   */
  public static set layout(value: string) {
    this.store.setParam(UI_LAYOUT, value);
  }

  public static get layout(): string {
    return this.store.getParam(UI_LAYOUT);
  }

  /**
   * Side modals
   */
  public static set sideModals(value: SideModalInterface[]) {
    this.store.setParam(UI_SIDE_MODALS, value);
  }

  public static get sideModals(): SideModalInterface[] {
    return this.store.getParam(UI_SIDE_MODALS);
  }

  /**
   * Products view
   */
  public static set productsView(value: ProductsListViewEnum) {
    this.store.setParam(UI_PRODUCTS_VIEW, value);

    if (value) {
      this.storage.setParam(UI_PRODUCTS_VIEW, value);
    }
  }

  public static get productsView(): ProductsListViewEnum {
    return this.store.getParam(UI_PRODUCTS_VIEW);
  }

  /**
   * Storage
   */
  public static readonly storage: SettingsStorage = SettingsStorageRegistry.register(
    new BrowserLocalSettingsStorageAdapter('UI'),
  );

  public static readonly store: SettingsStorage = SettingsStorageRegistry.register(
    new MemorySettingsStorageAdapter('UI'),
  );

  /**
   * Load cached settings
   */
  public static loadCachedSettings() {
    this.productsView = this.storage.getParam(UI_PRODUCTS_VIEW);
  }

  /**
   * Side modals
   * @param component
   * @param props
   * @param title
   * @param size
   */
  public static openSideModal(component: string, props = {}, title?: string, size?: string) {
    // router.push({query: {component, ...props}}); // TODO

    this.sideModals = [
      ...this.sideModals ?? [],
      {
        model: true,
        index: this.sideModals ? this.sideModals.length : 0,
        component,
        props,
        title,
        size: size ?? 'regular',
      },
    ];
  }

  public static closeSideModal(indexToRemove: number) {
    this.sideModals = JSON.parse(JSON.stringify(this.sideModals)).filter((modal: SideModalInterface, index: number) => {
      return modal.index !== indexToRemove;
    });
  }

  public static closeSideModals() {
    this.sideModals = [];
  }

  /**
   * Caching tables pagination
   */
  public static cacheTableSettings(name: string, settings: TableSettingsInterface) {
    this.cacheInObject(name, settings, UI_TABLES_SETTINGS);
  }

  public static getCacheTableSettings(name: string) {
    return this.getFromCachedObject(name, UI_TABLES_SETTINGS);
  }

  /**
   * Caching form save buttons
   */
  public static cacheFormSaveButtons(name: string, saveAction: string) {
    this.cacheInObject(name, saveAction, UI_FORM_SAVE_BUTTONS);
  }

  public static getCacheFormSaveButtons(name: string): string {
    return this.getFromCachedObject(name, UI_FORM_SAVE_BUTTONS);
  }

  /**
   * Cache value at name in object
   * @param name
   * @param value
   * @param objectName
   */
  private static cacheInObject(name: string, value: any, objectName: string) {
    const object: any = this.storage.getObject(objectName) || {};
    object[name] = value;

    this.storage.setObject(objectName, object);
  }

  /**
   * Get from cached object
   * @param name
   * @param objectName
   */
  private static getFromCachedObject(name: string, objectName: string) {
    const object: any = this.storage.getObject(objectName);
    return object ? object[name] : null;
  }
}
