

















































import { Component, Vue, Watch } from 'vue-property-decorator';
import CategoriesTree from '../../../categories/components/CategoriesTree.vue';
import NavigationHeader from '@/shared/resources/layouts/navigation/components/NavigationHeader.vue';
import NavigationDivider from '@/shared/resources/layouts/navigation/components/NavigationDivider.vue';
import NavigationItem from '@/shared/resources/layouts/navigation/components/NavigationItem.vue';
import Icon from '@/shared/resources/components/Icon.vue';
import ORMCollection from '@/core/bridge/orm/ORMCollection';
import CategoryCoreModel from '@/modules/categories/models/CategoryCoreModel';
import Loader from '@/shared/resources/components/Loader.vue';
import { scrollToTop } from '@/shared/lib/helpers/scrollTo';
import { Route } from 'vue-router';

@Component({
  components: {
    Loader,
    Icon,
    NavigationItem,
    NavigationDivider,
    NavigationHeader,
    CategoriesTree,
  },
})
export default class ProductsNavigation extends Vue {
  /**
   * Data
   */
  private topItem: any = { // TODO add interface
    title: 'Wróć do pulpitu',
    icon: 'arrow_back',
    route: { name: 'dashboard' },
  };
  private categories: ORMCollection = new ORMCollection();
  private childrenCategories: ORMCollection = new ORMCollection();
  private parentCategory: CategoryCoreModel | null = null;
  private currentCategory: CategoryCoreModel | null = null;
  private loading: boolean = false;
  private showLoader: boolean = false;

  /**
   * Getters
   */
  private get title(): string {
    return this.currentCategory ? 'Podkategorie' : 'Kategorie';
  }

  private get currentItem(): any | null {
    if (!this.currentCategory) {
      return null;
    }

    return {
      title: this.currentCategory.name,
      route: {
        name: 'products.category',
        params: {
          id: this.currentCategory.id.toString(),
        },
      },
    };
  }

  private get backItem(): any | null { // TODO add interface
    if (!this.categoryId) {
      return null;
    }

    const route: any = this.parentCategory
      ? { name: 'products.category', params: { id: this.parentCategory.id.toString() } }
      : { name: 'products' };

    const title: string = this.parentCategory
      ? `Cofnij do: ${this.parentCategory.name}`
      : 'Wszystkie produkty';

    return {
      icon: 'chevron_left',
      title,
      route,
      class: 'dimmed',
    };
  }

  private get categoryId(): number | null {
    return this.$route.params.id
      ? Number(this.$route.params.id)
      : null;
  }

  private get listCategories(): CategoryCoreModel[] {
    return this.categories.items;
  }

  /**
   * Display getters
   */
  private get displayList(): boolean {
    return !this.displayLoader;
  }

  private get displayLoader(): boolean {
    return this.loading && this.showLoader;
  }

  private get displayCurrentItem(): boolean {
    return !!this.currentItem;
  }

  private get displayBackItem(): boolean {
    return !!this.backItem;
  }

  /**
   * Lifecycle hooks
   */
  private created() {
    this.getData();
  }

  /**
   * Methods
   */
  private async getData() {
    this.loading = true;

    try {
      let parentCategory: CategoryCoreModel | null = null;

      if (this.categoryId) {
        // firstly get current category by url id param
        this.currentCategory = await this.getCategory(this.categoryId);

        if (this.currentCategory) {
          // then check if has children
          if (this.currentCategory.hasChildren) {
            // if has, load them
            this.showLoader = true;
            this.categories = await this.getCategories(this.currentCategory.id);
          } else { // if not check if there are still categories loaded from parent
            if (!this.categories.count) {
              this.showLoader = true;
              this.categories = await this.getCategories(this.currentCategory.parentId as number);
            }
          }

          // get parent if current category has any
          if (this.currentCategory.parentId) {
            parentCategory = await this.getCategory(this.currentCategory.parentId);

            // get parent higher if current category has no children
            if (!this.currentCategory.hasChildren && parentCategory) {
              // load if parent category has parent
              if (parentCategory.parentId) {
                // parentCategory = await this.getCategory(parentCategory.parentId);
              } else { // reset if has not
                // parentCategory = null;
              }
            }
          } else {
            parentCategory = null;
          }
        }

        this.parentCategory = parentCategory;
      } else {
        this.currentCategory = null;
        this.categories = await this.getCategories();
      }

      scrollToTop();
    } catch (ex) {
      // TODO
    }

    this.loading = false;
    this.showLoader = false;
  }

  private async getCategory(id: number): Promise<CategoryCoreModel | null> {
    return CategoryCoreModel
      .apiExt()
      .param('id', id)
      .with('children')
      .with('parent')
      .get();
  }

  private async getCategories(parentId: number | string = 'none'): Promise<ORMCollection> {
    return CategoryCoreModel
      .apiExt()
      .where('parentId', parentId)
      .where('online', true)
      .with('parent')
      .sort({ field: 'name', desc: 'asc' })
      .fetch();
  }

  private categoryName(category: CategoryCoreModel): string {
    return category.shortName || category.name;
  }

  private categoryRoute(category: CategoryCoreModel): any {
    const id: number = category.isRedirectType
      ? category.redirectCategoryId!
      : category.id;

    return {
      name: 'products.category',
      params: {
        id,
      },
    };
  }

  /**
   * Watchers
   */
  @Watch('categoryId')
  private watchCategoryIdChange(categoryId: number | null) {
    this.getData();
  }
}
