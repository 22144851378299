import ServiceProvider from '@/core/providers/ServiceProvider';

export default class ServiceProviderManager {
  /**
   * @info    Service Provider managing class
   *
   * @author  Lukas Laskowski & Karol Chojnacki 11.05.2019
   */

  public static register(provider: ServiceProvider) {
    this.providers.push(provider);
  }

  /**
   * Initialize
   */
  public static async initialize() {
    await this.initProviders(this.providers, this.initProvider, null);
  }

  /**
   * Initialize with changed param
   * @param param
   */
  public static async reinitialize(param: string) {
    await this.initProviders(this.providers, this.initProvider, param);
  }

  private static providers: ServiceProvider[] = [];

  /**
   * Set queue for providers initialize
   * @param providers
   * @param callback
   * @param param
   */
  private static async initProviders(
    providers: ServiceProvider[],
    callback: (provider: ServiceProvider, index: number, param: string | null) => {},
    param: string | null = null,
  ) {
    for (let index = 0; index < providers.length; index++) {
      await callback(providers[index], index, param);
    }
  }

  /**
   * Queue callback for provider initialize
   * @param provider
   * @param index
   * @param param
   */
  private static async initProvider(provider: ServiceProvider, index: number, param: string | null) {
    if (provider && provider.checkInitialize()) {
      await new Promise(async (resolve) => {
        provider.lock();
        if (provider.isReturning) {
          await provider.clear();
        }
        if (!provider.isBootstrap) {
          await provider.prepare();
        }

        await provider.init(param);
        provider.unlock();

        if (provider.isDisposable) {
          ServiceProviderManager.unregister(index);
        }

        resolve();
      });
    }
  }

  /**
   * Unregister provider
   * @param index
   */
  private static unregister(index: number) {
    delete this.providers[index];
  }
}
