import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import ApiError from '@/core/api/errors/ApiError';
import ApiSettings from '@/core/api/settings/ApiSettings';
import ApiORMQueryBuilderExtended from '@/shared/lib/api/query-builders/ApiORMQueryBuilderExtended';
import { ApiRequestConfigContract } from '@/core/api/support/ApiRequestConfigContract';

export default class ApiORMQueryBuilderV1Extended extends ApiORMQueryBuilderExtended {
  /**
   * Prepare axios response
   */
  protected prepareAxiosResponse(axiosInstance: AxiosInstance, requestData?: ApiRequestConfigContract) {
    // reset all existing axios interceptors
    (axiosInstance.interceptors.response as any).handlers = [];

    axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        return this.getSchema().parseResponse(response);
      },
      async (error: AxiosError) => {
        const apiError: ApiError = new ApiError(this.getSchema().parseErrorResponse(error));
        if (apiError.isUnauthorized()) {
          try {
            return this.login(error.config);
          } catch (ex) {
            throw new ApiError(this.getSchema().parseErrorResponse(ex));
          }
        } else {
          throw apiError;
        }
      },
    );
  }

  /**
   * Login to api
   * @param config
   */
  private async login(config: AxiosRequestConfig) {
    const result: any = await axios.post(ApiSettings.apiURL + '/login', LOGIN_DATA); // TODO create ApiAdapter
    ApiSettings.token = `Bearer ${result.data.token}`;

    try {
      config.headers.Authorization = ApiSettings.token;
      const response = await axios.request(config); // TODO create ApiAdapter

      return this.getSchema().parseResponse(response);
    } catch (ex) {
      throw new ApiError(this.getSchema().parseErrorResponse(ex));
    }
  }
}

const LOGIN_DATA: any = {
  code: process.env.VUE_APP_API_V1_CODE,
  password: process.env.VUE_APP_API_V1_PASSWORD,
};
