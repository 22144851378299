import ServiceProviderManager from '@/core/providers/ServiceProviderManager';
import ServiceProvider from '@/core/providers/ServiceProvider';

export default class Bootstrap {
  /**
   * Initialize App
   * @param providers
   */
  public static init(providers: ServiceProvider[]) {
    this.runServiceProviders(providers);
  }

  /**
   * Register and init app service providers
   * @param providers
   */
  private static runServiceProviders(providers: ServiceProvider[]) {
    providers.forEach((provider: ServiceProvider) => {
      ServiceProviderManager.register(provider);
    });
    ServiceProviderManager.initialize();
  }
}
