import {
  PaginationSeparatedInterface,
  PaginationSortInterface,
  PaginationObjectInterface,
} from '@/core/support/pagination/PaginationInterface';

export default class Pagination {
  /**
   * Private fields
   */
  private page: number | null = null;
  private limit: number | null = null;
  private sort: PaginationSortInterface[] = [];
  private total: number | null = null;

  /**
   * Constructor
   * @param page
   * @param limit
   * @param sort
   * @param total
   */
  constructor(
    page: number | null = null,
    limit: number | null = null,
    sort: PaginationSortInterface[] | null = null,
    total: number | null = null,
  ) {
    if (page !== null) {
      this.page = page;
    }
    if (limit !== null) {
      this.limit = limit;
    }
    if (sort !== null) {
      this.setSortArray(sort);
    }
    if (total !== null) {
      this.total = total;
    }
  }

  /**
   * Get current limit
   */
  public get currentLimit() {
    if (this.getLimit() >= this.getTotal()) {
      return this.getTotal();
    }

    const result: number = this.getLimit() * this.getPage();
    return result < this.getTotal() ? result : this.getTotal();
  }

  /**
   * Get offset
   */
  public get offset() {
    return (this.getPage() - 1) * this.getLimit();
  }

  /**
   * Synchronize data from pagination object
   * @param page
   * @param limit
   * @param sort
   */
  public sync(page: number | null, limit: number | null, sort: PaginationSortInterface[] | null) {
    if (page) {
      this.setPage(page);
    }

    if (limit) {
      this.setLimit(limit);
    }

    if (sort) {
      this.setSortArray(sort);
    }
  }

  /**
   * Get object data
   */
  public toObject(): PaginationObjectInterface {
    return {
      page: this.page,
      limit: this.limit,
      sort: this.sort,
    };
  }

  /**
   * Set page
   * @param page
   */
  public setPage(page: number) {
    this.page = page;
  }

  /**
   * Get page
   * @param defaultval
   */
  public getPage(defaultval: number | null = null): number {
    if (this.page !== null) {
      return this.page;
    } else if (defaultval) {
      return defaultval;
    }
    return 1;
  }

  /**
   * Check if has page
   */
  public hasPage(): boolean {
    return this.page !== null;
  }

  /**
   * Set limit
   * @param limit
   */
  public setLimit(limit: number) {
    this.limit = limit;
  }

  /**
   * Get limit
   * @param defaultval
   */
  public getLimit(defaultval: number | null = null): number {
    if (this.limit !== null) {
      return this.limit;
    } else if (defaultval) {
      return defaultval;
    }
    return 15;
  }

  /**
   * Check if has limit
   */
  public hasLimit(): boolean {
    return this.limit !== null;
  }

  /**
   * Add sort field or overwrite exists
   * @param field
   * @param desc
   * @param overwrite
   */
  public setSort(field: string, desc: string, overwrite: boolean = false) {
    const sort: PaginationSortInterface = { field, desc };

    if (overwrite) {
      this.sort = [sort];
    } else {
      const index: number = (this.sort as any[]).indexOf((sortItem: PaginationSortInterface) => {
        return sortItem.field === field;
      });

      if (index === -1) {
        this.sort.push(sort);
      } else {
        this.sort[index] = sort;
      }
    }
  }

  /**
   * Add sorts from sort contract
   * @param sorts
   */
  public setSortArray(sorts: PaginationSortInterface[]) {
    this.sort = sorts;

    // this.sort = [];
    //
    // sorts.forEach((sort: PaginationSortInterface) => {
    //   this.setSort(sort.field, sort.desc);
    // });
  }

  /**
   * Return sort fields
   * @param defaultSort
   */
  public getSort(defaultSort: PaginationSortInterface | null = null): PaginationSortInterface[] | null {
    if (this.sort.length > 0) {
      return this.sort;
    } else if (defaultSort) {
      return [defaultSort];
    }

    return null;
  }

  /**
   * Return first sort value
   * @param defaultSort
   */
  public getFirstSort(defaultSort: PaginationSortInterface | null = null): PaginationSortInterface | null {
    const sort = this.getSort(defaultSort);
    return sort ? sort[0] : null;
  }

  /**
   * Get separated sorts ex: { fields: ['id', 'price'], descs: ['desc', 'asc'] }
   * @param defaultSort
   */
  public getSortSeparated(defaultSort: PaginationSortInterface | null = null): PaginationSeparatedInterface {
    const separated: PaginationSeparatedInterface = {
      fields: [],
      descs: [],
    };

    const sorts: PaginationSortInterface[] | null = this.getSort(defaultSort);

    if (!sorts) {
      return separated;
    }

    sorts.map((sort: PaginationSortInterface) => {
      separated.fields.push(sort.field);
      separated.descs.push(sort.desc ? 'desc' : 'asc');
    });

    return separated;
  }

  /**
   * Get joined sorts ex: ['id,desc', 'price,asc']
   * @param defaultSort
   */
  public getSortJoined(defaultSort: PaginationSortInterface | null = null): string[] {
    const sorts: PaginationSortInterface[] | null = this.getSort(defaultSort);
    if (!sorts) {
      return [];
    }
    return sorts.map((sort: PaginationSortInterface) => {
      return sort.field + ',' + (sort.desc === 'desc' ? 'desc' : 'asc');
    });
  }

  /**
   * Check if has any sort fields
   */
  public hasSort(): boolean {
    return (this.sort.length > 0);
  }

  /**
   * Set total
   * @param total
   */
  public setTotal(total: number) {
    this.total = total;
  }

  /**
   * Get total
   * @param defaultval
   */
  public getTotal(defaultval: number | null = null): number {
    if (this.total !== null) {
      return this.total;
    } else if (defaultval !== null) {
      return defaultval;
    }
    return 0;
  }

  /**
   * Check if has total
   */
  public hasTotal(): boolean {
    return this.total !== null;
  }

  /**
   * Get request data
   */
  public getRequestData() {
    const params: { [key: string]: any } = {};

    if (this.page !== null) {
      params.page = this.page;
    }
    if (this.limit !== null) {
      params.limit = this.limit;
    }
    if (this.sort.length > 0) {
      params.sort = this.getSortJoined();
    }

    return params;
  }
}
