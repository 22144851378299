import ORMModel from '@/core/bridge/orm/ORMModel';
import ApiORMQueryBuilderExtended from '@/shared/lib/api/query-builders/ApiORMQueryBuilderExtended';
import ApiORMQueryBuilderV1Extended from '@/shared/lib/api/query-builders/ApiORMQueryBuilderV1Extended';

export default class ORMModelExtended extends ORMModel {
  /**
   * Init api query model
   */
  public static apiExt(): ApiORMQueryBuilderExtended {
    return new ApiORMQueryBuilderExtended(this);
  }

  /**
   * Init api query model
   */
  public static apiV1Ext(): ApiORMQueryBuilderV1Extended {
    return new ApiORMQueryBuilderV1Extended(this);
  }
}
