import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';

export default class ProductTagCoreModel extends ORMModelExtended {
  /**
   * ORM entity
   */
  public static entity = 'product-tags';

  /**
   * Primary key
   */
  public static primaryKey = ['product_id', 'attribute_id']; // TODO rename

  /**
   * ORM fields
   */
  public static fields() {
    return {
      product_id: this.attr(null),
      attribute_id: this.attr(null), // TODO rename
    };
  }
}
