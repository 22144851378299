

























import { Vue, Component, Prop } from 'vue-property-decorator';

import Icon from '@/shared/resources/components/Icon.vue';

@Component({
  components: {
    Icon,
  },
})
export default class Button extends Vue {
  /**
   * Props
   */
  @Prop({ default: () => {/**/} }) private on?: object;
  @Prop() private appendIcon?: string;
  @Prop() private prependIcon?: string;
  @Prop({ default: false, type: Boolean }) private noMargin!: string;
  @Prop({ default: false, type: Boolean }) private noShadow!: string;
  @Prop({ default: false, type: Boolean }) private square!: string;
  @Prop({ default: false, type: Boolean }) private mobileIconOnly!: string;

  /**
   * Display getters
   */
  private get displayAppendIcon(): boolean {
    return !!this.appendIcon;
  }

  private get displayPrependIcon(): boolean {
    return !!this.prependIcon;
  }

  /**
   * Class names
   */
  private get classNames(): object {
    return {
      'custom-button': true,
      'custom-button__square': this.square,
      'ma-1': !this.noMargin,
      'custom-button__no-shadow': this.noShadow,
      'custom-button__mobile-icon-only': this.mobileIconOnly,
    };
  }

  /**
   * Handle click event
   */
  private onClick(e: Event) {
    this.$emit('click', e);
  }
}
