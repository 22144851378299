/**
 * Locales config
 * Good start to make global i18n service
 */
const I18N_LOCALES_CONFIG: any = {
  pl: {
    name: 'POLISH',
    decimalSeparator: ',',
  },

  en: {
    name: 'ENGLISH',
    decimalSeparator: '.',
  },
};

/**
 * Export
 */
export {
  I18N_LOCALES_CONFIG,
};
