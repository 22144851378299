import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';
import ContractorCoreModel from '@/modules/contractors/models/ContractorCoreModel';
import { ApiORMModelRelationsFieldsContract } from '@/core/bridge/orm/api/relations/contracts/ApiORMModelRelationsFieldsContract';
import { ApiRelationModelsEnum } from '@/shared/lib/api/relations/ApiRelationModelsEnum';

export default class UserCoreModel extends ORMModelExtended {
  public static entity = 'users';

  public static apiConfig = {
    actions: {
      get: {
        method: 'GET',
        url: '/user',
      },
    },
  };

  public static relationFields: ApiORMModelRelationsFieldsContract = {
    [ApiRelationModelsEnum.CONTRACTOR]: 'contractor',
  };

  public static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
      email: this.string(''),

      contractorId: this.number(null).nullable(),
      contractor: this.belongsTo(ContractorCoreModel, 'contractorId'),

      createdAt: this.string(null).nullable(),
      updatedAt: this.string(null).nullable(),
      emailVerifiedAt: this.string(null).nullable(),
      verifiedAt: this.string(null).nullable(),
    };
  }

  /**
   * Public fields
   */
  public id!: number;
  public name!: string;
  public email!: string;

  public contractorId!: number | null;
  public contractor!: ContractorCoreModel | null;

  public createdAt!: string | null;
  public updatedAt!: string | null;
  public emailVerifiedAt!: string | null;
  public verifiedAt!: string | null;

  /**
   * Check if user is admin
   */
  public isAdmin(): boolean {
    return true; // TODO
  }

  /**
   * Check if user has verified email
   */
  public hasVerifiedEmail(): boolean {
    return !!this.emailVerifiedAt;
  }

  /**
   * Check if user is verified
   */
  public isVerified(): boolean {
    return !!this.verifiedAt;
  }

  /**
   * Check if user has permission
   * @param permissionName
   */
  // public hasPermission(permissionName: string): boolean {
    // return !!this.permissions.find((permission: UserV1PermissionModel) => permission.name === permissionName);
  // }
}
