import { fullAddress, fullAddressCity, fullAddressStreet } from '@/core/helpers/utils/AddressUtils';
import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';

export default class DocumentDataCoreModel extends ORMModelExtended {
  public static entity = 'document-data';

  public static apiConfig = {
    actions: {
      fetch: {
        method: 'GET',
        url: '/v2/documents/data',
      },
    },
  };

  public static fields() {
    return {
      id: this.number(0).nullable(),
      name: this.string('').nullable(),
      address: this.string('').nullable(),
      building: this.string('').nullable(),
      locale: this.string('').nullable(),
      zipCode: this.string('').nullable(),
      place: this.string('').nullable(),
      country: this.string('').nullable(),
      nip: this.string('').nullable(),
    };
  }

  public id!: number;
  public name!: string;
  public address!: string;
  public building!: string;
  public locale!: string;
  public zipCode!: string;
  public place!: string;
  public country!: string;
  public nip!: string;

  /**
   * Get full address street
   */
  public get fullAddressStreet(): string {
    return fullAddressStreet(this.address, this.building, this.locale);
  }

  /**
   * Get full address city
   */
  public get fullAddressCity(): string {
    return fullAddressCity(this.place, this.zipCode);
  }

  /**
   * Get full address
   */
  public get fullAddress(): string {
    return fullAddress(
      this.address,
      this.building,
      this.locale,
      this.place,
      this.zipCode,
    );
  }
}
