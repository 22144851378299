


















import { Component, Vue } from 'vue-property-decorator';

import GridContainer from '@/shared/resources/components/grid/GridContainer.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import SideModals from '@/shared/resources/layouts/SideModals.vue';

@Component({
  components: {
    SideModals,
    GridCol,
    GridContainer,
    GridRow,
  },
})
export default class AuthLayout extends Vue {
}
