























import { Vue, Component, Prop } from 'vue-property-decorator';

import Icon from '@/shared/resources/components/Icon.vue';
import NavigationItem from '@/shared/resources/layouts/navigation/components/NavigationItem.vue';

@Component({
  components: {
    NavigationItem,
    Icon,
  },
})
export default class NavigationGroup extends Vue {
  /**
   * Props
   */
  @Prop() private item!: any; // TODO add interface

  /**
   * Data
   */
  private get open(): boolean {

    return this.item.children.some((child: any) => { // TODO add contract
        return child.routeName === this.$route.name;
    });
  }
}
