import {
  ApiORMRelationResponseContract,
  ApiORMRelationsResponseContract,
  ApiORMRelationsSchemaContract,
} from '@/core/bridge/orm/api/relations/contracts/ApiORMRelationsResponseContract';
import ApiORMRelationsQueryBuilder from '@/core/bridge/orm/api/relations/ApiORMRelationsQueryBuilder';
import ApiORMQueryRelationHandler from '@/core/bridge/orm/api/relations/ApiORMQueryRelationHandler';
import IndexableInterface from '@/core/interfaces/IndexableInterface';

export default class ApiORMRelationsMapper {
  /**
   * Relations list
   */
  private relationsSchema: ApiORMRelationsSchemaContract = {};

  /**
   * Relations list
   */
  private promises: Array<Promise<any>> = [];

  /**
   * Query builder with data
   */
  private queryBuilder!: ApiORMRelationsQueryBuilder;

  /**
   * Remap relations and insert to the Store
   * @param relations
   * @param queryBuilder
   * @param relationsSchema
   */
  public async build(
      relations: ApiORMRelationsResponseContract,
      queryBuilder: ApiORMRelationsQueryBuilder,
      relationsSchema: ApiORMRelationsSchemaContract,
  ) {
    this.relationsSchema = relationsSchema;
    this.queryBuilder = queryBuilder;
    await this.collectRelations(relations);

    return Promise.all(this.promises);
  }

  /**
   * Collect all relations recursively as promises
   * @param relations
   */
  private async collectRelations(relations: ApiORMRelationsResponseContract) {
    Object.keys(relations).map(async (modelName: string) => {
      if (this.relationsSchema[modelName]) {
        const models: ApiORMRelationResponseContract[] = relations[modelName];
        await this.promises.push(this.insertIntoStore(modelName, models));
      }
    });
  }

  /**
   * Insert entities to the Store
   * @param modelName
   * @param items
   */
  private async insertIntoStore(modelName: string, items: ApiORMRelationResponseContract[]) {
    if (!this.queryBuilder.empty()) {
      const fields: string[] = this.findQueryFields(modelName);
      if (fields.length) {
        // eslint-disable-next-line no-param-reassign
        items = items.map((item: ApiORMRelationResponseContract) => this.filterFields(item, fields));
      }
    }
    return this.relationsSchema[modelName].insertOrUpdate({ data: items });
  }

  /**
   * Find and return fields from query builder for relation
   * @param name
   */
  private findQueryFields(name: string): string[] {
    const relations: ApiORMQueryRelationHandler[] = this.queryBuilder.getRelations();
    if (!relations || !relations.length) {
      return [];
    }
    const relation: ApiORMQueryRelationHandler | undefined = relations.find(
        (queryRelation: ApiORMQueryRelationHandler) => queryRelation.name === name.toLowerCase());
    return relation && relation.fields ? relation.fields : [];
  }

  /**
   * Filter object fields
   * @param item
   * @param fields
   */
  private filterFields(item: IndexableInterface, fields: string[]): IndexableInterface {
    const data: IndexableInterface = {};
    fields.forEach((field: string) => {
      data[field] = item[field];
    });
    return data;
  }
}
