import VueI18n from 'vue-i18n';
import { Validation as VueValidation } from 'vuelidate';
import { ValidationCustomMessageContract } from '@/shared/lib/support/validation/ValidationContracts';

const REQUIRED_RULE = 'required';

export default class Validation {
  public static CUSTOM_MESSAGES_PARAM = 'customMessages';

  /**
   * Load needed data/services
   * @param i18n
   */
  public static load(i18n: VueI18n) {
    this.i18n = i18n;
  }

  /**
   * Translate and return invalid rules
   * @param validation
   */
  public static translateInvalid(validation: any): string[] { // TODO contract
    this.validation = validation;

    // get rules
    const invalidRules: string[] = Object.keys(validation)
      .filter((param: string) => !param.startsWith('$'))
      .filter((param: string) => !validation[param]);

    const hasRequiredError: boolean = invalidRules.some((rule: string) => rule === REQUIRED_RULE);
    if (hasRequiredError) {
      return [this.translateRule(REQUIRED_RULE)];
    }

    // return translated rules
    return invalidRules.map((rule: string) => this.translateRule(rule));
  }

  /**
   * i18n model
   */
  private static i18n: VueI18n;
  private static validation: any; // TODO contract

  /**
   * Translate rule
   * @param ruleName
   */
  private static translateRule(ruleName: string): string {
    return this.getCustomMessage(ruleName) ||
      this.i18n.t('validation.' + ruleName, this.validation.$params[ruleName]).toString().toString();
  }

  /**
   * Get rule custom message
   */
  private static getCustomMessage(ruleName: string): string | null {
    return this.getCustomMessages()[ruleName];
  }

  /**
   * Get rule custom message
   */
  private static getCustomMessages(): ValidationCustomMessageContract {
    if (!this.hasCustomMessages()) {
      return {};
    }

    return this.validation.$params.withParams.messages;
  }

  /**
   * Check if validation has custom message
   */
  private static hasCustomMessages(): boolean {
    if (
      !this.hasCustomParams() || !this.validation.$params) {
      return false;
    }

    return this.validation.$params.withParams.type === this.CUSTOM_MESSAGES_PARAM;
  }

  /**
   * Check if validation has custom params
   */
  private static hasCustomParams(): boolean {
    return !!this.validation.withParams;
  }
}
