import { fullAddress, fullAddressCity, fullAddressStreet } from '@/core/helpers/utils/AddressUtils';
import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';
import { PricesEnum } from '@/modules/prices/enums/PricesEnum';
import UserCoreModel from '@/modules/users/models/UserCoreModel';

export default class ContractorCoreModel extends ORMModelExtended {
  public static entity = 'contractors';

  public static apiConfig = {
    actions: {
      fetch: {
        method: 'GET',
        url: '/contractors',
      },
      get: {
        method: 'GET',
        url: '/contractors/:id',
      },
    },
  };

  public static fields() {
    return {
      id: this.number(null).nullable(),
      tradeName: this.string('').nullable(),

      contactName: this.string('').nullable(),
      contactEmail: this.string('').nullable(),
      contactPhone: this.string('').nullable(),

      invoiceName: this.string('').nullable(),
      invoiceAddress: this.string('').nullable(),
      invoiceBuilding: this.string('').nullable(),
      invoiceLocale: this.string('').nullable(),
      invoiceZipCode: this.string('').nullable(),
      invoicePlace: this.string('').nullable(),
      invoiceCountry: this.string('').nullable(),
      invoiceNip: this.string('').nullable(),

      defaultPrice: this.string(''),

      user: this.belongsTo(UserCoreModel, 'contractorId'),
    };
  }

  public id!: number;
  public tradeName!: string;

  public contactName!: string;
  public contactEmail!: string;
  public contactPhone!: string;

  public invoiceName!: string;
  public invoiceAddress!: string;
  public invoiceBuilding!: string;
  public invoiceLocale!: string | null;
  public invoiceZipCode!: string;
  public invoicePlace!: string;
  public invoiceCountry!: string;
  public invoiceNip!: string;

  public defaultPrice!: PricesEnum;

  public user!: UserCoreModel | null;

  /**
   * Get full invoice address street
   */
  public get invoiceFullAddressStreet(): string {
    return fullAddressStreet(this.invoiceAddress, this.invoiceBuilding, this.invoiceLocale);
  }

  /**
   * Get full invoice address city
   */
  public get invoiceFullAddressCity(): string {
    return fullAddressCity(this.invoicePlace, this.invoiceZipCode);
  }

  /**
   * Get full invoice address
   */
  public get invoiceFullAddress(): string {
    return fullAddress(
      this.invoiceAddress,
      this.invoiceBuilding,
      this.invoiceLocale,
      this.invoicePlace,
      this.invoiceZipCode,
    );
  }

  /**
   * Check if contractor has user
   */
  public hasUser(): boolean {
    return !!this.user;
  }

  /**
   * Check if contractor has verified user
   */
  public hasVerifiedUser(): boolean {
    if (!this.user) {
      return false;
    }

    return this.user.isVerified();
  }

  /**
   * Check if contractor has verified user email
   */
  public hasVerifiedUserEmail(): boolean {
    if (!this.user) {
      return false;
    }

    return this.user.hasVerifiedEmail();
  }
}
