import Record from '@vuex-orm/core/dist/src/data/Record';
import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';
import AmountsFormattedInterface from '@/shared/lib/interfaces/AmountsFormattedInterface';
import Locale from '@/core/locale/Locale';
import ProductCoreModel from '@/modules/products/models/ProductCoreModel';

export default class CartItemCoreModel extends ORMModelExtended {
  /**
   * ORM entity
   */
  public static entity = 'cart-items';

  /**
   * Primary key
   */
  // public static primaryKey: string[] = ['cartId', 'productId'];

  /**
   * Fields
   */
  public static fields() {
    return {
      id: this.string(''),
      cartId: this.string(''),
      name: this.string(''),
      thumbPath: this.string(''),

      productId: this.number(0),
      product: this.belongsTo(ProductCoreModel, 'productId'),

      quantity: this.number(0),
      unit: this.string(''),
      vatRate: this.number(0),

      price: this.number(0),
      priceNet: this.number(0),
      priceGross: this.number(0),
      priceVat: this.number(0),
    };
  }

  /**
   * Public fields
   */
  public id!: string;
  public cartId!: string;
  public name!: string;
  public thumbPath!: string;

  public productId!: number;
  public product!: ProductCoreModel;

  public quantity!: number;
  public unit!: string; // TODO enum
  public vatRate!: number; // TODO enum

  public price!: number;
  public priceNet!: number;
  public priceGross!: number;
  public priceVat!: number;

  [key: string]: any;

  /**
   * Constructor
   * @param record
   */
  constructor(record: Record) {
    super(record);

    // TODO set prices dependently of given contractor price
    this.priceNet = record.price;
    this.priceGross = record.price * this.vatIndex;
    this.priceVat = this.priceGross - record.price;
  }

  /**
   * Get total net
   */
  public get totalNet(): number {
    return this.priceNet * this.quantity;
  }

  /**
   * Get total gross
   */
  public get totalGross(): number {
    return this.totalNet * this.vatIndex;
  }

  /**
   * Get total vat
   */
  public get totalVat(): number {
    return this.totalGross - this.totalNet;
  }

  /**
   * Get vat index
   */
  public get vatIndex(): number {
    return 1 + (this.vatRate / 100);
  }

  /**
   * Get prices formatted with currency
   */
  public get pricesWithCurrency(): AmountsFormattedInterface {
    return {
      net: Locale.priceWithCurrency(this.priceNet),
      gross: Locale.priceWithCurrency(this.priceGross),
      vat: Locale.priceWithCurrency(this.priceVat),
    };
  }

  /**
   * Get totals formatted with currency
   */
  public get totalsWithCurrency(): AmountsFormattedInterface {
    return {
      net: Locale.priceWithCurrency(this.totalNet),
      gross: Locale.priceWithCurrency(this.totalGross),
      vat: Locale.priceWithCurrency(this.totalVat),
    };
  }

  /**
   * Get product item max quantity
   */
  public get maxQuantity(): number {
    if (this.product.isOnOrder) {
      return 0;
    }

    const maxAmount: number = this.product.amount -  this.quantity;
    return maxAmount > 0 ? maxAmount : 0;
  }

  /**
   * Convert to object
   */
  public toObject(): object {
    return {
      id: this.id,
      cartId: this.cartId,
      productId: this.productId,
      name: this.name,
      thumbPath: this.thumbPath,

      quantity: this.quantity,
      unit: this.unit,
      vatRate: this.vatRate,

      price: this.price,
      priceNet: this.priceNet,
      priceGross: this.priceGross,
      priceVat: this.priceVat,

      totalNet: this.totalNet,
      totalGross: this.totalGross,
      totalVat: this.totalVat,
    };
  }
}
