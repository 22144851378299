







































import { Vue, Component, Prop } from 'vue-property-decorator';

import NumberField from '@/shared/resources/components/forms/fields/NumberField.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import ProductCoreModel from '@/modules/products/models/ProductCoreModel';
import CartItemCoreModel from '@/modules/carts/models/CartItemCoreModel';
import Icon from '@/shared/resources/components/Icon.vue';
import Cart from '@/app/lib/cart/Cart';
import ProductQuantityField from '@/app/modules/products/components/partials/ProductQuantityField.vue';

@Component({
  components: {
    ProductQuantityField,
    Icon,
    Button,
    NumberField,
  },
})
export default class ProductItemAddToCartButton extends Vue {
  /**
   * Props
   */
  @Prop() private product!: ProductCoreModel;
  @Prop({ default: false, type: Boolean }) private advanced!: boolean;

  /**
   * Data
   */
  private quantity: number = 1;
  private successTimeoutTime: number = 3 * 1000;
  private errorTimeoutTime: number = 15 * 1000;
  private loading: boolean = false;
  private success: boolean = false;
  private error: boolean = false;
  private errorMessage: string | null = null;

  /**
   * Getters
   */
  private get cartItem(): CartItemCoreModel | null {
    return this.product.cartItem;
  }

  private get inputMessage(): string {
    if (!this.cartItem) {
      return '';
    }

    return `W koszyku: ${this.cartItem.quantity} ${this.product.unit}`;
  }

  private get buttonColor(): string {
    if (this.success) {
      return 'success';
    }

    if (this.error) {
      return 'error';
    }

    return 'primary';
  }

  private get buttonIcon(): string {
    if (this.success) {
      return 'done';
    }

    return 'add_shopping_cart';
  }

  private get textButtonLabel(): string {
    if (this.success) {
      return 'Dodano';
    }

    return 'Do koszyka';
  }

  private get disabled(): boolean {
    return this.product.maxCartItemAmount
      ? this.product.maxCartItemAmount <= 0
      : false;
  }

  private get disableQuantityField(): boolean {
    return this.loading || this.disabled;
  }

  private get disableButton(): boolean {
    return this.disabled && !this.success;
  }

  private get hasCartItem(): boolean {
    return !!this.cartItem;
  }

  /**
   * Display getters
   */
  private get displayIconButton(): boolean {
    return !this.advanced;
  }

  private get displayTextButton(): boolean {
    return this.advanced;
  }

  /**
   * Class names
   */
  private get classNames(): object {
    return {
      'add-input-button d-flex': true,
      'advanced': this.advanced,
      'has-cart-item': this.hasCartItem,
    };
  }

  /**
   * Methods
   */
  private async addToCart() {
    if (this.success) {
      return;
    }

    this.loading = true;

    this.error = false;
    this.errorMessage = null;

    try {
      await Cart.addItem(this.product, this.quantity);

      this.success = true;

      setTimeout(() => {
        this.success = false;
        this.quantity = 1;
        this.$emit('cart:added');
      }, this.successTimeoutTime);
    } catch (ex) {
      this.error = true;
      this.errorMessage = ex.message || 'Wystąpił nieznany błąd';
    }

    this.loading = false;
  }

  /**
   * Handlers
   */
  private onQuantityFieldInput(value: number) {
    this.quantity = value;
  }

  private onQuantityFieldEnterPress() {
    this.addToCart();
  }

  private onButtonClick() {
    this.addToCart();
  }
}
