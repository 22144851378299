














import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Tooltip extends Vue {
  /**
   * Props
   */
  @Prop({ default: 'secondary' }) private color!: string;
}
