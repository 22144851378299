import SettingsContract from '@/core/settings/contracts/SettingsContract';
import MemorySettingsStorageAdapter from '@/core/settings/adapters/MemorySettingsStorageAdapter';
import StoreSettingsStorageModel from '@/core/settings/models/StoreSettingsStorageModel';

export default class StoreSettingsStorageAdapter extends MemorySettingsStorageAdapter {
  /**
   * Synchronize exists params with adapter
   * @param params
   * @param objects
   */
  public sync(params: SettingsContract, objects: SettingsContract) {
    Object.keys(params).forEach((name: string) => {
      this.setParam(name, params[name]);
    });

    super.sync({}, objects);
  }

  /**
   * Set param
   * @param name
   * @param value
   */
  public setParam(name: string, value: string | string[] | number | number[] | boolean) {
    StoreSettingsStorageModel.insertOrUpdate({ data: [{ name: this.getPrefixed(name), value }] });
  }

  /**
   * Get param
   */
  public getParam(name: string): any {
    const s = StoreSettingsStorageModel.find(this.getPrefixed(name));
    return s ? s.value : undefined;
  }

  /**
   * Check if param exists
   * @param name
   */
  public hasParam(name: string): boolean {
    return this.getParam(name) !== undefined;
  }

  /**
   * Get all params
   */
  public getParams(): SettingsContract {
    const result: SettingsContract = [];
    StoreSettingsStorageModel.query()
      .where((setting: StoreSettingsStorageModel) => {
        return !this.prefix || setting.name.includes(this.prefix);
      })
      .get()
      .forEach((item: StoreSettingsStorageModel) => {
        result[this.getUnPrefixed(item.name)] = item.value;
      });
    return result;
  }

  /**
   * Remove param
   */
  public removeParam(name: string) {
    StoreSettingsStorageModel.delete(this.getPrefixed(name));
  }

  /**
   * Clear all collected data
   */
  public clear() {
    super.clear();

    StoreSettingsStorageModel.delete((setting) => !!this.prefix && setting.name.includes(this.prefix));
  }
}
