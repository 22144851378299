















import { Component, Vue, Prop } from 'vue-property-decorator';
import IndexableInterface from '@/core/interfaces/IndexableInterface';

import NavigationItem from '@/shared/resources/layouts/navigation/components/NavigationItem.vue';
import NavigationGroup from '@/shared/resources/layouts/navigation/components/NavigationGroup.vue';
import NavigationHeader from '@/shared/resources/layouts/navigation/components/NavigationHeader.vue';
import NavigationDivider from '@/shared/resources/layouts/navigation/components/NavigationDivider.vue';
import NavigationSpacer from '@/shared/resources/layouts/navigation/components/NavigationSpacer.vue';

@Component({
  components: {
    NavigationItem,
    NavigationGroup,
    NavigationHeader,
    NavigationDivider,
    NavigationSpacer,
  },
})
export default class NavigationContent extends Vue {
  /**
   * Props
   */
  @Prop() private items!: IndexableInterface;

  /**
   * Methods
   */
  private itemComponent(item: any): string { // TODO add interface
    if (item.divider) {
      return 'NavigationDivider';
    }

    if (item.spacer) {
      return 'NavigationSpacer';
    }

    if (item.header) {
      return 'NavigationHeader';
    }

    if (item.children) {
      return 'NavigationGroup';
    }

    return 'NavigationItem';
  }

  private displayItem(item: any): boolean { // TODO add interface
    if (!item.breakpoint) {
      return true;
    }

    return (this.$vuetify.breakpoint as IndexableInterface)[item.breakpoint];
  }
}
