import {I18N_LOCALES_CONFIG} from '@/core/support/i18n/i18nConfig';

/**
 * Adjust decimal separator to given locale
 * @param decimal
 * @param locale
 */
export const adjustDecimalSeparator = (decimal: number, locale: string): string => {
  const localeConfig: any | undefined = I18N_LOCALES_CONFIG[locale];

  if (!localeConfig) {
    return decimal.toString();
  }

  return decimal.toString().toString().replace('.', localeConfig.decimalSeparator);
};

/**
 * Format amount
 * @param amount
 */
export const formatAmount = (amount: number): string => {
  return amount.toFixed(2);
};

/**
 * Check if value is number
 * @param value
 */
export const isNumber = (value: any) => {
  return !isNaN(Number(value.toString()));
};
