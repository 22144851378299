import axios, { AxiosRequestConfig } from 'axios';
import ApiQueryBuilder from '@/core/api/ApiQueryBuilder';

declare type RequestType = 'get' | 'post' | 'patch' | 'delete' | 'put';

export default class Api extends ApiQueryBuilder {
  /**
   * Send GET request
   * @param endpoint
   * @param config
   */
  public async get(endpoint: string, config: AxiosRequestConfig = {}): Promise<any> {
    return this.request('get', endpoint, config);
  }
  /**
   * Send POST request
   * @param endpoint
   * @param config
   */
  public async post(endpoint: string, config: AxiosRequestConfig = {}): Promise<any> {
    return this.request('post', endpoint, config);
  }
  /**
   * Send PATCH request
   * @param endpoint
   * @param config
   */
  public async patch(endpoint: string, config: AxiosRequestConfig = {}): Promise<any> {
    return this.request('patch', endpoint, config);
  }
  /**
   * Send PUT request
   * @param endpoint
   * @param config
   */
  public async put(endpoint: string, config: AxiosRequestConfig = {}): Promise<any> {
    return this.request('put', endpoint, config);
  }
  /**
   * Send DELETE request
   * @param endpoint
   * @param config
   */
  public async delete(endpoint: string, config: AxiosRequestConfig = {}): Promise<any> {
    return this.request('delete', endpoint, config);
  }
  /**
   * Send request
   * @param type
   * @param endpoint
   * @param config
   */
  private async request(
    type: RequestType,
    endpoint: string,
    config: AxiosRequestConfig = {},
  ) {
    const axiosInstance = axios.create();

    const requestConfig: AxiosRequestConfig = {
      ...this.axiosRequestConfig,
      ...config,
      url: this.prepareRequestUrl(endpoint),
      data: this.getData(),
      method: type,
    };

    await this.prepareAxiosResponse(axiosInstance, {
      url: requestConfig.url!,
      method: requestConfig.method!,
    });

    return axiosInstance.request(requestConfig);
  }
}
