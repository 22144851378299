import IndexableInterface from '@/core/interfaces/IndexableInterface';

export default abstract class LangSchema {
  public abstract name: string;
  public abstract symbol: string;
  public abstract region: string;
  public abstract currency: string;

  public specialChars: IndexableInterface = {};

  public abstract variety(
    value: number | null,
    singularNominativ: string,
    pluralNominativ: string,
    pluralGenitive: string,
  ): string;
}
