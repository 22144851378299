import { PaymentsTypeInterface } from '@/modules/payments/interfaces/PaymentsTypesInterface';
import { PaymentInterface } from '@/modules/payments/interfaces/PaymentsInterface';
import { PaymentsTypesEnum } from '@/modules/payments/enums/PaymentsTypesEnum';
import { DeliveryDiscountInterface, DeliveryInterface, DeliveryOptionInterface } from '@/modules/deliveries/interfaces/DeliveriesInterface';
import { paymentsTypes } from '@/modules/payments/paymentsTypes';
import { DeliveriesEnum } from '@/modules/deliveries/enums/DeliveriesEnum';

export default class Payments {
  /**
   * Load data
   * @param payments
   * @param deliveries
   */
  public static load(payments: PaymentInterface[], deliveries: DeliveryInterface[]) {
    this.payments = payments;
    this.deliveries = deliveries;

    this.paymentsTypes = this.preparePaymentsTypes();
  }

  /**
   * Get payments types
   */
  public static getPaymentsTypes(): PaymentsTypeInterface[] {
    return this.paymentsTypes;
  }

  /**
   * Get selectable payments list
   */
  public static getSelectablePaymentsList(): PaymentInterface[] {
    return this.payments.filter((payment: PaymentInterface) => {
      return payment.selectable;
    });
  }

  /**
   * Get payments types with deliveries prices calculated
   * @param totalValue
   */
  public static getPaymentsTypesWithPricesCalculated(totalValue: number): PaymentsTypeInterface[] {
    return this.paymentsTypes.map((paymentType: PaymentsTypeInterface) => {
      return {
        ...paymentType,
        deliveries: paymentType.deliveries && paymentType.deliveries.map((delivery: DeliveryInterface) => {
          return this.getDeliveryWithDiscountPrice(paymentType.key, delivery.key, totalValue);
        }),
      };
    });
  }

  /**
   * Get payment type by given key
   * @param key
   */
  public static getPaymentsType(key: PaymentsTypesEnum): PaymentsTypeInterface {
    return this.paymentsTypes.filter((paymentsType: PaymentsTypeInterface) => {
      return paymentsType.key === key;
    })[0];
  }

  /**
   * Get payment type by given key
   * @param key
   */
  public static getPaymentsTypeConfig(key: PaymentsTypesEnum): PaymentsTypeInterface {
    return paymentsTypes.filter((paymentsType: PaymentsTypeInterface) => {
      return paymentsType.key === key;
    })[0];
  }

  /**
   * Get delivery by key
   * @param paymentTypeKey
   * @param key
   */
  public static getDelivery(paymentTypeKey: PaymentsTypesEnum, key: DeliveriesEnum): DeliveryInterface {
    const paymentType: PaymentsTypeInterface = this.getPaymentsType(paymentTypeKey);
    return paymentType.deliveries!.filter((delivery: DeliveryInterface) => {
      return delivery.key === key;
    })[0] || null;
  }

  /**
   * Get delivery with discount price
   * @param paymentTypeKey
   * @param deliveryKey
   * @param totalValue
   */
  public static getDeliveryWithDiscountPrice(
    paymentTypeKey: PaymentsTypesEnum,
    deliveryKey: DeliveriesEnum,
    totalValue: number = 0,
  ): DeliveryInterface {
    const delivery: DeliveryInterface = this.getDelivery(paymentTypeKey, deliveryKey);

    return {
      ...delivery,
      price: this.getDeliveryPrice(paymentTypeKey, deliveryKey, totalValue),
    };
  }

  /**
   * Get delivery with discount
   * @param paymentTypeKey
   * @param deliveryKey
   * @param totalValue
   */
  public static getDeliveryPrice(
    paymentTypeKey: PaymentsTypesEnum,
    deliveryKey: DeliveriesEnum,
    totalValue: number,
  ): number {
    const delivery: DeliveryInterface = this.getDelivery(paymentTypeKey, deliveryKey);

    if (!delivery.discounts) {
      return delivery.price!;
    }

    const discounts: DeliveryDiscountInterface[] = delivery.discounts.filter((discount: DeliveryDiscountInterface) => {
      return totalValue >= discount.minValue;
    });

    return discounts.length
      ? discounts[discounts.length - 1].price
      : delivery.price!;
  }

  /**
   * Private fields
   */
  private static payments: PaymentInterface[] = [];
  private static paymentsTypes: PaymentsTypeInterface[] = [];
  private static deliveries: DeliveryInterface[] = [];

  /**
   * Prepare payments types
   * @private
   */
  private static preparePaymentsTypes(): PaymentsTypeInterface[] {
    // collect payment types
    const paymentsTypesList: PaymentsTypesEnum[] = this.deliveries.reduce((
      result: PaymentsTypesEnum[],
      delivery: DeliveryInterface,
    ) => {
      delivery.options!.forEach((deliveryOption: DeliveryOptionInterface) => {
        if (result.indexOf(deliveryOption.paymentType) === -1) {
          result.push(deliveryOption.paymentType);
        }
      });

      return result;
    }, []);

    return paymentsTypesList.map((paymentsType: PaymentsTypesEnum) => {
      const paymentsTypeDeliveries: DeliveryInterface[] = this.deliveries.reduce((
        deliveries: DeliveryInterface[],
        delivery: DeliveryInterface,
      ) => {
        const paymentTypeDeliveryOption: DeliveryOptionInterface = delivery.options!.filter((
          option: DeliveryOptionInterface,
        ) => option.paymentType === paymentsType)[0];

        deliveries.push({
          key: delivery.key,
          vatRate: delivery.vatRate,
          requireAddress: delivery.requireAddress,
          productId: delivery.productId,
          price: paymentTypeDeliveryOption.price,
          discounts: paymentTypeDeliveryOption.discounts,
        });

        return deliveries;
      }, []);

      return {
        ...this.getPaymentsTypeConfig(paymentsType),
        deliveries: paymentsTypeDeliveries,
      };
    });
  }
}
