




















import {Vue, Component, Prop} from 'vue-property-decorator';

import Icon from '@/shared/resources/components/Icon.vue';

@Component({
  components: {
    Icon,
  },
})
export default class NavigationItem extends Vue {
  /**
   * Props
   */
  @Prop() private item!: any; // TODO add interface

  /**
   * Display getters
   */
  private get displayIcon(): boolean {
    return !!this.item.icon;
  }

  /**
   * Class names
   */
  private get classNames(): object {
    return {
      'custom-list-item': true,
      [this.item.class]: true,
    };
  }
}
