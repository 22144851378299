




import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class NavigationDivider extends Vue {
  /**
   * Props
   */
  @Prop({default: false, type: Boolean}) private noMargin!: boolean;

  /**
   * Class names
   */
  private get classNames(): object {
    return {
      'mt-3': !this.noMargin,
      'mb-2': true,
    };
  }
}
