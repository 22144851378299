enum ApiRelationModelsEnum {
  CONTRACTOR = 'contractor',

  PRODUCT = 'product',
  PRODUCT_CATEGORY = 'category',

  CATEGORY = 'children', // TODO
  FILES = 'files',
  TAGS = 'tags',

  DOCUMENT_ITEMS = 'documentItems',
  DOCUMENT_DATA = 'documentData',

  ONLINE_ORDER_STATUS = 'statuses',
  ONLINE_ORDER_ITEMS = 'items',
  ONLINE_ORDER_ADDRESS_DATA = 'addressData',
}

export {ApiRelationModelsEnum};
