import SettingsContract from '@/core/settings/contracts/SettingsContract';
import SettingsStorageAdapter from '@/core/settings/SettingsStorageAdapter';

export default class MemorySettingsStorageAdapter extends SettingsStorageAdapter {
  private params: SettingsContract = {};
  private objects: SettingsContract = [];

  /**
   * Synchronize exists params with adapter
   * @param params
   * @param objects
   */
  public sync(params: SettingsContract, objects: SettingsContract) {
    this.params = params;
    this.objects = objects;
  }

  /**
   * Set param
   * @param param
   * @param value
   */
  public setParam(name: string, value: string | string[] | number | number[] | boolean) {
    this.params[name] = value;
  }

  /**
   * Get param
   */
  public getParam(name: string): any {
    return this.params[name] ? this.params[name] : undefined;
  }

  /**
   * Check if param exists
   * @param name
   */
  public hasParam(name: string): boolean {
    return this.getParam(name) !== undefined;
  }

  /**
   * Remove param
   */
  public removeParam(name: string) {
    if (this.params[name]) {
      delete this.params[name];
    }
  }

  /**
   * Get all params
   */
  public getParams(): SettingsContract {
    return this.params;
  }

  /**
   * Set object type
   * @param name
   * @param object
   */
  public setObject(name: string, object: any) {
    this.objects[name] = object;
  }

  /**
   * Get object type
   * @param name
   */
  public getObject(name: string): any {
    return this.objects[name] ? this.objects[name] : null;
  }

  /**
   * Check if object exists
   * @param name
   */
  public hasObject(name: string): boolean {
    return this.getObject(name) !== undefined;
  }

  /**
   * Remove object
   */
  public removeObject() {
    if (this.objects[name]) {
      delete this.objects[name];
    }
  }

  /**
   * Get all objects
   */
  public getObjects(): SettingsContract {
    return this.objects;
  }

  /**
   * Clear all collected data
   */
  public clear() {
    this.params = [];
    this.objects = [];
  }
}
