import LangSchema from '@/core/locale/LangSchema';

export default class PL extends LangSchema {
  /**
   * Data
   */
  public name: string = 'Polish';
  public symbol: string = 'pl';
  public region: string = 'pl-PL';
  public currency: string = 'PLN';

  /**
   * Get word variety
   * @param value
   * @param singularNominativ
   * @param pluralNominativ
   * @param pluralGenitive
   */
  public variety(
      value: number | null,
      singularNominativ: string,
      pluralNominativ: string,
      pluralGenitive: string,
  ): string {
    if (!value) {
      return pluralGenitive;
    }
    value = Math.abs(value);
    if (value === 1) {
      return singularNominativ;
    } else {
      return pluralGenitive;
    }
  }
}
