import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';
import { ApiORMModelRelationsFieldsContract } from '@/core/bridge/orm/api/relations/contracts/ApiORMModelRelationsFieldsContract';
import FileTypesEnum from '@/modules/files/enums/FileTypesEnum';

export default class FileCoreModel extends ORMModelExtended {
  /**
   * ORM entity
   */
  public static entity = 'files';

  /**
   * API method conf
   */
  public static methodConf = {
    http: {
      url: '/files',
    },
  };

  /**
   * Relation fields
   */
  public static relationFields: ApiORMModelRelationsFieldsContract = {};

  /**
   * ORM fields
   */
  public static fields() {
    return {
      id: this.number(0),

      name: this.string(''),
      originalName: this.string(''),

      ext: this.string(''),
      mime: this.string(''),
      module: this.string(''),
      path: this.string(''),
      sort: this.number(0),
      type: this.string(''),

      createdAt: this.string(''),
      updatedAt: this.string(''),
    };
  }

  /**
   * Public fields
   */
  public id!: number;
  public name!: string;
  public originalName!: string;
  public ext!: string;
  public mime!: string;
  public module!: string;
  public path!: string;
  public sort!: number;
  public type!: string;
  public createdAt!: string;
  public updatedAt!: string;

  /**
   * Check if file is image type
   */
  public get isImage(): boolean {
    return this.isType(FileTypesEnum.IMAGE);
  }

  /**
   * Check if file is document type
   */
  public get isDocument(): boolean {
    return this.isType(FileTypesEnum.DOCUMENT);
  }

  /**
   * Check if file is movie type
   */
  public get isMovie(): boolean {
    return this.isType(FileTypesEnum.MOVIE);
  }

  /**
   * Check if file is given type
   * @param type
   * @private
   */
  private isType(type: FileTypesEnum): boolean {
    return this.type === type;
  }
}
