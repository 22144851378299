






import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Divider extends Vue {
  /**
   * Props
   */
  @Prop({ default: false, type: Boolean }) private sm!: boolean;
  @Prop({ default: false, type: Boolean }) private transparent!: boolean;
  @Prop({ default: false, type: Boolean }) private thick!: boolean;

  /**
   * Get class names
   */
  private get classNames(): any {
    return {
      'divider--sm': this.sm,
      'divider--transparent': this.transparent,
      'divider--thick': this.thick,
    };
  }
}
