






























































































import { Vue, Component, Prop } from 'vue-property-decorator';

import Button from '@/shared/resources/components/buttons/Button.vue';
import Cart from '@/app/lib/cart/Cart';
import Badge from '@/shared/resources/components/Badge.vue';
import Icon from '@/shared/resources/components/Icon.vue';
import Card from '@/shared/resources/components/cards/Card.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import Menu from '@/shared/resources/components/Menu.vue';
import CartCoreModel from '@/modules/carts/models/CartCoreModel';
import { existsInPath } from '@/shared/lib/support/router/RouterUtils';
import Order from '@/app/lib/order/Order';
import { minOrderString } from '@/app/modules/online-orders/config/onlineOrders';
import Alert from '@/shared/resources/components/Alert.vue';

@Component({
  components: {
    Alert,
    Menu,
    GridRow,
    GridCol,
    Card,
    Icon,
    Badge,
    Button,
  },
})
export default class TopBarCartButton extends Vue {
  /**
   * Getters
   */
  private get cartInstance(): CartCoreModel {
    return Cart.model!;
  }

  private get cartCounter(): number | null {
    return Cart.count();
  }

  private get cartTotalItems(): number {
    return Cart.countTotalItems();
  }

  private get minAmountAlertMessage(): string {
    return `Minimalna wartość zamówienia:<br/>${minOrderString()}`;
  }

  /**
   * Display getters
   */
  private get displayCartCounter(): boolean {
    return !!this.cartCounter;
  }

  private get displayCartContent(): boolean {
    return !Cart.isEmpty();
  }

  private get displayGoToCartButton(): boolean {
    return !Cart.isEmpty();
  }

  private get displayCheckoutButton(): boolean {
    return Order.conditionsMet();
  }

  private get displayMinAmountAlert(): boolean {
    return !Order.conditionsMet();
  }

  private get displayCartCard(): boolean {
    return !existsInPath('cart', this.$route);
  }
}
