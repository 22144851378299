/**
 * Get address number
 * @param addressBuilding
 * @param addressApt
 */
export const addressNumber = (addressBuilding: string, addressApt: string | null): string => {
  if (!addressApt) {
    return addressBuilding;
  }

  return `${addressBuilding}/${addressApt}`;
};

/**
 * Get full address street
 * @param addressStreet
 * @param addressBuilding
 * @param addressApt
 */
export const fullAddressStreet = (
  addressStreet: string,
  addressBuilding: string,
  addressApt: string | null,
): string => {
  return `${addressStreet} ${addressNumber(addressBuilding, addressApt)}`;
};

/**
 * Get full address city
 */
export const fullAddressCity = (addressPostalCode: string, addressCity: string): string => {
  return `${addressPostalCode} ${addressCity}`;
};

/**
 * Get full address
 * @param addressStreet
 * @param addressBuilding
 * @param addressApt
 * @param addressPostalCode
 * @param addressCity
 */
export const fullAddress = (
  addressStreet: string,
  addressBuilding: string,
  addressApt: string | null,
  addressPostalCode: string,
  addressCity: string,
): string => {
  return `${fullAddressStreet(addressStreet, addressBuilding, addressApt)},
    ${fullAddressCity(addressPostalCode, addressCity)}`;
};
