import SettingsStorage from '@/core/settings/SettingsStorage';
import SettingsStorageRegistry from '@/core/settings/SettingsStorageRegistry';
import BrowserLocalSettingsStorageAdapter from '@/core/settings/adapters/BrowserLocalSettingsStorageAdapter';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';
import ContractorCoreModel from '@/modules/contractors/models/ContractorCoreModel';
import AmountsInterface from '@/shared/lib/interfaces/AmountsInterface';
import AmountsFormattedInterface from '@/shared/lib/interfaces/AmountsFormattedInterface';
import Locale from '@/core/locale/Locale';
import CartCoreModel from '@/modules/carts/models/CartCoreModel';
import Cart from '@/app/lib/cart/Cart';
import { DeliveryInterface } from '@/modules/deliveries/interfaces/DeliveriesInterface';
import { calculateNetPrice, calculateVatPrice } from '@/modules/prices/pricesUtils';
import CartItemCoreModel from '@/modules/carts/models/CartItemCoreModel';
import AppSettings from '@/app/lib/settings/AppSettings';
import config from '@/app/modules/online-orders/config/onlineOrders';
import { OnlineOrdersMinValueConfigInterface } from '@/app/modules/online-orders/interfaces/OnlineOrdersConfigInterface';
import Payments from '@/shared/lib/support/Payments';
import OnlineOrderAddressDataCoreModel from '@/modules/online-orders/models/OnlineOrderAddressDataCoreModel';
import ProductCoreModel from '@/modules/products/models/ProductCoreModel';

const ORDER_DATA = 'DATA';
const ORDER_STORAGE_PREFIX: string = 'ORDER';

export default class Order {
  /**
   * Set storage
   */
  public static readonly storage: SettingsStorage = SettingsStorageRegistry.register(
    new BrowserLocalSettingsStorageAdapter(ORDER_STORAGE_PREFIX),
  );

  /**
   * Define address data
   */
  public static addressData: any; // TODO add interface

  /**
   * Processing flag
   */
  public static processing: boolean = false;

  public static model: OnlineOrderCoreModel | null = null;

  /**
   * Order model
   */
  public static async createModel() {
    const orderData = this.storage.getObject(ORDER_DATA);
    this.model = await new OnlineOrderCoreModel(orderData);
  }

  /**
   * Cart model
   */
  public static get cartModel(): CartCoreModel {
    return Cart.model;
  }

  /**
   * Get delivery config
   */
  public static get deliveryConfig(): DeliveryInterface | null {
    return this.model ? this.model.deliveryConfig : null;
  }

  /**
   * Get delivery prices
   */
  public static get deliveryPrice(): AmountsInterface {
    const gross = this.deliveryConfig
      ? Payments.getDeliveryPrice(this.model!.paymentType!, this.deliveryConfig.key, this.cartModel.totalGross)
      : 0;
    const net = this.deliveryConfig ? calculateNetPrice(gross, this.deliveryConfig.vatRate) : 0;
    const vat = calculateVatPrice(gross, net);

    return {
      net,
      gross,
      vat,
    };
  }

  /**
   * Get delivery prices formatted
   */
  public static get deliveryPriceFormatted(): AmountsFormattedInterface {
    return {
      net: Locale.priceWithCurrency(this.deliveryPrice.net),
      gross: Locale.priceWithCurrency(this.deliveryPrice.gross),
      vat: Locale.priceWithCurrency(this.deliveryPrice.vat),
    };
  }

  /**
   * Get products totals
   */
  public static get productsTotals(): AmountsInterface {
    return {
      net: this.cartModel.totalNet,
      gross: this.cartModel.totalGross,
      vat: this.cartModel.totalVat,
    };
  }

  /**
   * Get totals
   */
  public static get totals(): AmountsInterface {
    if (!this.cartModel || !this.deliveryPrice) {
      return {
        net: 0,
        gross: 0,
        vat: 0,
      };
    }

    return {
      net: this.cartModel.totalNet + this.deliveryPrice.net,
      gross: this.cartModel.totalGross + this.deliveryPrice.gross,
      vat: this.cartModel.totalVat + this.deliveryPrice.vat,
    };
  }

  /**
   * Get totals formatted
   */
  public static get totalsFormatted(): AmountsFormattedInterface {
    return {
      net: Locale.priceWithCurrency(this.totals.net),
      gross: Locale.priceWithCurrency(this.totals.gross),
      vat: Locale.priceWithCurrency(this.totals.vat),
    };
  }

  /**
   * Check if order model has valid data
   */
  public static isValid(): boolean {
    return !!this.model!.payment
      && !!this.model!.delivery
      && !!this.model!.email
      && !!this.model!.phone;
  }

  /**
   * Check if needed conditions to make an order are met
   */
  public static conditionsMet(): boolean {
    const minOrderConfig: OnlineOrdersMinValueConfigInterface = config.minOrder;
    return this.productsTotals[minOrderConfig.isGross ? 'gross' : 'net'] >= minOrderConfig.value;
  }

  /**
   * Prepare data to create
   */
  public static dataToCreateRequest(items: CartItemCoreModel[]): object | null {
    if (!this.model || !this.contractor) {
      return null;
    }

    return {
      ...this.model.toCreateRequest(),

      deliveryPrice: this.deliveryPrice.gross,
      items: this.itemsDataToCreateRequest(items),

      productsNet: this.productsTotals.net,
      productsGross: this.productsTotals.gross,
      productsVat: this.productsTotals.vat,

      total: this.totals.gross,

      invoice: {
        name: this.contractor.invoiceName,
        street: this.contractor.invoiceAddress,
        building: this.contractor.invoiceBuilding,
        locale: this.contractor.invoiceLocale,
        zipCode: this.contractor.invoiceZipCode,
        place: this.contractor.invoicePlace,
        country: this.contractor.invoiceCountry,
        nip: this.contractor.invoiceNip,
      },
    };
  }

  /**
   * Prepare order items data to create request
   * @param items
   */
  public static itemsDataToCreateRequest(items: CartItemCoreModel[]): any[] {
    return items.map((item: CartItemCoreModel) => {
      return {
        productId: item.productId,
        quantity: item.quantity,

        priceNet: item.priceNet,
        priceGross: item.priceGross,
        priceVat: item.priceVat,
      };
    });
  }

  /**
   * Load data from cache
   */
  public static async loadData() {
    await this.createModel();
    const orderData: any | null = this.storage.getObject(ORDER_DATA);

    if (orderData && orderData.contractorId === AppSettings.contractorId) {
      this.addressData = orderData.addressData;
      await this.setContractor(AppSettings.contractor);
    } else {
      await this.resetContractor(AppSettings.contractor);
    }
  }

  /**
   * Sync order data to storage
   */
  public static syncOrder(data: any, override: boolean = true) {
    if (override) {
      this.storage.setObject(ORDER_DATA, data);
      this.fillModel(data);
    } else {
      const existingData: any = this.storage.getObject(ORDER_DATA);
      const newData: object = {
        ...data,
        ...existingData,
      };

      this.storage.setObject(ORDER_DATA, newData);
      this.fillModel(newData);
    }
  }

  /**
   * Set contractor
   * @param contractor
   */
  public static async setContractor(contractor: ContractorCoreModel | null) {
    this.contractor = contractor;

    if (this.contractor) {
      // TODO fix data
      await this.syncOrder({
        contractorId: this.contractor.id,
        email: this.model!.email || this.contractor.contactEmail,
        phone: this.model!.phone || this.contractor.contactPhone,
        prices: this.contractor.defaultPrice,

        addressData: {
          name: this.addressData?.name || this.contractor.invoiceName,
          street: this.addressData?.street || this.contractor.invoiceAddress,
          building: this.addressData?.building || this.contractor.invoiceBuilding,
          locale: this.addressData?.locale || this.contractor.invoiceLocale,
          zipCode: this.addressData?.zipCode || this.contractor.invoiceZipCode,
          place: this.addressData?.place || this.contractor.invoicePlace,
        },
      }, false);
    }
  }

  /**
   * Reset contractor
   * @param contractor
   */
  public static async resetContractor(contractor: ContractorCoreModel | null) {
    this.contractor = contractor;

    if (this.contractor) {
      await this.syncOrder({
        contractorId: this.contractor.id,
        email: this.contractor.contactEmail,
        phone: this.contractor.contactPhone,
        prices: this.contractor.defaultPrice,

        addressData: {
          name: this.contractor.invoiceName,
          street: this.contractor.invoiceAddress,
          building: this.contractor.invoiceBuilding,
          locale: this.contractor.invoiceLocale,
          zipCode: this.contractor.invoiceZipCode,
          place: this.contractor.invoicePlace,
        },
      }, true);
    }
  }

  /**
   * Reset order
   */
  public static async reset() {
    await this.clearOrderData();
    Cart.clearCart();
  }

  public static updateProductAmount(productId: number, amount: number) {
    ProductCoreModel.update({
      where: productId,
      data: {
        amount,
      },
    });
  }

  /**
   * Contractor
   */
  private static contractor: ContractorCoreModel | null;

  /**
   * Fill model with given data
   * @param data
   * @private
   */
  private static fillModel(data: any) {
    if (data.addressData) {
      if (!this.model!.addressData) {
        this.model!.addressData = new OnlineOrderAddressDataCoreModel();
      }

      this.model!.addressData.fill(data.addressData);
      delete data.addressData;
    }

    this.model!.fill(data);
  }

  /**
   * Clear order data
   * @private
   */
  private static clearOrderData() {
    // TODO maybe just clear?
    this.storage.removeObject(ORDER_DATA);
  }
}
