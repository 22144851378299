enum DocumentTypesEnum {
  RECEIPT = 'RECEIPT',

  SALE_INVOICE = 'SALE_INVOICE',
  SALE_INVOICE_CORRECTION = 'SALE_INVOICE_CORRECTION',
  SALE_INVOICE_DUPLICATE = 'SALE_INVOICE_DUPLICATE',

  PROFORMA = 'PROFORMA',
  PURCHASE_INVOICE = 'PURCHASE_INVOICE',
}

export default DocumentTypesEnum;
