




















import { Vue, Component, Prop } from 'vue-property-decorator';

import Dialog from '@/shared/resources/components/dialogs/Dialog.vue';
import SideModal from '@/shared/resources/components/SideModal.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';

import { SideModalInterface } from '@/shared/lib/interfaces/SideModalInterface';

// b2b // TODO make don't have to import here
import ProductDetails from '@/app/modules/products/components/ProductDetails.vue';
import ProductAddToCart from '@/app/modules/products/components/ProductAddToCart.vue';
import RegulationsText from '@/app/components/RegulationsText.vue';

@Component({
  components: {
    RegulationsText,
    ProductAddToCart,
    ProductDetails,
    Button,
    SideModal,
    Dialog,
  },
})
export default class SideModals extends Vue {
  /**
   * Props
   */
  @Prop() private modals!: SideModalInterface[];

  /**
   * Methods
   */
  private getIndex(index: number) {
    return this.modals.length - index;
  }

  /**
   * Handlers
   */
  private onHide(index: number) {
    this.$emit('hide', index);
  }
}
