import {PRICE_DECIMALS, PRICES_LIST} from './prices';

/**
 * Format price
 * @param price
 * @param decimals
 * @returns {string}
 */
export const formatPrice = (price: number, decimals: number = PRICE_DECIMALS): number => {
  const index = Math.pow(10, decimals);
  return Number((Math.round(price * index) / index).toFixed(decimals));
};

/**
 * Check if given prices are gross
 * @param price
 * @returns {boolean}
 */
export const isGrossPrice = (price: string): boolean => {
  return PRICES_LIST[price].isGross;
};

/**
 * Calculate net price
 * @param grossPrice
 * @param vatRate
 * @returns {number}
 */
export const calculateNetPrice = (grossPrice: number, vatRate: number): number => {
  return grossPrice / decimalVatRate(vatRate);
};

/**
 * Calculate vat price
 * @param grossPrice
 * @param netPrice
 * @returns {number}
 */
export const calculateVatPrice = (grossPrice: number, netPrice: number): number => {
  return grossPrice - netPrice;
};

/**
 * Calculate vat price from net
 * @param netPrice
 * @param vatRate
 * @returns {number}
 */
export const calculateVatPriceFromNet = (netPrice: number, vatRate: number): number => {
  return netPrice * decimalVatRate(vatRate, 0);
};

/**
 * Calculate gross price
 * @param netPrice
 * @param vatRate
 * @returns {number}
 */
export const calculateGrossPrice = (netPrice: number, vatRate: number): number => {
  return netPrice * decimalVatRate(vatRate);
};

/**
 * Convert vat rate to decimal
 * @param vatRate
 * @param index
 * @returns {number}
 */
export const decimalVatRate = (vatRate: number, index: number = 1) => {
  return (index + (vatRate / 100));
};

/**
 * Calculate price with given discount rate
 * @param price
 * @param discountRate
 * @returns {number}
 */
export const calculatePriceWithDiscount = (price: number, discountRate: number): number => {
  return price * ((100 - discountRate) / 100);
};
