import { transform, isEqual, isObject, isArray, isNumber, isString, sortBy, cloneDeep } from 'lodash';
import IndexableInterface from '@/core/interfaces/IndexableInterface';

enum DataFilterTypes {
  ARRAY = 'array',
  OBJECT = 'object',
  OTHER = 'other',
}

export default class ORMModelDataHelper {
  /**
   * Remove selected fields from Indexable object
   * @param data
   * @param fields
   */
  public static remove(data: IndexableInterface, fields: string[] = []): IndexableInterface {
    const dataClone = cloneDeep(data);

    fields.forEach((field: string) => {
      if (field.includes('.')) {
        const elements: string[] = field.split('.');
        const index: string | undefined = elements.shift();
        const subfield: string = elements.join('.');
        if (index && dataClone[index] !== undefined) {
          if (isArray(dataClone[index])) {
            const list: any[] = [];
            dataClone[index].forEach((subData: IndexableInterface, subIndex: number) => {
              list[subIndex] = this.remove(subData, [subfield]);
            });
            dataClone[index] = list;
          } else if (isObject(dataClone[index])) {
            dataClone[index] = this.remove(dataClone[index], [subfield]);
          }
        }
      } else if (isString(field) && dataClone[field] !== undefined) {
        delete dataClone[field];
      }
    });

    return dataClone;
  }

  /**
   * Filter fields in Indexable object
   * @param data
   * @param selectedFields
   * @param excludeFields
   */
  public static filter(
      data: IndexableInterface,
      selectedFields?: string[],
      excludeFields?: string[],
  ) {
    const result: IndexableInterface = {};
    if (excludeFields && excludeFields.length) {
      // delete excluded fields (including deep data)
      // eslint-disable-next-line no-param-reassign
      data = this.remove(data, excludeFields);
    }

    if (selectedFields && selectedFields.length) {
      // add selected fields only
      selectedFields.forEach((field: string) => {
        result[field] = data[field];
      });
    } else {
      Object.keys(data).forEach((field: string) => {
        result[field] = data[field];
      });
    }

    return result;
  }

  /**
   * Compare records and return filtered data for requests
   * @param object
   * @param origin
   */
  public static compare(object: IndexableInterface, origin: IndexableInterface) {
    return this.iterator(object, origin);
  }

  /**
   * Default primary key fieldname
   */
  private static primaryKey = 'id';

  /**
   * Iterate each branch recursively and return filtered data
   * @param list
   * @param origin
   * @param inEntity
   * @param deep
   * @param inArray
   */
  private static iterator(
    list: IndexableInterface,
    origin: any,
    inEntity = false,
    includeAllFields = false,
    inArray = false,
  ) {
    const result = transform(list, (data: IndexableInterface, value: any, key: string) => {
      const type: DataFilterTypes = this.recognizeType(value);

      if (inEntity && inArray && type === DataFilterTypes.OTHER && key === this.primaryKey) {
        // iteration on fields inside entity object inside parent array
        // add primaryKey in all cases
        data[key] = value;
      } else if (type === DataFilterTypes.OTHER && (includeAllFields || !this.hasEqualValue(value, key, origin))) {
        // basic value
        data[key] = this.parseValue(value);
      } else if (type === DataFilterTypes.OBJECT && this.hasPrimaryKeyProperty(value)) {
        if (inArray) {
          // value is an entity object in array list
          // try to find same object in origin list
          const foundKey: string | number | undefined = this.findObjectKeyById(origin, value.id);
          if (isNumber(foundKey) || isString(foundKey)) {
            // get origin branch
            const originBranch: IndexableInterface | null = this.getOriginBranch(foundKey, origin);
            // start iteration on entity fields
            const branch: IndexableInterface
              = this.iterator(value, originBranch, true, false, true);
            // compare branches
            if (!this.hasEqualBranch(branch, originBranch)) {
              data[key] = branch;
            }
          } else {
            // add whole object (entity not found by id in origin list)
            data[key] = value;
          }
        } else {
          // value is a single entity object
          // get origin branch
          const originBranch: any = this.getOriginBranch(key, origin);
          // start iteration on entity fields
          const branch: IndexableInterface
            = this.iterator(value, originBranch, true, includeAllFields, inArray);

          // compare branches
          if (!this.hasEqualBranch(branch, originBranch) && !!Object.values(branch).length) {
            data[key] = branch;
          }
        }
      } else if (type === DataFilterTypes.OBJECT || type === DataFilterTypes.ARRAY) {
        // value is a classic object/array
        // get origin branch
        const originBranch: any = this.getOriginBranch(key, origin);
        if (this.hasEqualBranch(value, originBranch)) {
          // there is no changes in whole branch at deep level
          if (includeAllFields && type === DataFilterTypes.OBJECT) {
            data[key] = value;
          }
          return;
        }
        const isArrayType: boolean = type === DataFilterTypes.ARRAY;
        // start new iteration with includeAllFields: true to collect all values
        const branch: IndexableInterface
          = this.iterator(value, originBranch, false, inArray || isArrayType, isArrayType);

        // includeAllFields: false -> first iterate inside entity check if deep branches are equal
        // includeAllFields: true -> deep iterate, push all collected data to compare it at top iteration level
        if (includeAllFields || !this.hasEqualBranch(branch, originBranch)) {
          data[key] = branch;
        }
      }
    });
    return result;
  }

  /**
   * Get origin branch by key
   * @param key
   * @param origin
   */
  private static getOriginBranch(key: string | number, origin?: any): any {
    return origin !== undefined &&
      origin !== null &&
      origin[key] !== undefined
        ? origin[key] : undefined;
  }

  /**
   * Check if branches are equal
   * @param branch
   * @param origin
   */
  private static hasEqualBranch(branch: IndexableInterface, origin?: any) {
    if (!origin) {
      return false;
    }
    return isEqual(sortBy(branch, []), sortBy(origin, []));
  }

  /**
   * Compare value with origin copy and check if values are equal
   * @param value
   * @param key
   * @param origin
   */
  private static hasEqualValue(value: any, key: string | number, origin?: any) {
    const originBranch: any = this.getOriginBranch(key, origin);
    return originBranch !== undefined && isEqual(value, originBranch);
  }

  /**
   * Recognize value type by schema definition
   * @param value
   */
  private static recognizeType(value: any) {
    if (isArray(value)) {
      return DataFilterTypes.ARRAY;
    } else if (isObject(value)) {
      return DataFilterTypes.OBJECT;
    } else {
      return DataFilterTypes.OTHER;
    }
  }

  /**
   * Run extra parsing rules on value
   * @param value
   */
  private static parseValue(value: string | number | null) {
    if (isString(value) && value === '') {
      return null;
    }

    return value;
  }

  /**
   * Check if object has id property
   * @param object
   */
  private static hasPrimaryKeyProperty(object?: object) {
    return !!object && object.hasOwnProperty(this.primaryKey);
  }

  /**
   * Find same entity by id and return position index
   * @param list
   * @param id
   */
  private static findObjectKeyById(list: IndexableInterface[], id: string | number): string | number | undefined {
    if (!isArray(list)) {
      return;
    }
    let key: number | undefined;
    list.forEach((value: IndexableInterface, index: number) => {
      if (value.id === id) {
        key = index;
      }
    });
    return key;
  }
}
