import DocumentCoreModel from '@/modules/documents/models/DocumentCoreModel';

export default class DocumentModel extends DocumentCoreModel {
  /**
   * API method conf
   */
  public static apiConfig = {
    actions: {
      fetch: {
        method: 'GET',
        url: '/documents',
      },
      get: {
        method: 'GET',
        url: '/documents/:id',
      },
      count: {
        method: 'GET',
        url: '/documents/count',
      },
    },
  };

  /**
   * Create print report url
   */
  public printUrl(token: string): string {
    return `${process.env.VUE_APP_API_V2_URL}/b2b/v1/documents/printDocument/${this.id}?access_token=${token}`;
  }
}
