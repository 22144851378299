/**
 * Storage service
 */
export default class StorageService {
  /**
   * Set data in storage on given key
   *
   * @param key
   * @param data
   * @param storage
   * @param stringify
   */
  public static set(key: string, data: any, storage: Storage = localStorage, stringify: boolean = true) {
    const item = stringify ? JSON.stringify(data) : data;
    return storage.setItem(key, item);
  }

  /**
   * Get data from storage by given key
   *
   * @param key
   * @param parse
   */
  public static get(key: string, parse: boolean = true) {
    const data = localStorage.getItem(key) || sessionStorage.getItem(key);

    if (data !== null && data !== undefined && parse) {
      return JSON.parse(data);
    } else {
      return data;
    }
  }

  /**
   * Remove data from storage by given key
   *
   * @param key
   * @param storage
   */
  public static remove(key: string, storage: Storage = localStorage) {
    return storage.removeItem(key);
  }
}
