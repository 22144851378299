



































import { Vue, Component, Prop } from 'vue-property-decorator';

import InnerNavigationDrawer from '@/shared/resources/layouts/InnerNavigationDrawer.vue';
import CategoryCoreModel from '@/modules/categories/models/CategoryCoreModel';
import ORMCollection from '@/core/bridge/orm/ORMCollection';

import Loader from '@/shared/resources/components/Loader.vue';

@Component({
  components: {
    Loader,
    InnerNavigationDrawer,
  },
  name: 'CategoriesTree',
})
export default class CategoriesTree extends Vue {
  /**
   * Props
   */
  @Prop({ default: null }) private parentId!: number | null;

  /**
   * Data
   */
  private categories: ORMCollection = new ORMCollection();
  private loading: boolean = false;

  /**
   * Display getters
   */
  private get displayLoading(): boolean {
    return this.loading;
  }

  private get displayList(): boolean {
    return !this.loading;
  }

  /**
   * Lifecycle hooks
   */
  private created() {
    this.fetchCategories();
  }

  /**
   * Methods
   */
  private async fetchCategories() {
    this.loading = true;

    try {
      this.categories = await CategoryCoreModel
        .apiExt()
        .where('parentId', this.parentId)
        .fetch();
    } catch (ex) {
      // TODO
    }

    this.loading = false;
  }

  private categoryName(category: CategoryCoreModel): string {
    return category.shortName || category.name;
  }
}
