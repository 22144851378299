














import {Vue, Component, Prop} from 'vue-property-decorator';

import ProductCoreModel from '@/modules/products/models/ProductCoreModel';

@Component
export default class ProductDescription extends Vue {
  /**
   * Props
   */
  @Prop() private product!: ProductCoreModel;

  /**
   * Display getters
   */
  private get displayTemplateOnTop(): boolean {
    return this.product.isTemplatePositionOnTop;
  }

  private get displayTemplateOnBottom(): boolean {
    return this.product.isTemplatePositionOnBottom;
  }
}
