import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { VuetifyPreset } from 'vuetify/types/services/presets';

Vue.use(Vuetify);

const preset: VuetifyPreset = {
  breakpoint: {
    mobileBreakpoint: 600,
    scrollBarWidth: 16,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920,
    },
  },
  icons: {
    iconfont: 'mdi',
    values: {},
  },
  lang: {
    current: 'pl',
    locales: {
      pl: {
        dataTable: {
          ariaLabel: {
            sortNone: 'Brak sortowania',
            sortAscending: 'Sortowanie rosnąco',
            sortDescending: 'Sortowanie malejąco',
            activateAscending: 'Sortuj rosnąco',
            activateDescending: 'Sortuj malejąco',
            activateNone: '',
          },
          sortBy: 'Sortowanie',
        },
        pagination: {
          ariaLabel: {
            previous: 'Poprzedni',
            next: 'Następny',
            currentPage: 'Bieżąca strona',
            page: 'Strona',
            wrapper: '',
          },
        },
        datePicker: {
          itemsSelected: '{0} zaznaczonych',
        },
        noDataText: 'Brak danych.',
        badge: '',
      },
    },
    t: undefined as any,
  },
  rtl: false,
  theme: {
    dark: false,
    default: 'light',
    disable: false,
    options: {
      cspNonce: undefined,
      customProperties: undefined,
      minifyTheme: undefined,
      themeCache: undefined,
    },
    themes: {
      light: {
        primary: '#9661a8',
        secondary: '#21252b',
        accent: '#76839a',
        info: '#2196F3',
        warning: '#FFEB3B',
        error: '#e6413d',
        success: '#a7db4e',
      },
      dark: {
        primary: '#9661a8',
        secondary: '#21252b',
        accent: '#76839a',
        info: '#2196F3',
        warning: '#FFEB3B',
        error: '#e6413d',
        success: '#a7db4e',
      },
    },
  },
};

export default new Vuetify({
  preset,
});
