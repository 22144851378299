import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';
import { OnlineOrdersStatusesEnum } from '@/modules/online-orders/enums/OnlineOrdersStatusesEnum';
import { formatDateTime } from '@/core/helpers/utils/DateUtils';

export default class OnlineOrderStatusCoreModel extends ORMModelExtended {
  /**
   * ORM entity
   */
  public static entity = 'online-orders-statuses';

  /**
   * ORM fields
   */
  public static fields() {
    return {
      id: this.number(0),
      orderId: this.number(0),
      date: this.string(''),
      status: this.string(''),
      description: this.string(null).nullable(),
    };
  }

  /**
   * Public fields
   */
  public id!: number;
  public orderId!: number;
  public date!: string;
  public status!: OnlineOrdersStatusesEnum;
  public description!: string | null;

  /**
   * Get date formatted
   */
  public get dateFormatted(): string {
    return formatDateTime(this.date);
  }
}
