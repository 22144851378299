
























import { Vue, Component, Prop } from 'vue-property-decorator';
import NumberField from '@/shared/resources/components/forms/fields/NumberField.vue';
import Tooltip from '@/shared/resources/components/Tooltip.vue';

@Component({
  components: {
    Tooltip,
    NumberField,
  },
})
export default class ProductQuantityField extends Vue {
  /**
   * Props
   */
  @Prop() private value!: number;
  @Prop({ default: 1 }) private min!: number;
  @Prop() private max!: number | null;
  @Prop() private messages!: string | string[];
  @Prop() private errorMessage!: string | string[];
  @Prop() private disabled!: boolean;
  @Prop({ default: false }) private loading!: boolean;

  /**
   * Handle quantity
   */
  private get quantity(): number {
    return this.value;
  }

  private set quantity(value: number) {
    this.$emit('input', value);
  }

  /**
   * Getters
   */
  private get showTooltip(): boolean {
    return this.hasError;
  }

  private get hasError(): boolean {
    return !!this.errorMessage;
  }

  /**
   * Handlers
   */
  private onEnterPress() {
    this.$emit('keypress.enter');
  }
}
