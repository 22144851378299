export const VAT_RATE_23: number = 23;
export const VAT_RATE_8: number = 8;
export const VAT_RATE_5: number = 5;
export const VAT_RATE_0: number = 0;
export const VAT_RATE_ZW: string = 'zw';

export const VAT_RATES: Array<number | string> = [
  VAT_RATE_23,
  VAT_RATE_8,
  VAT_RATE_5,
  VAT_RATE_0,
  VAT_RATE_ZW,
];
