


































import { Component, Vue } from 'vue-property-decorator';

import Loader from '@/shared/resources/components/Loader.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridContainer from '@/shared/resources/components/grid/GridContainer.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';

const TIMEOUT_TIME = 15; // in seconds

@Component({
  components: {
    Button,
    GridContainer,
    GridRow,
    Loader,
  },
})
export default class CheckingLayout extends Vue {
  /**
   * Data
   */
  private timeout: boolean = false;

  /**
   * Display getters
   */
  private get displayLoader(): boolean {
    return !this.timeout;
  }

  private get displayRefreshButton(): boolean {
    return this.timeout;
  }

  /**
   * Lifecycle hooks
   */
  private created() {
    setTimeout(() => {
      this.timeout = true;
    }, TIMEOUT_TIME * 1000);
  }

  /**
   * Methods
   */
  private refresh() {
    window.location.href = process.env.VUE_APP_URL as string;
  }

  /**
   * Handlers
   */
  private onRefreshButtonClick() {
    this.refresh();
  }
}
