export default class ApiORMQueryRelationHandler {
  /**
   * Data
   */
  public name!: string;
  public fields?: string[];
  public relations?: ApiORMQueryRelationHandler[];

  constructor(name: string, fields?: string[], relations?: ApiORMQueryRelationHandler[]) {
    this.name = name;
    if (fields) {
      this.fields = fields;
    }
    if (relations) {
      this.relations = relations;
    }
  }
}
