
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import ValidationLib from '@/shared/lib/support/validation/Validation';
import { Validation } from 'vuelidate';

@Component
export default class Field extends Vue {
  /**
   * Props
   */
  @Prop() protected value!: string;
  @Prop() protected name!: string;
  @Prop() protected validation?: Validation;
  @Prop({ default: false, type: Boolean }) protected required!: boolean;
  @Prop({ default: true, type: Boolean }) protected outlined!: boolean;
  @Prop({ default: false, type: Boolean }) protected solo!: boolean;
  @Prop({ default: true, type: Boolean }) protected dense!: boolean;
  @Prop({ default: false, type: Boolean }) protected error!: boolean;
  @Prop({ default: () => [/**/] }) protected errorMessages!: string | string[];

  /**
   * Getters
   */
  protected get hasError(): boolean {
    return (!!this.errorMessages && !!this.errorMessages.length) ||
      this.hasValidationError ||
      this.error;
  }

  protected get hasValidationError(): boolean {
    if (!this.validation) {
      return false;
    }

    return this.validation.$dirty && this.validation.$invalid;
  }

  protected get validationErrors(): string[] | null {
    if (!this.hasError) {
      return null;
    }

    return ValidationLib.translateInvalid(this.validation);
  }

  protected get errors(): string | string[] | null {
    return this.errorMessages || this.validationErrors;
  }

  protected get isOutlined(): boolean {
    return !this.solo;
  }

  /**
   * Emit events
   */
  @Emit()
  protected input(value: any) {
    return value;
  }

  @Emit()
  protected change(value: any) {
    return value;
  }

  /**
   * Render
   */
  private render() {
    //
  }
}
