





















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Header extends Vue {
  /**
   * Props
   */
  @Prop({ default: 'h3' }) private tag!: string;

  @Prop({ default: false, type: Boolean }) private noMargin!: boolean;
  @Prop({ default: false, type: Boolean }) private noMarginTop!: boolean;
  @Prop({ default: false, type: Boolean }) private noMarginBottom!: boolean;

  /**
   * Getters
   */
  private get componentName(): string {
    return this.tag;
  }

  /**
   * Display getters
   */
  private get displayButtons(): boolean {
    return !!this.$slots.buttons;
  }

  /**
   * Class names
   */
  private get elementClassNames(): object {
    return {
      ['header--' + this.tag]: true,
      'header--no-margin': this.noMargin,
      'header--no-margin-top': this.noMarginTop,
      'header--no-margin-bottom': this.noMarginBottom,
    };
  }
}
