import { AxiosResponse } from 'axios';
import AppSettings from '@/app/lib/settings/AppSettings';
import UserCoreModel from '@/modules/users/models/UserCoreModel';
import Cart from '@/app/lib/cart/Cart';
import Order from '@/app/lib/order/Order';
import { default as AuthBase } from '@/core/auth/Auth';
import Api from '@/core/api/Api';

export default class Auth extends AuthBase {
  /**
   * Init
   */
  public static async init() {
    try {
      const user: UserCoreModel = await UserCoreModel
        .apiExt()
        .baseUrl(this.apiBaseUrl)
        .with('contractor')
        .get();

      AppSettings.contractorId = user.contractorId;

      await Cart.loadData();
      await Order.loadData();
    } catch (ex) {
      throw ex;
    }
  }

  /**
   * Request password reset
   * @param email
   */
  public static async requestPasswordReset(email: string) {
    await new Api()
      .data({ email })
      .post('reset-password/request');
  }

  /**
   * Login callback
   * @param response
   * @protected
   */
  protected static async loginCallback(response: AxiosResponse) {
    await this.init();
  }

  /**
   * Logout callback
   * @protected
   */
  protected static async logoutCallback() {
    // do nothing special here
  }
}
